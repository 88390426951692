import { put, select } from 'redux-saga/effects'

import { setCurrentCategories } from '../../actions/ux'
import { track as trackEvent } from '../../actions/tracking'
import { selectCurrentCategories } from '../../selectors/ux'

function* onCategoryListScrollerItemClick(action) {
  const { params: { category } } = action.payload

  const activeCategories = yield select(selectCurrentCategories)
  const wasPreSelected = activeCategories.includes(category)

  const newActiveCategories = wasPreSelected ? (
    activeCategories.filter(x => x !== category)
  ) : (
    [...activeCategories, category]
  )

  yield put(setCurrentCategories(newActiveCategories))

  yield put(trackEvent({
    event: 'filterSelected',
    params: {
      categories: newActiveCategories,
    },
  }))
}

export default onCategoryListScrollerItemClick
