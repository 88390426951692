import { call } from 'redux-saga/effects'

import { trackGA } from '../providers'

function* clientPixelTracked(action) {
  const { params } = action.payload

  yield call(trackGA, {
    hitType: 'event',
    eventCategory: 'Impression',
    eventAction: 'ClientPixelTracked',
    ...params,
  })
}

export default clientPixelTracked
