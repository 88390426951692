import { put, select } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'
import { selectProduct } from '../../selectors/product'
import { selectCurrentProductId, selectCurrentCollectionIndex } from '../../selectors/ux'
import { CONTEXT_STORE, ORIGIN_PRODUCT } from '../../../helpers/const'

function* onProductStorePhoneClick() {
  const productId = yield select(selectCurrentProductId)
  const product = yield select(selectProduct(productId))
  const position = (yield select(selectCurrentCollectionIndex)) + 1 // from 1 to N

  yield put(trackEvent({
    event: 'callProduct',
    params: {
      id: product.id,
      title: product.title,
      position,
      to: `${ORIGIN_PRODUCT}-${CONTEXT_STORE}`,
    },
  }))
}

export default onProductStorePhoneClick
