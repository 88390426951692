import { call } from 'redux-saga/effects'

import {
  trackSF, trackFBQ, trackGAW, trackGAWR,
} from '../providers'

function* heroSection() {
  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'hs',
    s2s_ec: 'c',
    s2s_origin: 'image',
  })

  // Facebook
  yield call(trackFBQ, 'trackCustom', 'Hero Image')

  // Google AdWords
  yield call(trackGAW, {
    google_custom_params: {
      s2s_ea: 'hs',
      s2s_ec: 'c',
      s2s_origin: 'image',
    },
  })

  // Google AdWords - Remarketing
  yield call(trackGAWR, {
    google_custom_params: {
      s2s_ea: 'hs',
      s2s_ec: 'c',
      s2s_origin: 'image',
    },
  })
}

export default heroSection
