const EMPTY_RETAILER = {}
const EMPTY_RETAILERS = []

export const selectRetailers = state => state.retailers || EMPTY_RETAILERS

export const selectRetailer = id => state => selectRetailers(state)[id] || EMPTY_RETAILER

export const selectRetailerSlug = id => state => selectRetailer(id)(state)?.slug

export const selectRetailerLogo = id => state => selectRetailer(id)(state)?.assets?.logo

export const selectRetailerPin = id => state => selectRetailer(id)(state)?.assets?.pin

export const selectRetailerLogoCover = id => state => selectRetailer(id)(state)?.assets?.cover
