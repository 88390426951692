import { select, put } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'

function* onSimilarProductCarouselVisible(action) {
  const { params: { productId, category, position } } = action.payload

  const product = yield select(selectProduct(productId))

  yield put(trackEvent({
    event: 'viewProductSimilarProduct',
    params: {
      id: product.id,
      title: product.title,
      position,
      category,
    },
  }))
}

export default onSimilarProductCarouselVisible
