import i18nUtils from '../utils/i18n'
import getQueryParams from '../utils/getQueryParams'
import { getCampaignDataFromUrl } from './getInfoFromUrl'

const getGeolocation = () => {
  const { epoi } = getQueryParams()
  let { lat, lng } = getQueryParams()
  const { country } = getCampaignDataFromUrl()
  const { lat: defaultLat, lng: defaultLng } = (
    i18nUtils.getI18nConsts(country.toLowerCase()).geolocation
  )

  if (epoi) {
    try {
      const qsToParse = atob(epoi)
      const splittedParams = qsToParse.split('|')
      if (splittedParams.length > 1) {
        [lat, lng] = splittedParams
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Unable to parse epoi from queryStrig, falling back to default')
    }
  }

  const isGeolocationValid = Boolean(parseFloat(lat) && parseFloat(lng))

  return (isGeolocationValid)
    ? { lat, lng }
    : { lat: defaultLat, lng: defaultLng }
}

export default getGeolocation
