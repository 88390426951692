import fetchRestData from '../../helpers/fetchResData'
import { MAX_MANIPULATOR_STORES } from '../../helpers/const'

/**
 * Given a flyerId, lat, lng fetch flyer's stores with a range limit of 30km ( API )
 * @param {Object} params
 * @param {string} params.flyerId
 */
export default ({ flyerId, lat, lng }) => {
  const options = {
    resource: `flyers/${flyerId}/stores`,
    paginate: false,
    dataOnly: true,
    params: {
      sort: 'distance',
      limit: MAX_MANIPULATOR_STORES,
      ll: `${lat},${lng}`, // 30km max
    },
  }

  return fetchRestData(options)
}
