import { put } from 'redux-saga/effects'

import { setSearch } from '../../actions/ux'

function* onProductsSearchType(action) {
  const { params: { search } } = action.payload

  yield put(setSearch(search))
}

export default onProductsSearchType
