import { select, put } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'
import { ORIGIN_LANDING } from '../../../helpers/const'
// import { setCurrentCategories } from '../../actions/ux'

function* onProductsGridItemClick(action) {
  const { params: { productId, category, position } } = action.payload
  const product = yield select(selectProduct(productId))

  yield put(trackEvent({
    event: 'openDetail',
    params: {
      id: product.id,
      title: product.title,
      position,
      category,
      origin: ORIGIN_LANDING,
    },
  }))

  // yield put(setCurrentCategories([])) // avoid MRK-3427 bug
}

export default onProductsGridItemClick
