import {
  parse,
  setHours,
  setMinutes,
  format,
} from 'date-fns'

const ADMIN_DATE_FORMAT = 'dd-MM-yyyy HH:mm:ss'
const FEED_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss'

/*
E' stato scelto il formato FEED_DATE_FORMAT,
per motivi di retro-compatibilità ci tocca supportare tutti i formati
https://doveconviene.atlassian.net/browse/MRK-2625
Cases:
yyyy-MM-dd HH:mm:ss
dd-MM-yyyy HH:mm:ss
dd-MM-yyyy
yyyy-MM-dd
*/

export default (sDate, eDate) => {
  let startDate = sDate || format(new Date(), FEED_DATE_FORMAT)
  let endDate = eDate || format(new Date(), FEED_DATE_FORMAT)

  if (startDate.split(' ').length === 1) startDate += ' 00:00:00'
  if (endDate.split(' ').length === 1) endDate += ' 00:00:00'

  // regex to match DD/MM/YYYY
  const regex = /^([1-9]|([012][0-9])|(3[01]))-([0]{0,1}[1-9]|1[012])-\d\d\d\d (20|21|22|23|[0-1]?\d):[0-5]?\d:[0-5]?\d$/g
  const isAdminDate = regex.test(startDate)

  // replace needed for safari not supporting dates with hyphens
  const newStartDate = parse(
    startDate,
    (isAdminDate ? ADMIN_DATE_FORMAT : FEED_DATE_FORMAT),
    new Date(),
  )

  const newEndDate = setHours(setMinutes(parse(
    endDate,
    (isAdminDate ? ADMIN_DATE_FORMAT : FEED_DATE_FORMAT),
    new Date(),
  ), 59), 23)

  return {
    start: newStartDate,
    end: newEndDate,
  }
}
