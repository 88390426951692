import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const ringAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledRoot = styled.div({
  display: 'inline-block',
  position: 'relative',
  width: 64,
  height: 64,
})

const StyledRing = styled.div({
  boxSizing: 'border-box',
  display: 'block',
  position: 'absolute',
  width: 60,
  height: 60,
  border: '6px solid #D1233E',
  borderRadius: '50%',
  animation: `${ringAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
  borderColor: '#D1233E transparent transparent transparent',
  '&:nth-of-type(1)': {
    animationDelay: '-0.45s',
  },
  '&:nth-of-type(2)': {
    animationDelay: '-0.3s',
  },
  '&:nth-of-type(3)': {
    animationDelay: '-0.15s',
  },
})

const Spinner = () => (
  <StyledRoot data-test="Spinner">
    <StyledRing />
    <StyledRing />
    <StyledRing />
    <StyledRing />
  </StyledRoot>
)

export default Spinner
