import getQueryParams from '../getQueryParams'

export const CONTEXTS = ['dvc', 'tiendeo', 'promoqui', 'volantinofacile']

/**
 * Sets the application context, which customized the UI, some translations/labels
 * and, if needed, disabled/enableds specific features
 *
 * Context is determined by (in order of priority):
 * - context query param
 * - marketplace query param
 * - domain
 */
const getContext = () => {
  const { context, marketplace } = getQueryParams()
  const domain = window.location.origin
  if (CONTEXTS.includes(context)) return context
  if (CONTEXTS.includes(marketplace)) return marketplace
  const domainCtx = CONTEXTS.find(ctx => domain.indexOf(ctx) >= 0)
  if (domainCtx) return domainCtx

  return 'dvc'
}

export default getContext
