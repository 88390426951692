import { call, put, select } from 'redux-saga/effects'
import { parse, isBefore } from 'date-fns'

import {
  ERROR_TYPE_NOT_FOUND,
  ERROR_TYPE_EXPIRED,
  ERROR_TYPE_ERROR,
} from '../../../../helpers/const'
import { validateProduct } from './validateProduct'
import { getCurrentDate } from '../../../../helpers/getCurrentDate'
import getQueryParams from '../../../../utils/getQueryParams'
import stringTemplate from '../../../../utils/stringTemplate'
import { renderSplash } from '../../../../splash/index'
import { selectViewMode } from '../../../selectors/layout'
import { selectLabel, selectConst } from '../../../selectors/i18n'
import { setIsCampaignExpired } from '../../../actions/ux'
import { fetchFlyerCategories } from '../../../../api/flyer/fetchFlyerCategories'
import { validateRoute } from './validateRoute'
import { selectCurrentTheme } from '../../../selectors/ux'

// eslint-disable-next-line consistent-return
export function* validateCampaign({
  flyerId,
  products,
  productsArray,
  campaignNameId,
  productId,
  endDate,
}) {
  const theme = yield select(selectCurrentTheme)

  try {
    const viewMode = yield select(selectViewMode)
    const productNotFound = yield select(selectLabel('productNotFound'))
    const productExpired = yield select(selectLabel('productExpired'))
    const productValidation = validateProduct(products, productsArray, productId)

    const { preview } = getQueryParams()
    const isFlyerExpired = preview === 'true'
      ? false
      : isBefore(parse(endDate, 'yyyy-MM-dd', getCurrentDate()), getCurrentDate())

    /* CAMPAIGN IS NOT VALID SECTION */

    if (isFlyerExpired || productValidation === ERROR_TYPE_EXPIRED) {
      const flyerCategories = (yield call(fetchFlyerCategories, { flyerId })) || {}
      const { slug, id: flyerCategoryId } = flyerCategories[flyerCategories.length - 1]
      const domain = yield select(selectConst('domain'))
      const mobileCategoryPath = yield select(selectConst('mobile.category'))
      const text = yield select(selectLabel('otherDeals'))
      const { t: deviceType } = getQueryParams()
      const isWebView = deviceType === 'm'
      const protocolPath = isWebView ? 'mobile.protocol' : 'web.protocol'
      const protocol = yield select(selectConst(protocolPath))
      const retailerPath = isWebView ? 'mobile.retailer' : 'web.retailer'
      const path = yield select(selectConst(retailerPath))

      const ctaValue = deviceType !== 'm'
        ? `${protocol}${domain}/${slug}`
        : stringTemplate(
          `${protocol}${domain}${mobileCategoryPath}`,
          { categoryId: flyerCategoryId },
        )

      yield put(setIsCampaignExpired(true))
      // return cta object content only if retailer path is available ie if context is not tiendeo
      return renderSplash({
        type: ERROR_TYPE_EXPIRED,
        message: productExpired,
        error: 'Product expired',
        cta: path ? { text, value: ctaValue } : null,
        theme,
      })
    }

    if (productValidation === ERROR_TYPE_NOT_FOUND) {
      yield put(setIsCampaignExpired(true))

      return renderSplash({
        type: ERROR_TYPE_NOT_FOUND,
        message: productNotFound,
        error: 'Product not found',
        theme,
      })
    }

    /* CAMPAIGN IS VALID SECTION */
    yield put(setIsCampaignExpired(false))

    yield validateRoute({
      campaignNameId,
      productId,
      viewMode,
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    yield put(setIsCampaignExpired(true))
    return renderSplash({
      type: ERROR_TYPE_ERROR,
      message: 'Validation Error',
      theme,
    })
  }
}
