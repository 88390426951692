import { call, select } from 'redux-saga/effects'

import { selectContext, selectCountry } from '../../../selectors/ux'
import { getSecretGaw } from '../../../../utils/i18n/secret'
import { selectUserCookiesPreferenceActive } from '../../../selectors/tracking'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../../helpers/const'

export function* trackGAW(params = {}) {
  const country = yield select(selectCountry)
  const context = yield select(selectContext)
  const { conversionID, conversionLabel } = getSecretGaw({ country, context })
  const isTargetingAdsCookieEnable = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )

  if (!conversionID) {
    // eslint-disable-next-line no-console
    console.debug('Cannot Track GAW. conversionID is missing.')
    return
  }
  const formattedConversionID = conversionID.split(',')

  // eslint-disable-next-line arrow-body-style
  const gawParams = formattedConversionID.map(id => {
    return {
      send_to: `${id}${conversionLabel ? `/${conversionLabel}` : ''}`,
      google_remarketing_only: false,
      ...params,
    }
  })

  try {
    if (isTargetingAdsCookieEnable) {
      for (const obj of gawParams) {
        yield call(window.gtag, 'event', 'conversion', obj)
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function* initGoogleAdwords() {
  const country = yield select(selectCountry)
  const context = yield select(selectContext)
  const { conversionID } = getSecretGaw({ country, context })
  const isTargetingAdsCookieEnable = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )

  if (typeof window.gtag !== 'function' || !conversionID) {
    // eslint-disable-next-line no-console
    console.debug('error', 'Cannot init Google adwords')
    return
  }

  const formattedConversionID = conversionID.split(',')

  if (isTargetingAdsCookieEnable) {
    try {
      const gawConfig = {
        transport_type: 'beacon',
      }
      for (const id of formattedConversionID) {
        yield call(window.gtag, 'config', `${id}`, gawConfig)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}
