import React from 'react'
import PropTypes from 'prop-types'

import { captureException } from '@sentry/browser'

class ErrorBoundary extends React.Component {
  componentDidCatch(error) {
    captureException(error)
  }

  render() {
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]).isRequired,
}
export default ErrorBoundary
