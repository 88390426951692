import { call, put } from 'redux-saga/effects'

import { fetchFlyer } from '../../../../api/flyer/fetchFlyer'
import { setTrackingRetailerId, setTrackingImpressionTagUrl } from '../../../actions/tracking'
import stringTemplate from '../../../../utils/stringTemplate'

export function* flyerJob({ flyerId, country }) {
  const {
    retailer_id: retailerId,
    unpublish_at: endDate,
    settings: { tracking_url_client: trackingUrlClient },
  } = (yield call(fetchFlyer, { country, flyerId })) || {}

  yield put(setTrackingRetailerId(retailerId))
  const impressionTagUrl = stringTemplate(trackingUrlClient, { timestamp: new Date().getTime() })
  yield put(setTrackingImpressionTagUrl(impressionTagUrl))

  return { retailerId, endDate }
}
