import { v1 as uuidv1 } from 'uuid'
import getQueryParams from '../utils/getQueryParams'

const getUserId = () => {
  const { muid: queryStringMuid } = getQueryParams()

  return queryStringMuid || uuidv1()
}

export default getUserId
