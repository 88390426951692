import { APP_ROUTES } from './const'

export const getCollectionHomePageRoute = campaignNameId => {
  const { campaignNameIdPlaceholder, collectionHomePage } = APP_ROUTES
  return collectionHomePage.replace(campaignNameIdPlaceholder, campaignNameId)
}

export const getCollectionProductPageRoute = (campaignNameId, productId) => {
  const { campaignNameIdPlaceholder, productIdPlaceholder, collectionProductPage } = APP_ROUTES

  return collectionProductPage
    .replace(campaignNameIdPlaceholder, campaignNameId ?? '')
    .replace(productIdPlaceholder, productId ?? '')
}

export const getCarouselProductPageRoute = (campaignNameId, productId) => {
  const { campaignNameIdPlaceholder, productIdPlaceholder, carouselProductPage } = APP_ROUTES
  return carouselProductPage
    .replace(campaignNameIdPlaceholder, campaignNameId ?? '')
    .replace(productIdPlaceholder, productId ?? '')
}
