import { put } from 'redux-saga/effects'

import { setStoresListIsOpen } from '../../actions/ux'

function* onStoresListToggle(action) {
  const { params } = action.payload
  const { open } = params

  yield put(setStoresListIsOpen(open))
}

export default onStoresListToggle
