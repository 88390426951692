import { put, select } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'
import { selectCurrentCollectionIndex } from '../../selectors/ux'

function* onProductDescriptionSeeMoreClick(action) {
  const { params: { productId, isCollapsed } } = action.payload

  if (isCollapsed) {
    const product = yield select(selectProduct(productId))
    const position = (yield select(selectCurrentCollectionIndex)) + 1 // from 1 to N

    yield put(trackEvent({
      event: 'showDescription',
      params: {
        id: product.id,
        title: product.title,
        position,
      },
    }))
  }
}

export default onProductDescriptionSeeMoreClick
