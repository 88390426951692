import { Suspense } from 'react'

import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'

import TagManager from 'react-gtm-module'
import { history } from '../../flux'
import Loading from '../../splash/components/Loading'
import lazyfy from '../../helpers/lazyfy'
import { getCollectionProductPageRoute, getCarouselProductPageRoute } from '../../helpers/getAppRoutes'
import { getSecret } from '../../utils/i18n/secret'

const collection = {
  path: getCollectionProductPageRoute(':s2s_campaign_id', ':productId?'),
  render: lazyfy(() => import('../pages/CollectionPage')),
}

const carousel = {
  path: getCarouselProductPageRoute(':s2s_campaign_id', ':productId?'),
  render: lazyfy(() => import('../pages/CarouselPage')),
}

const gtmId = getSecret('GTM_PROPERTY_ID')()
TagManager.initialize({
  gtmId,
})

const Routes = () => (
  <Router history={history}>
    <Suspense fallback={(<Loading />)}>
      <Switch>
        <Route path={collection.path} component={collection.render} />
        <Route path={carousel.path} component={carousel.render} />
        <Route component={Loading} />
      </Switch>
    </Suspense>
  </Router>
)

export default Routes
