import createActionTypes from './createActionTypes'

export const namespace = 'CATEGORIES'

export const types = createActionTypes(namespace, [
  'SET_CATEGORIES',
])

export const setCategories = categories => ({
  type: types.SET_CATEGORIES,
  payload: { categories },
})
