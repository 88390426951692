import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a flyerId, fetch flyer categories
 * @param {Object} params
 * @param {string} params.flyerId
 */
export const fetchFlyerCategories = ({ flyerId }) => {
  const options = {
    resource: `flyers/${flyerId}/categories`,
    paginate: false,
    dataOnly: true,
    params: {
      fields: 'slug,id',
    },
  }

  return fetchRestData(options)
}
