import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import tracking from './tracking'
import layout from './layout'
import callcenterHours from './callcenterHours'
import products from './products'
import categorizedProducts from './categorizedProducts'
import categories from './categories'
import stores from './stores'
import hours from './hours'
import defaultStoreHours from './defaultStoreHours'
import i18n from './i18n'
import ux from './ux'
import user from './user'
import retailers from './retailers'
import adv from './adv'

export default history => combineReducers({
  router: connectRouter(history),
  tracking,
  layout,
  callcenterHours,
  products,
  categorizedProducts,
  categories,
  stores,
  hours,
  defaultStoreHours,
  i18n,
  ux,
  user,
  retailers,
  adv,
})
