import { put } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'

function* onCampaignHeaderLogoClick() {
  yield put(trackEvent({
    event: 'openRetailerPage',
    params: { },
  }))
}

export default onCampaignHeaderLogoClick
