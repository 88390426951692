import {
  put, call, select, all,
} from 'redux-saga/effects'

import { setStores } from '../../../actions/stores'
import { setStoreHours } from '../../../actions/hours'
import { getStoreRetailer } from '../../../actions/api'
import { setUserNearestStoreId } from '../../../actions/user'

import getGeolocation from '../../../../helpers/getGeolocation'

import manipulateStoreHours from '../../../manipulators/hours'
import { manipulateStores } from '../../../manipulators/2.0/stores'

import { selectLabel } from '../../../selectors/i18n'
import fetchStoresList from '../../../../api/stores/fetchStoresList'
import fetchStoreHours from '../../../../api/stores/fetchStoreHours'
import { renderSplash } from '../../../../splash/index'
import { ERROR_TYPE_ERROR } from '../../../../helpers/const'
import { selectCurrentTheme } from '../../../selectors/ux'

// eslint-disable-next-line consistent-return
export function* storesJob({ country, flyerId }) {
  try {
    const daysOfWeek = yield select(selectLabel('daysOfWeek'))
    const geolocation = getGeolocation()
    const storesArray = yield call(fetchStoresList, {
      flyerId, country, lat: geolocation.lat, lng: geolocation.lng,
    })
    const hasStores = !!storesArray.length
    if (hasStores) {
      const { stores, nearestStoreId } = manipulateStores(storesArray, geolocation)

      yield put(setStores(stores))
      yield put(setUserNearestStoreId(nearestStoreId))
      yield put(getStoreRetailer(nearestStoreId))

      // Fetch and manipulate all stores's hours
      if (Object.keys(stores).length) {
        const storesHours = yield all(
          Object.keys(stores).map(storeId => call(fetchStoreHours, { storeId, country })),
        )

        yield all(storesHours.map(storeHours => {
          if (storeHours.length) {
            const hours = manipulateStoreHours(storeHours, daysOfWeek)
            return put(setStoreHours(storeHours[0].store_id, hours))
          }
          return null
        }))
      }
    } else {
      yield put(setStores([]))
      yield put(setUserNearestStoreId(null))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    const theme = yield select(selectCurrentTheme)
    return renderSplash({
      type: ERROR_TYPE_ERROR,
      message: 'Campaign Error',
      theme,
    })
  }
}
