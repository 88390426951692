import { types as apiActionTypes } from '../../actions/api'
import takeEveryApiRequest from '../../helpers/takeEveryApiRequest'

import getStoreRetailer from './resources/stores/getStoreRetailer'
import getRetailerAssets from './resources/retailers/getRetailerAssets'
import getRetailerSlug from './resources/retailers/getRetailerSlug'

const { GET_STORE_RETAILER, GET_RETAILER_ASSETS, GET_RETAILER_SLUG } = apiActionTypes

function* watchApi() {
  yield takeEveryApiRequest(GET_STORE_RETAILER, getStoreRetailer)
  yield takeEveryApiRequest(GET_RETAILER_ASSETS, getRetailerAssets)
  yield takeEveryApiRequest(GET_RETAILER_SLUG, getRetailerSlug)
}

export default watchApi
