import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a campaignId, fetch s2s_campaign assets
 * @param {Object} params
 * @param {string} params.campaignId
 */
export const fetchS2sCampaignAssets = ({ campaignId }) => {
  const options = {
    resource: `s2s_campaigns/${campaignId}/assets`,
    dataOnly: true,
  }
  return fetchRestData(options)
}
