import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'
import { NOT_AVAILABLE } from '../../../../helpers/const'

function* toggleProductDrawer(action) {
  const { params } = action.payload
  const {
    id, title, position, to,
  } = params

  const productTitle = title || NOT_AVAILABLE

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'tgl',
    s2s_ec: 'c',
    s2s_el: 'product',
    s2s_pos: position,
    s2s_iid: id,
    s2s_title: productTitle,
    s2s_to: to,
  })
}

export default toggleProductDrawer
