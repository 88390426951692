import i18nUtils from '../../../utils/i18n'

const manipulateI18n = (i18n, country) => {
  const currentCountry = {
    consts: i18nUtils.getI18nConsts(country.toLowerCase()),
    labels: i18nUtils.getI18nMessages(country.toLowerCase()),
  }

  return {
    consts: {
      ...i18n.consts,
      ...currentCountry.consts,
    },
    labels: {
      ...i18n.labels,
      ...currentCountry.labels,
    },
  }
}

export default manipulateI18n
