import { selectSearch, selectCurrentCategories } from './ux'
import { createMemoSelector } from '../helpers/selector'

export const selectProductList = state => state.products

export const selectProducts = createMemoSelector(
  selectProductList,
  (products => (
    Object.values(products)
      .sort((productPrev, productNext) => (
        productPrev.settings.order - productNext.settings.order
      ))
  )),
)

export const filterProducts = (categorizedProducts, searchValue, currentCategories) => {
  if (!categorizedProducts) return []
  const filteredProducts = []
  const search = searchValue.toLowerCase()

  categorizedProducts.forEach(categProducts => {
    const filteredCategProducts = []
    // categProducts[0] -> categoryName
    // categProducts[1] -> products array

    if (currentCategories.length && currentCategories.indexOf(categProducts[0]) === -1) return

    const searchFilteredProducts = categProducts[1].filter(product => {
      const { title } = product
      const productTitle = title ? title.toLowerCase() : ''
      return productTitle.includes(search)
    })

    if (searchFilteredProducts.length) {
      filteredCategProducts.push(categProducts[0])
      filteredCategProducts.push(searchFilteredProducts)
      filteredProducts.push(filteredCategProducts)
    }

    // Se nessun prodotto ha il searchValue nel titolo ma c'è una categoria che lo contiene
    if (!searchFilteredProducts.length && categProducts[0].toLowerCase().includes(search)) {
      filteredCategProducts.push(categProducts[0])
      filteredCategProducts.push(categProducts[1])
      filteredProducts.push(filteredCategProducts)
    }
  })

  return filteredProducts
}

export const selectFilteredProducts = state => {
  const search = selectSearch(state)
  const currentCats = selectCurrentCategories(state)
  const products = selectProducts(state)

  return filterProducts(search, currentCats, products)
}

export const selectCategorizedProducts = state => state.categorizedProducts?.products || []

export const selectCollectionProductsList = createMemoSelector(
  selectCategorizedProducts,
  products => {
    let productsList = []
    products.forEach(groupedProducts => { productsList = [...productsList, ...groupedProducts[1]] })
    return productsList
  },
)

export const selectFilteredGroupedProducts = createMemoSelector(
  selectCategorizedProducts,
  selectSearch,
  selectCurrentCategories,
  (categorizedProducts, search, currentCats) => (
    filterProducts(categorizedProducts, search, currentCats)
  ),
)

export const selectProductCategory = productCollectionIndex => createMemoSelector(
  selectCategorizedProducts,
  categorizedProducts => {
    let category = null
    for (let i = 0; i <= categorizedProducts.length - 1; i += 1) {
      const product = categorizedProducts[i][1]
        .filter(currProduct => currProduct.collectionIndex === productCollectionIndex)
      if (product.length) {
        // eslint-disable-next-line prefer-destructuring
        category = categorizedProducts[i][0]
        break
      }
    }
    return category
  },
)

export const selectCategoryProducts = category => createMemoSelector(
  selectCategorizedProducts,
  categorizedProducts => {
    const categoryProducts = []
    for (let i = 0; i <= categorizedProducts.length - 1; i += 1) {
      if (categorizedProducts[i][0] === category) {
        categoryProducts.push(categorizedProducts[i][0])
        categoryProducts.push(categorizedProducts[i][1])
        break
      }
    }
    return categoryProducts
  },
)
