import get from 'lodash.get'
import isStoreOpen from './isStoreOpen'

const isHourValid = time => (
  time.hh !== undefined && time.mm !== undefined
  && time.hh !== null && time.mm !== null
  && time.hh !== '' && time.mm !== ''
)

/**
 * Return val with minimumIntegerDigits = 0
 * Example: 0 ==> "00", 1 ==> "01"
 * @param {integer} val - The integer value to normalize
 */
const twoDigits = val => (val).toLocaleString(undefined, { minimumIntegerDigits: 2 })

export default (
  daysOfWeek,
  storeHours = [],
) => {
  if (!storeHours.length) return []

  const currentDate = new Date(Date.now())
  const formattedHours = []

  const dayLabels = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ].map(key => get(daysOfWeek, key))

  for (const storeHour of storeHours) {
    const { from, to, number } = storeHour
    const hourFrom = isHourValid(from) ? `${twoDigits(from.hh)}:${twoDigits(from.mm)}` : ''
    const hourTo = isHourValid(to) ? `${twoDigits(to.hh)}:${twoDigits(to.mm)}` : ''
    const label = `${hourFrom} - ${hourTo}`
    const isActive = currentDate.getDay() === number - 1
    const isOpen = isActive && isHourValid(from) && isHourValid(to) && isStoreOpen(from, to)

    const day = dayLabels[number - 1]
    const formattedHour = {
      day, label, isActive, isOpen,
    }
    if (!formattedHours[number]) {
      formattedHours[number] = formattedHour
    } else {
      formattedHours[number].label = `${formattedHours[number].label} / ${label}`
      formattedHours[number].isOpen = formattedHours[number].isOpen || isOpen
    }
  }

  formattedHours.shift()

  for (let i = 0; i < dayLabels.length && formattedHours.length; i += 1) {
    if (!formattedHours[i]) {
      formattedHours[i] = {
        day: dayLabels[i],
        label: '',
        isActive: currentDate.getDay() === i,
        isOpen: false,
      }
    }
  }

  return [...formattedHours.slice(1), formattedHours[0]]
}
