import { select, call } from 'redux-saga/effects'

import { selectCampaignName, selectCampaignRetailerId } from '../../selectors/campaign'
import { prepareTrackingUrl } from './providers/streamfully'

function* initUnloadTracking() {
  const campaignName = yield select(selectCampaignName)
  const retailerId = yield select(selectCampaignRetailerId)
  const url = yield call(prepareTrackingUrl, {
    s2s_ea: 'end',
    s2s_el: campaignName,
    s2s_ev: retailerId,
  })

  yield call(window.addEventListener, 'unload', () => {
    navigator.sendBeacon(url)
  }, false)
}

export default initUnloadTracking
