import { types as actionTypes } from '../actions/hours'
import initialState from '../initialState/hours'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_HOURS: {
      const { hours } = action.payload
      return { ...hours }
    }
    case actionTypes.SET_STORE_HOURS: {
      const { storeId, hours } = action.payload

      return {
        ...state,
        [storeId]: [...hours],
      }
    }
    default:
      return state
  }
}
