import createActionTypes from './createActionTypes'

export const namespace = 'LAYOUT'

export const types = createActionTypes(namespace, [
  'SET_LAYOUT',
  'SET_PRODUCT_SEARCH',
])

export const setLayout = layout => ({
  type: types.SET_LAYOUT,
  payload: { layout },
})

export const setProductSearchVisible = isSearchVisible => ({
  type: types.SET_PRODUCT_SEARCH,
  payload: { isSearchVisible },
})
