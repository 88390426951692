import { put, select } from 'redux-saga/effects'
import { history } from '../..'

import { getCollectionProductPageRoute } from '../../../helpers/getAppRoutes'
import { setProductInfoDrawerOpen, setCurrentProduct } from '../../actions/ux'
import { selectRouterLocation } from '../../selectors/router'
import { selectCurrentCampaignNameId } from '../../selectors/ux'

import {
  EXPANDABLE_DRAWER_MODE_PREVIEW,
} from '../../../helpers/const'

function* onProductInfoDrawerOpen(action) {
  const { params: { mode, productId } } = action.payload
  const { search } = yield select(selectRouterLocation)
  const campaignNameId = yield select(selectCurrentCampaignNameId)

  yield put(setCurrentProduct(productId))
  yield put(setProductInfoDrawerOpen(mode || EXPANDABLE_DRAWER_MODE_PREVIEW))

  history.push({
    pathname: getCollectionProductPageRoute(campaignNameId, productId),
    search,
  })
}

export default onProductInfoDrawerOpen
