import { select, put } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import { selectRouterLocation } from '../../selectors/router'
import { CONTEXT_HEADER } from '../../../helpers/const'

function* onBrandHeaderBackClick(action) {
  const { search } = yield select(selectRouterLocation)
  const { params: { pathname } } = action.payload

  yield put(replace({
    pathname,
    state: { from: CONTEXT_HEADER },
    search,
  }))
}

export default onBrandHeaderBackClick
