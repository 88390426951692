const commons = {
  en_au: {
    goToStore: 'Go to the store',
    nearestStoreAt: 'Store closest to',
    searchPlaceholder: 'Search among the offers',
    nearestStore: 'Nearest store',
    nearestStoreApproximated: 'Store - Attention, we couldn\'t detect your exact location, the shop may not be close to you.',
    nearestStores: 'Stores near you',
    emptyStateStores: 'No store locations in the area',
    callcenterHours: 'Customer Service hours',
    emptyStateTitle: 'No result',
    emptyStateBody: 'The product you are looking for is not present in this offer or was not typed in correctly. Try again with a new search!',
    previousProduct: 'Previous',
    nextProduct: 'Next',
    viewAllProducts: 'View all products',
    viewAll: 'View all',
    filters: 'Filters',
    uncheckAll: 'Clear All',
    uncheckAllCategories: 'Clear all filters',
    saveFavourites: 'Save',
    removeFavourites: 'Delete from my list',
    ok: 'ok',
    show: 'Show',
    pageOf: 'of',
    showLongDesc: 'View Details',
    hideLongDesc: 'Close Details',
    productNotFound: 'Offer not found',
    otherDeals: 'Other deals',
    productExpired: 'Sorry: the offer you have selected is no longer valid',
    daysOfWeek: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    close: 'close',
    open: 'open',
    map: 'map',
    list: 'list',
    availability: {
      'in stock': 'Available',
      'limited stock': 'Last pieces!',
      preorder: 'Arriving',
      'out of stock': 'Expired',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Show more',
      hide: 'Show less',
    },
    extendableDrawer: {
      expand: 'Expand',
      reduce: 'Collapse',
      close: 'Close',
    },
    description: 'Description',
  },
  en_nz: {
    goToStore: 'Go to the store',
    nearestStoreAt: 'Store closest to',
    emptyStateStores: 'No store locations in the area',
    searchPlaceholder: 'Search among the offers',
    nearestStore: 'Nearest store',
    nearestStoreApproximated: 'Store - Attention, we couldn\'t detect your exact location, the shop may not be close to you.',
    nearestStores: 'Stores near you',
    callcenterHours: 'Customer Service hours',
    emptyStateTitle: 'No result',
    emptyStateBody: 'The product you are looking for is not present in this offer or was not typed in correctly. Try again with a new search!',
    previousProduct: 'Previous',
    nextProduct: 'Next',
    viewAllProducts: 'View all products',
    viewAll: 'View all',
    filters: 'Filters',
    uncheckAll: 'Clear All',
    uncheckAllCategories: 'Clear all filters',
    ok: 'ok',
    saveFavourites: 'Save',
    removeFavourites: 'Delete from my list',
    show: 'Show',
    pageOf: 'of',
    showLongDesc: 'View Details',
    hideLongDesc: 'Close Details',
    productNotFound: 'Offer not found',
    otherDeals: 'Other deals',
    productExpired: 'Sorry: the offer you have selected is no longer valid',
    daysOfWeek: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    close: 'close',
    open: 'open',
    map: 'map',
    list: 'list',
    availability: {
      'in stock': 'Available',
      'limited stock': 'Last pieces!',
      preorder: 'Arriving',
      'out of stock': 'Expired',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b><br/><br/> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: 'Proceed without accepting',
    },
    productsExpand: {
      show: 'Show more',
      hide: 'Show less',
    },
    extendableDrawer: {
      expand: 'Expand',
      reduce: 'Collapse',
      close: 'Close',
    },
    description: 'Description',
  },
  es_mx: {
    goToStore: 'Ir a la tienda',
    nearestStoreAt: 'Tienda más cercana a ti',
    searchPlaceholder: 'Busca entre las ofertas',
    nearestStore: 'Tienda más cercana',
    nearestStoreApproximated: 'Tienda - Atención, no detectamos tu ubicación exacta, es posible que la tienda no sea la más cercana a ti.',
    nearestStores: 'Tiendas cercanas a ti',
    emptyStateStores: 'No hay tiendas cercanas',
    callcenterHours: 'El horario de nuestro Servicio de Atención al Cliente',
    emptyStateTitle: 'No se obtuvieron resultados',
    emptyStateBody: 'El producto que buscas no está presente en esta promoción o no ha sido escrito correctamente. ¡Vuelve a intentarlo con una nueva búsqueda!',
    previousProduct: 'Anterior',
    nextProduct: 'Siguiente',
    viewAllProducts: 'Ver todos los productos',
    viewAll: 'Ver todos',
    filters: 'Filtros',
    uncheckAll: 'Deselecciona todo',
    uncheckAllCategories: 'Deselecciona todos los filtros',
    saveFavourites: 'Guarda',
    removeFavourites: 'Eliminar de mi lista',
    ok: 'ok',
    show: 'Muestra',
    pageOf: 'de',
    showLongDesc: 'Leer todo',
    hideLongDesc: 'Cerrar',
    productNotFound: 'Oferta no encontrada',
    otherDeals: 'Otras ofertas',
    productExpired: 'Lo sentimos: la oferta que has seleccionado ya no está disponible',
    daysOfWeek: {
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
    close: 'cerrado',
    open: 'abierto',
    map: 'mapa',
    list: 'lista',
    availability: {
      'in stock': 'Disponible',
      'limited stock': '¡Últimas piezas!',
      preorder: 'Por llegar',
      'out of stock': 'Agotado',
    },
    cookiebar: {
      content: '<b>Tu privacidad es importante para nosotros.</b></br></br>En nuestro sitio utilizamos cookies técnicas y, con algunos partner seleccionados y tu consentimiento previo, también cookies de perfil, para analizar tu comportamiento y ofrecerte contenidos que respondan a tus preferencias. Clicando en “Acepta todo” das tu consentimiento y puedes visualizar contenidos y anuncios publicitarios inherentes a tus intereses. En cualquier momento podrás especificar tus preferencias clicando en “Gestiona opciones”. Al cerrar este banner continúas navengando sin haber instalado las cookies de perfil, es decir, el contenido del sitio y los anuncios publicitarios se basarán en argumentos generales que probablemente no responden a tus intereses. Para mayor información visita nuestra %{privacy_policy} y %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Política de Cookies',
      customizeButtonCaption: 'Gestiona opciones',
      privacyPolicyCaption: 'Política de Privacidad',
      acceptButtonCaption: 'Acepta todo',
      closeButtonCaption: 'Continúa navegando sin aceptar',
    },
    productsExpand: {
      show: 'Ver todos',
      hide: 'Ocultar',
    },
    extendableDrawer: {
      expand: 'Ver más',
      reduce: 'Ver menos',
      close: 'Cerrar',
    },
    description: 'Descripción',
  },
  it_it: {
    goToStore: 'Vai allo store',
    nearestStoreAt: 'Negozio più vicino a',
    searchPlaceholder: 'Cerca tra i prodotti',
    nearestStore: 'Negozio più vicino',
    nearestStoreApproximated: 'Negozio - Attenzione non rileviamo la tua posizione esatta, il negozio potrebbe non essere quello più vicino a te.',
    nearestStores: 'Negozi intorno a te',
    emptyStateStores: 'Non ci sono negozi nelle vicinanze',
    callcenterHours: 'L’orario del nostro centralino',
    emptyStateTitle: 'Nessun risultato ottenuto',
    emptyStateBody: 'Il prodotto che cerchi non risulta presente in questa promozione o non è stato digitato correttamente. Riprova con una nuova ricerca!',
    previousProduct: 'Precedente',
    nextProduct: 'Successivo',
    viewAllProducts: 'Vedi tutti i prodotti',
    viewAll: 'Mostra tutti',
    filters: 'Filtri',
    uncheckAll: 'Deseleziona tutto',
    uncheckAllCategories: 'Deseleziona tutti i filtri',
    saveFavourites: 'Salva',
    removeFavourites: 'Elimina dalla mia lista',
    ok: 'ok',
    show: 'Mostra',
    pageOf: 'di',
    showLongDesc: 'Leggi tutto',
    hideLongDesc: 'Chiudi descrizione',
    productNotFound: 'Offerta non trovata',
    otherDeals: 'Altre offerte',
    productExpired: "Siamo spiacenti: l'offerta che hai selezionato non è più disponibile",
    daysOfWeek: {
      monday: 'Lunedì',
      tuesday: 'Martedì',
      wednesday: 'Mercoledì',
      thursday: 'Giovedì',
      friday: 'Venerdì',
      saturday: 'Sabato',
      sunday: 'Domenica',
    },
    close: 'chiuso',
    open: 'aperto',
    map: 'mappa',
    list: 'lista',
    availability: {
      'in stock': 'Disponibile',
      'limited stock': 'Ultimi pezzi!',
      preorder: 'In arrivo',
      'out of stock': 'Esaurito',
    },
    cookiebar: {
      content: '<b>La tua privacy è importante per noi.</b></br></br>Utilizziamo nel nostro sito cookie tecnici e, con alcuni partner selezionati e previo tuo consenso, anche cookie di profilazione, per effettuare analisi sul tuo comportamento e fornirti contenuti in linea con le tue preferenze. Cliccando su “Accetta tutto” dai il consenso e puoi visualizzare contenuti e annunci pubblicitari attinenti ai tuoi interessi. In ogni momento puoi specificare le tue preferenze cliccando su “Gestisci opzioni”. Se chiudi questo banner continui la navigazione senza che vengano installati cookie di profilazione, ma il contenuto del sito e gli annunci pubblicitari riguarderanno argomenti generici non in linea con i tuoi interessi. Per maggiori informazioni visita la nostra %{privacy_policy} e %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie Policy',
      customizeButtonCaption: 'Gestisci opzioni',
      privacyPolicyCaption: 'Privacy Policy',
      acceptButtonCaption: 'Accetta tutto',
      closeButtonCaption: 'Continua senza accettare',
    },
    infoStore: {
      ctaText: 'Chiama il negozio',
    },
    productsExpand: {
      show: 'Mostra tutti',
      hide: 'Nascondi',
    },
    extendableDrawer: {
      expand: 'Espandi',
      reduce: 'Riduci',
      close: 'Chiudi',
    },
    description: 'Descrizione',
  },
  pt_br: {
    goToStore: 'Vá à loja',
    nearestStoreAt: 'Loja mais próxima a',
    searchPlaceholder: 'Procure entre as ofertas',
    nearestStore: 'Loja mais próxima',
    nearestStoreApproximated: 'Lojas- Atenção não conseguimos identificar a sua localização exata: as lojas mostradas podem não ser aquelas próximas a você',
    nearestStores: 'Lojas perto de você',
    emptyStateStores: 'Não existem lojas nas proximidades',
    callcenterHours: 'Horário do nosso serviço ao cliente',
    emptyStateTitle: 'Nenhum resultado encontrado',
    emptyStateBody: 'O produto procurado não se encontra entre as promoções ou não foi escrito corretamente. Tente outra vez!',
    previousProduct: 'Anterior',
    nextProduct: 'Próximo',
    viewAllProducts: 'Veja todos os produtos',
    viewAll: 'Veja todos',
    filters: 'Filtros',
    uncheckAll: 'Desmarcar tudo',
    uncheckAllCategories: 'Desmarcar todos os filtros',
    saveFavourites: 'Salva',
    removeFavourites: 'Eliminar da minha lista',
    ok: 'ok',
    show: 'Mostrar',
    pageOf: 'de',
    showLongDesc: 'Detalhes',
    hideLongDesc: 'Fechar detalhes',
    productNotFound: 'Oferta não encontrada',
    otherDeals: 'Outras ofertas',
    productExpired: 'Desculpe mas a oferta que você está procurando não está mais disponível',
    daysOfWeek: {
      monday: 'Segunda',
      tuesday: 'Terça',
      wednesday: 'Quarta',
      thursday: 'Quinta',
      friday: 'Sexta',
      saturday: 'Sábado',
      sunday: 'Domingo',
    },
    close: 'fechado',
    open: 'aberto',
    map: 'mapa',
    list: 'lista',
    availability: {
      'in stock': 'Disponível',
      'limited stock': 'Últimos preços!',
      preorder: 'Em breve',
      'out of stock': 'Esgotado',
    },
    cookiebar: {
      content: '<b>A sua privacidade é importante para nós.</b></br></br>.No nosso site utilizamos cookies técnicos e, com alguns parceiros selezionados e com o seu prévio consentimento, utilizamos também cookies de segmentação, para efetuar uma análise do seu comportamento e fornecer a você conteúdos relacionados às suas preferências. Clicando em ‘’Aceitar todos’’ você autoriza e poderá visualizar conteúdos e  anúncios publicitários pertinentes ao seu interesse. Você pode, a qualquer momento, especificar as suas preferências acessando a opção ‘’ Gerenciar opções’’. Se você fechar esse banner irá continuar a sua navegação sem a instalação de cookies de segmentação, mas o conteúdo do site e os anúncios publicitários serão sobre assuntos genéricos e não pertinentes ao seu interesse. Para maiores informações visite a nossa %{privacy_policy} e %{cookie_policy_link}',
      cookiePolicyLinkCaption: 'Politica de Cookies',
      customizeButtonCaption: 'Gerenciar opções',
      privacyPolicyCaption: 'Politica de Privacidade',
      acceptButtonCaption: 'Aceitar todos',
      closeButtonCaption: 'Continuar navegando sem aceitar',
    },
    productsExpand: {
      show: 'Ver todos',
      hide: 'Ocultar',
    },
    extendableDrawer: {
      expand: 'Mais',
      reduce: 'Menos',
      close: 'Fechar',
    },
    description: 'Descrição',
  },
  bg_bg: {
    goToStore: 'Отиди в магазина за приложения',
    nearestStoreAt: 'Магазин, който е най-близо до',
    searchPlaceholder: 'Търсене сред офертите',
    nearestStore: 'Магазини наоколо',
    nearestStoreApproximated: 'Внимание, не успяхме да открием точното ви местоположение, магазинът може да не е близо до вас.',
    nearestStores: 'Магазини близо до вас',
    emptyStateStores: 'Няма магазини',
    callcenterHours: 'Часове за обслужване на клиенти',
    emptyStateTitle: 'Няма резултати',
    emptyStateBody: 'Опитайте друго търсене!',
    previousProduct: 'Предишна',
    nextProduct: 'Следваща',
    viewAllProducts: 'Виж всички оферти',
    viewAll: 'Вижте всички',
    filters: 'Филтри',
    uncheckAll: 'Изчистване на всички',
    uncheckAllCategories: 'Изчистване на филтрите',
    saveFavourites: 'Запази',
    removeFavourites: 'Изтрий от моя списък',
    ok: 'Приемам',
    show: 'Покажи',
    pageOf: 'на',
    showLongDesc: 'Покажи детайли',
    hideLongDesc: 'Затвори Детайли',
    productNotFound: 'Офертата не е намерена',
    otherDeals: 'Други оферти',
    productExpired: 'Съжаляваме: избраната от вас оферта вече не е валидна',
    daysOfWeek: {
      monday: 'понеделник',
      tuesday: 'вторник',
      wednesday: 'сряда',
      thursday: 'четвъртък',
      friday: 'петък',
      saturday: 'събота',
      sunday: 'неделя',
    },
    close: 'Затвори',
    open: 'Отвори',
    map: 'Карта',
    list: 'Списък',
    availability: {
      'in stock': 'На разположение',
      'limited stock': 'Последни единици!',
      preorder: 'Очаквай',
      'out of stock': 'С изтекъл срок',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Виж още',
      hide: 'Покажи по-малко',
    },
    extendableDrawer: {
      expand: 'Разширете',
      reduce: 'Свиване',
      close: 'Затвори',
    },
    description: 'Описание',
  },
  ro_ro: {
    goToStore: 'Accesează magazinul',
    nearestStoreAt: 'Magazinul cel mai apropiat de',
    searchPlaceholder: 'Căutați printre oferte',
    nearestStore: 'Cel mai apropiat magazin',
    nearestStoreApproximated: 'Atenție, nu am putut detecta locația dvs. exactă, este posibil ca magazinul să nu fie aproape de dvs.',
    nearestStores: 'Magazinele din apropierea dumneavoastră',
    emptyStateStores: 'Nu există magazine',
    callcenterHours: 'Orele Serviciului Clienți',
    emptyStateTitle: 'Fără rezultate',
    emptyStateBody: 'Încercați o altă căutare!',
    previousProduct: 'Anterior',
    nextProduct: 'Următorul',
    viewAllProducts: 'Vizualizează toate ofertele',
    viewAll: 'Vizualizează toate',
    filters: 'Filtre',
    uncheckAll: 'Ștergeți toate',
    uncheckAllCategories: 'Șterge filtrele',
    saveFavourites: 'Salvează',
    removeFavourites: 'Ștergeți din lista mea',
    ok: 'Acceptă',
    show: 'Arată',
    pageOf: 'de',
    showLongDesc: 'Arată detalii',
    hideLongDesc: 'Închideți detalii',
    productNotFound: 'Oferta nu a fost găsită',
    otherDeals: 'Alte oferte',
    productExpired: 'Ne pare rău: oferta pe care ați selectat-o nu mai este valabilă',
    daysOfWeek: {
      monday: 'Luni',
      tuesday: 'Marţi',
      wednesday: 'Miercuri',
      thursday: 'Joi',
      friday: 'Vineri',
      saturday: 'Sâmbată',
      sunday: 'Duminică',
    },
    close: 'Închide',
    open: 'Deschideți',
    map: 'Hartă',
    list: 'Lista',
    availability: {
      'in stock': 'Disponibil',
      'limited stock': 'Ultimele unități!',
      preorder: 'Anticipat',
      'out of stock': 'Expirat',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Vezi mai mult',
      hide: 'Vezi mai puțin',
    },
    extendableDrawer: {
      expand: 'Extindeți',
      reduce: 'Colaps',
      close: 'Închide',
    },
    description: 'Descriere',
  },
  cs_cz: {
    goToStore: 'Jít do obchodů',
    nearestStoreAt: 'Obchod nejblíže',
    searchPlaceholder: 'Vyhledávání mezi nabídkami',
    nearestStore: 'Nejbližší obchod',
    nearestStoreApproximated: 'Pozor, nepodařilo se nám zjistit vaši přesnou polohu, obchod nemusí být ve vaší blízkosti.',
    nearestStores: 'Obchody ve vašem okolí',
    emptyStateStores: 'Žádné obchody',
    callcenterHours: 'Hodiny zákaznického servisu',
    emptyStateTitle: 'Žádné výsledky',
    emptyStateBody: 'Zkuste jiné vyhledávání!',
    previousProduct: 'Předchozí',
    nextProduct: 'Další',
    viewAllProducts: 'Zobrazit všechny nabídky',
    viewAll: 'Zobrazit všechny',
    filters: 'Filtry',
    uncheckAll: 'Vymazat vše',
    uncheckAllCategories: 'Vymazat filtry',
    saveFavourites: 'Uložit',
    removeFavourites: 'Odstranit z mého seznamu',
    ok: 'Přijmout',
    show: 'Zobrazit',
    pageOf: 'z',
    showLongDesc: 'Zobrazit podrobnosti',
    hideLongDesc: 'Zavřít podrobnosti',
    productNotFound: 'Nabídka nebyla nalezena',
    otherDeals: 'Další nabídky',
    productExpired: 'Omlouváme se: Vámi vybraná nabídka již není platná.',
    daysOfWeek: {
      monday: 'Pondĕlí',
      tuesday: 'Úterý',
      wednesday: 'Středa',
      thursday: 'Čtvrtek',
      friday: 'Pátek',
      saturday: 'Sobota',
      sunday: 'Nedĕle',
    },
    close: 'Zavřít',
    open: 'Otevřít',
    map: 'Mapa',
    list: 'Seznam',
    availability: {
      'in stock': 'Dostupné',
      'limited stock': 'Poslední jednotky!',
      preorder: 'Očekávaný',
      'out of stock': 'Vypršelo',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Zobrazit více',
      hide: 'Ukázat méne',
    },
    extendableDrawer: {
      expand: 'Rozbalit',
      reduce: 'Sbalit',
      close: 'Zavřít',
    },
    description: 'Popis',
  },
  fr_fr: {
    goToStore: 'Aller au magasin',
    nearestStoreAt: 'Magasin le plus proche à',
    searchPlaceholder: 'Rechercher parmi les offres',
    nearestStore: 'Magasin le plus proche',
    nearestStoreApproximated: 'Magasin - Attention, nous n\'avons pas pu détecter votre emplacement exact, le magasin n\'est peut-être pas proche de chez vous.',
    nearestStores: 'Magasins près de chez vous',
    emptyStateStores: 'Aucun magasin dans le secteur',
    callcenterHours: 'Horaires du service client',
    emptyStateTitle: 'Pas de résultat',
    emptyStateBody: 'Le produit que vous recherchez n\'est pas présent dans cette offre ou n\'a pas été saisi correctement. Réessayez avec une nouvelle recherche!',
    previousProduct: 'Précédent',
    nextProduct: 'Suivant',
    viewAllProducts: 'Voir tous les produits',
    viewAll: 'Voir tout',
    filters: 'Filtres',
    uncheckAll: 'Tout effacer',
    uncheckAllCategories: 'Supprimer tous les filtres',
    saveFavourites: 'Sauvegarder',
    removeFavourites: 'Supprimer de ma liste',
    ok: 'appliquer',
    show: 'Montrer',
    pageOf: 'de',
    showLongDesc: 'Voir les détails',
    hideLongDesc: 'Fermer les détails',
    productNotFound: 'Offre introuvable',
    otherDeals: 'Autres offres',
    productExpired: 'Désolé : l\'offre que vous avez sélectionnée n\'est plus valable',
    daysOfWeek: {
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      sunday: 'Dimanche',
    },
    close: 'fermé',
    open: 'ouvrir',
    map: 'carte',
    list: 'liste',
    availability: {
      'in stock': 'Disponible',
      'limited stock': 'Dernières pièces!',
      preorder: 'En arrivant',
      'out of stock': 'Expiré',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Voir plus',
      hide: 'Voir moins',
    },
    extendableDrawer: {
      expand: 'Ouvrir',
      reduce: 'Réduire',
      close: 'Fermer',
    },
    description: 'Description',
  },
  tr_tr: {
    goToStore: 'Mağazaya Git',
    nearestStoreAt: 'En yakın mağaza',
    searchPlaceholder: 'Ürünler arasında arama yapın',
    nearestStore: 'En yakın mağaza',
    nearestStoreApproximated: 'Mağaza - Dikkat, tam konumunuzu tespit edemiyoruz, mağaza size en yakın olmayabilir.',
    nearestStores: 'Çevrenizdeki mağazalar',
    emptyStateStores: 'Yakınlarda mağaza yok',
    callcenterHours: 'Santral saatlerimiz',
    emptyStateTitle: 'Sonuç elde edilemedi',
    emptyStateBody: 'Aradığınız ürün bu promosyonda mevcut değil veya doğru girilmemiş. Yeni bir aramayla tekrar deneyin!',
    previousProduct: 'Öncesi',
    nextProduct: 'Takip etme',
    viewAllProducts: 'Tüm ürünleri görün',
    viewAll: 'Hepsini Göster',
    filters: 'Filtreler',
    uncheckAll: 'Her şeyin seçimini kaldır',
    uncheckAllCategories: 'Tüm filtrelerin seçimini kaldır',
    saveFavourites: 'Kaydetmek',
    removeFavourites: 'Listemden kaldır',
    ok: 'Tamam',
    show: 'Göstermek',
    pageOf: 'İle ilgili',
    showLongDesc: 'Her şeyi okuyun',
    hideLongDesc: 'Açıklamayı kapat',
    productNotFound: 'Teklif bulunamadı',
    otherDeals: 'Diğer İndirimler',
    productExpired: 'Üzgünüz, seçtiğiniz teklif artık mevcut değil',
    daysOfWeek: {
      monday: 'Pazartesi',
      tuesday: 'Salı',
      wednesday: 'Çarşamba',
      thursday: 'Perşembe',
      friday: 'Cuma',
      saturday: 'Cumartesi',
      sunday: 'Pazar',
    },
    close: 'kapalı',
    open: 'açık',
    map: 'harita',
    list: 'liste',
    availability: {
      'in stock': 'Stokta var',
      'limited stock': 'Son parçalar!',
      preorder: 'Geliyor',
      'out of stock': 'Tükendi',
    },
    cookiebar: {
      content: '<b>La tua privacy è importante per noi.</b></br></br>Utilizziamo nel nostro sito cookie tecnici e, con alcuni partner selezionati e previo tuo consenso, anche cookie di profilazione, per effettuare analisi sul tuo comportamento e fornirti contenuti in linea con le tue preferenze. Cliccando su “Accetta tutto” dai il consenso e puoi visualizzare contenuti e annunci pubblicitari attinenti ai tuoi interessi. In ogni momento puoi specificare le tue preferenze cliccando su “Gestisci opzioni”. Se chiudi questo banner continui la navigazione senza che vengano installati cookie di profilazione, ma il contenuto del sito e gli annunci pubblicitari riguarderanno argomenti generici non in linea con i tuoi interessi. Per maggiori informazioni visita la nostra %{privacy_policy} e %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie Policy',
      customizeButtonCaption: 'Gestisci opzioni',
      privacyPolicyCaption: 'Privacy Policy',
      acceptButtonCaption: 'Accetta tutto',
      closeButtonCaption: 'Continua senza accettare',
    },
    infoStore: {
      ctaText: 'Mağazayı ara',
    },
    productsExpand: {
      show: 'Tümünü göster',
      hide: 'Gizle',
    },
    extendableDrawer: {
      expand: 'Genişlet',
      reduce: 'Küçült',
      close: 'Kapat',
    },
    description: 'Açıklama',
  },
  de_de: {
    goToStore: 'Gehen Sie zum Geschäft',
    nearestStoreAt: 'Nächster Laden bei',
    searchPlaceholder: 'Suche unter den Angeboten',
    nearestStore: 'Nächster Laden',
    nearestStoreApproximated: 'Geschäft - Achtung, wir konnten Ihren genauen Standort nicht ermitteln, das Geschäft ist möglicherweise nicht in Ihrer Nähe.',
    nearestStores: 'Geschäfte in Ihrer Nähe',
    emptyStateStores: 'Keine Geschäftsstandorte in der Umgebung',
    callcenterHours: 'Öffnungszeiten des Kundenservice',
    emptyStateTitle: 'Kein Ergebnis',
    emptyStateBody: 'Das Produkt, das Sie suchen, ist in diesem Angebot nicht vorhanden oder wurde nicht korrekt eingegeben. Versuchen Sie es erneut mit einer neuen Suche!',
    previousProduct: 'Vorheriges',
    nextProduct: 'Nächstes',
    viewAllProducts: 'Alle Produkte anzeigen',
    viewAll: 'Alle anzeigen',
    filters: 'Filter',
    uncheckAll: 'Alle abwählen',
    uncheckAllCategories: 'Alle Filter löschen',
    saveFavourites: 'Speichern',
    removeFavourites: 'Von meiner Liste löschen',
    ok: 'Ok',
    show: 'Anzeigen',
    pageOf: 'von',
    showLongDesc: 'Details anzeigen',
    hideLongDesc: 'Details schließen',
    productNotFound: 'Angebot nicht gefunden',
    otherDeals: 'Andere Angebote',
    productExpired: 'Entschuldigung: Das von Ihnen ausgewählte Angebot ist nicht mehr gültig',
    daysOfWeek: {
      monday: 'Montag',
      tuesday: 'Dienstag',
      wednesday: 'Mittwoch',
      thursday: 'Donnerstag',
      friday: 'Freitag',
      saturday: 'Samstag',
      sunday: 'Sonntag',
    },
    close: 'Schließen',
    open: 'Öffnen',
    map: 'Karte',
    list: 'Liste',
    availability: {
      'in stock': 'Verfügbar',
      'limited stock': 'Letzte Stücke!',
      preorder: 'Ankunft',
      'out of stock': 'Abgelaufen',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Mehr anzeigen',
      hide: 'Weniger anzeigen',
    },
    extendableDrawer: {
      expand: 'Erweitern',
      reduce: 'Zusammenklappen',
      close: 'Schließen',
    },
    description: 'Beschreibung',
  },
  nb_no: {
    goToStore: 'Gå til butikken',
    nearestStoreAt: 'Nærmeste butikk til',
    searchPlaceholder: 'Søk blant tilbudene',
    nearestStore: 'Nærmeste butikk',
    nearestStoreApproximated: 'Butikk - Oppmerksomhet, vi kunne ikke oppdage din nøyaktige plassering, butikken er kanskje ikke i nærheten av deg.',
    nearestStores: 'Butikker i nærheten av deg',
    emptyStateStores: 'Ingen butikklokasjoner i området',
    callcenterHours: 'Kundeservice timer',
    emptyStateTitle: 'Ingen resultat',
    emptyStateBody: 'Produktet du leter etter er ikke til stede i dette tilbudet eller ble ikke skrevet inn riktig. Prøv igjen med et nytt søk!',
    previousProduct: 'Forrige',
    nextProduct: 'Neste',
    viewAllProducts: 'Vis alle produkter',
    viewAll: 'Vis alle',
    filters: 'Filtre',
    uncheckAll: 'Fjern alle',
    uncheckAllCategories: 'Fjern alle filtre',
    saveFavourites: 'Lagre',
    removeFavourites: 'Slett fra listen min',
    ok: 'ok',
    show: 'Vis',
    pageOf: 'av',
    showLongDesc: 'Vis detaljer',
    hideLongDesc: 'Lukk detaljer',
    productNotFound: 'Tilbud ikke funnet',
    otherDeals: 'Andre tilbud',
    productExpired: 'Beklager: tilbudet du har valgt er ikke lenger gyldig',
    daysOfWeek: {
      monday: 'Mandag',
      tuesday: 'Tirsdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Fredag',
      saturday: 'Lørdag',
      sunday: 'Søndag',
    },
    close: 'lukk',
    open: 'åpen',
    map: 'kart',
    list: 'liste',
    availability: {
      'in stock': 'Tilgjengelig',
      'limited stock': 'Siste stykker!',
      preorder: 'Ankommer',
      'out of stock': 'Utgått',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Vis mer',
      hide: 'Vis mindre',
    },
    extendableDrawer: {
      expand: 'Utvid',
      reduce: 'Kollaps',
      close: 'Lukk',
    },
    description: 'Beskrivelse',
  },
  nl_nl: {
    goToStore: 'Ga naar de winkel',
    nearestStoreAt: 'Dichtstbijzijnde winkel bij',
    searchPlaceholder: 'Zoek tussen de aanbiedingen',
    nearestStore: 'Dichtstbijzijnde winkel',
    nearestStoreApproximated: 'Winkel - Let op, we konden uw exacte locatie niet detecteren, de winkel is mogelijk niet dichtbij u.',
    nearestStores: 'Winkels bij u in de buurt',
    emptyStateStores: 'Geen winkellocaties in het gebied',
    callcenterHours: 'Openingstijden van de klantenservice',
    emptyStateTitle: 'Geen resultaat',
    emptyStateBody: 'Het product dat u zoekt, is niet aanwezig in dit aanbod of is niet correct ingevoerd. Probeer het opnieuw met een nieuwe zoekopdracht!',
    previousProduct: 'Vorige',
    nextProduct: 'Volgende',
    viewAllProducts: 'Bekijk alle producten',
    viewAll: 'Bekijk alles',
    filters: 'Filters',
    uncheckAll: 'Alles wissen',
    uncheckAllCategories: 'Wis alle filters',
    saveFavourites: 'Opslaan',
    removeFavourites: 'Verwijder uit mijn lijst',
    ok: 'ok',
    show: 'Toon',
    pageOf: 'van',
    showLongDesc: 'Bekijk Details',
    hideLongDesc: 'Details sluiten',
    productNotFound: 'Aanbieding niet gevonden',
    otherDeals: 'Andere aanbiedingen',
    productExpired: 'Sorry: de aanbieding die u heeft geselecteerd is niet langer geldig',
    daysOfWeek: {
      monday: 'Maandag',
      tuesday: 'Dinsdag',
      wednesday: 'Woensdag',
      thursday: 'Donderdag',
      friday: 'Vrijdag',
      saturday: 'Zaterdag',
      sunday: 'Zondag',
    },
    close: 'sluit',
    open: 'open',
    map: 'kaart',
    list: 'lijst',
    availability: {
      'in stock': 'Beschikbaar',
      'limited stock': 'Laatste stuks!',
      preorder: 'Aankomen',
      'out of stock': 'Verlopen',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Toon meer',
      hide: 'Toon minder',
    },
    extendableDrawer: {
      expand: 'Uitbreiden',
      reduce: 'Inklappen',
      close: 'Sluiten',
    },
    description: 'Beschrijving',
  },
  da_dk: {
    goToStore: 'Gå til butikken',
    nearestStoreAt: 'Nærmeste butik ved',
    searchPlaceholder: 'Søg blandt tilbuddene',
    nearestStore: 'Nærmeste butik',
    nearestStoreApproximated: 'Butik - Oplysning, vi kunne ikke registrere din exakte placering, butikken kan være langt væk fra dig.',
    nearestStores: 'Butikker nær dig',
    emptyStateStores: 'Ingen butikssteder i området',
    callcenterHours: 'Kundeservice-timer',
    emptyStateTitle: 'Ingen resultater',
    emptyStateBody: 'Produktet, du leder efter, er ikke til stede i denne tilbud eller blev ikke skrevet ind korrekt. Prøv igen med en ny søgning!',
    previousProduct: 'Forrige',
    nextProduct: 'Næste',
    viewAllProducts: 'Se alle produkter',
    viewAll: 'Se alt',
    filters: 'Filtre',
    uncheckAll: 'Ryd alle',
    uncheckAllCategories: 'Ryd alle filtre',
    saveFavourites: 'Gem',
    removeFavourites: 'Slet fra min liste',
    ok: 'ok',
    show: 'Vis',
    pageOf: 'af',
    showLongDesc: 'Vis detaljer',
    hideLongDesc: 'Luk detaljer',
    productNotFound: 'Tilbud ikke fundet',
    otherDeals: 'Andre aftaler',
    productExpired: 'Beklager: det valgte tilbud er ikke længere gyldigt',
    daysOfWeek: {
      monday: 'Mandag',
      tuesday: 'Tirsdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Fredag',
      saturday: 'Lørdag',
      sunday: 'Søndag',
    },
    close: 'luk',
    open: 'åben',
    map: 'kort',
    list: 'liste',
    availability: {
      'in stock': 'Tilgængelig',
      'limited stock': 'Sidste stykker!',
      preorder: 'Ankommer',
      'out of stock': 'Udsolgt',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Vis flere',
      hide: 'Vis færre',
    },
    extendableDrawer: {
      expand: 'Udvid',
      reduce: 'Fold',
      close: 'Luk',
    },
    description: 'Beskrivelse',
  },
  sv_se: {
    goToStore: 'Gå till butiken',
    nearestStoreAt: 'Närmaste butik vid',
    searchPlaceholder: 'Sök bland erbjudandena',
    nearestStore: 'Närmaste butik',
    nearestStoreApproximated: 'Butik - OBS, vi kunde inte detektera din exakta plats, butiken kan inte vara nära dig.',
    nearestStores: 'Butiker nära dig',
    emptyStateStores: 'Inga butiks platser i området',
    callcenterHours: 'Kundservice timmar',
    emptyStateTitle: 'Inget resultat',
    emptyStateBody: 'Produkten du söker är inte tillgänglig i detta erbjudande eller skrevs inte in korrekt. Försök igen med en ny sökning!',
    previousProduct: 'Föregående',
    nextProduct: 'Nästa',
    viewAllProducts: 'Visa alla produkter',
    viewAll: 'Visa alla',
    filters: 'Filter',
    uncheckAll: 'Rensa alla',
    uncheckAllCategories: 'Rensa alla filter',
    saveFavourites: 'Spara',
    removeFavourites: 'Ta bort från min lista',
    ok: 'ok',
    show: 'Visa',
    pageOf: 'av',
    showLongDesc: 'Visa detaljer',
    hideLongDesc: 'Stäng detaljer',
    productNotFound: 'Erbjudandet hittades inte',
    otherDeals: 'Andra erbjudanden',
    productExpired: 'Tyvärr: det erbjudande du har valt är inte längre giltigt',
    daysOfWeek: {
      monday: 'Måndag',
      tuesday: 'Tisdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Fredag',
      saturday: 'Lördag',
      sunday: 'Söndag',
    },
    close: 'stäng',
    open: 'öppen',
    map: 'karta',
    list: 'lista',
    availability: {
      'in stock': 'Tillgänglig',
      'limited stock': 'Sista exemplaren!',
      preorder: 'Ankommande',
      'out of stock': 'Utgånget',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Visa mer',
      hide: 'Visa mindre',
    },
    extendableDrawer: {
      expand: 'Expandera',
      reduce: 'Kolla in',
      close: 'Stäng',
    },
    description: 'Beskrivning',
  },
  el_gr: {
    goToStore: 'Πήγαινε στο κατάστημα',
    nearestStoreAt: 'Κοντινότερο κατάστημα στο',
    searchPlaceholder: 'Αναζήτηση μεταξύ των προσφερόμενων',
    nearestStore: 'Κοντινότερο κατάστημα',
    nearestStoreApproximated: 'Κατάστημα - Προσοχή, δεν μπορήσαμε να εντοπίσουμε την ακραία σας τοποθεσία, το κατάστημα μπορεί να μην είναι κοντά σου.',
    nearestStores: 'Καταστήματα κοντά σου',
    emptyStateStores: 'Δεν υπάρχουν τοποθεσίες καταστημάτων στην περιοχή',
    callcenterHours: 'Ωρα της γραμμής πελατηρχείας',
    emptyStateTitle: 'Αποτέλεσμα δεν υπάρχει',
    emptyStateBody: 'Το προϊόν που ψάχνετε δεν υπάρχει σε αυτήν την προσφορά ή δεν γράφηκε σωστά. Δοκίμασε ξανά με μια νέα αναζήτηση!',
    previousProduct: 'Προηγούμενο',
    nextProduct: 'Επόμενο',
    viewAllProducts: 'Προβολή όλων των προϊόντων',
    viewAll: 'Προβολή όλων',
    filters: 'Φίλτρα',
    uncheckAll: 'Εκκαθάριση όλων',
    uncheckAllCategories: 'Εκκαθάριση όλων των φίλτρων',
    saveFavourites: 'Αποθήκευση',
    removeFavourites: 'Διαγραφή από τη λίστα μου',
    ok: 'οκ',
    show: 'Προβολή',
    pageOf: 'σελίδα',
    showLongDesc: 'Προβολή Λεπτομερειών',
    hideLongDesc: 'Κλείσιμο Λεπτομερειών',
    productNotFound: 'Η προσφορά δεν βρέθηκε',
    otherDeals: 'Άλλες προσφορές',
    productExpired: 'Λυπάμαι: η προσφορά που επιλέξατε δεν είναι πλέον έγκυρη',
    daysOfWeek: {
      monday: 'Δευτέρα',
      tuesday: 'Τρίτη',
      wednesday: 'Τετάρτη',
      thursday: 'Πέμπτη',
      friday: 'Παρασκευή',
      saturday: 'Σάββατο',
      sunday: 'Κυριακή',
    },
    close: 'κλείσιμο',
    open: 'ανοιχτό',
    map: 'χάρτης',
    list: 'λίστα',
    availability: {
      'in stock': 'Διαθέσιμο',
      'limited stock': 'Τελευταία μονάδα!',
      preorder: 'Προσφορά',
      'out of stock': 'Έχει ξεπεράσει',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Δείξε περισσότερα',
      hide: 'Δείξε λιγότερα',
    },
    extendableDrawer: {
      expand: 'Επέκταση',
      reduce: 'Συμπίεση',
      close: 'Κλείσιμο',
    },
    description: 'Περιγραφή',
  },
  pl_pl: {
    goToStore: 'Idź do sklepu',
    nearestStoreAt: 'Najbliższy sklep',
    searchPlaceholder: 'Wyszukaj wśród ofert',
    nearestStore: 'Najbliższy sklep',
    nearestStoreApproximated: 'Sklep - Uwaga, nie udało się dokładnie określić Twojego miejsca, sklep może nie być blisko Ciebie.',
    nearestStores: 'Sklepy w Twojej okolicy',
    emptyStateStores: 'Brak lokalizacji sklepów w obszarze',
    callcenterHours: 'Godziny obsługi klienta',
    emptyStateTitle: 'Brak wyników',
    emptyStateBody: 'Produkt, który szukasz, nie jest obecny w tej ofercie lub nie został wpisany poprawnie. Spróbuj ponownie z nowym wyszukiwaniem!',
    previousProduct: 'Poprzedni',
    nextProduct: 'Następny',
    viewAllProducts: 'Zobacz wszystkie produkty',
    viewAll: 'Zobacz wszystko',
    filters: 'Filtry',
    uncheckAll: 'Wyczyść wszystko',
    uncheckAllCategories: 'Wyczyść wszystkie filtry',
    saveFavourites: 'Zapisz',
    removeFavourites: 'Usuń z mojej listy',
    ok: 'ok',
    show: 'Pokaż',
    pageOf: 'z',
    showLongDesc: 'Pokaż szczegóły',
    hideLongDesc: 'Zamknij szczegóły',
    productNotFound: 'Oferta nie znaleziona',
    otherDeals: 'Inne oferty',
    productExpired: 'Przepraszamy: wybrana przez Ciebie oferta nie jest już prawidłowa',
    daysOfWeek: {
      monday: 'Poniedziałek',
      tuesday: 'Wtorek',
      wednesday: 'Środa',
      thursday: 'Czwartek',
      friday: 'Piątek',
      saturday: 'Sobota',
      sunday: 'Niedziela',
    },
    close: 'zamknij',
    open: 'otwarty',
    map: 'mapa',
    list: 'lista',
    availability: {
      'in stock': 'Dostępny',
      'limited stock': 'Ostatnie sztuki!',
      preorder: 'Przybywa',
      'out of stock': 'Wyczerpany',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Pokaż więcej',
      hide: 'Pokaż mniej',
    },
    extendableDrawer: {
      expand: 'Rozwiń',
      reduce: 'Zwiń',
      close: 'Zamknij',
    },
    description: 'Opis',
  },
  sk_sk: {
    goToStore: 'Pojdi do obchodu',
    nearestStoreAt: 'Obchod najbližší k',
    searchPlaceholder: 'Vyhľadajte medzi ponukami',
    nearestStore: 'Najbližší obchod',
    nearestStoreApproximated: 'Obchod - Pozor, nemohli sme presne zistiť vašu polohu, obchod nemusí byť blízko vám.',
    nearestStores: 'Obchody blízko vás',
    emptyStateStores: 'V oblasti nie sú žiadne umiestnenia obchodov',
    callcenterHours: 'Rámy zákazníckej služby',
    emptyStateTitle: 'Žiadny výsledok',
    emptyStateBody: 'Produkt, ktorý hľadáte, nie je v tejto ponuke prítomný alebo nebol správne zadaný. Skúste to znova s novým vyhľadávaním!',
    previousProduct: 'Predchádzajúci',
    nextProduct: 'Ďalší',
    viewAllProducts: 'Zobraziť všetky produkty',
    viewAll: 'Zobraziť všetko',
    filters: 'Filtre',
    uncheckAll: 'Vymazať všetko',
    uncheckAllCategories: 'Vymazať všetky filtre',
    saveFavourites: 'Uložiť',
    removeFavourites: 'Odstrániť z mojej zoznamu',
    ok: 'ok',
    show: 'Zobraziť',
    pageOf: 'z',
    showLongDesc: 'Zobraziť detaily',
    hideLongDesc: 'Zavrieť detaily',
    productNotFound: 'Ponuka sa nenašla',
    otherDeals: 'Iné ponuky',
    productExpired: 'Ospravedlňujeme sa: vybraná ponuka už nie je platná',
    daysOfWeek: {
      monday: 'Pondelok',
      tuesday: 'Utorok',
      wednesday: 'Streda',
      thursday: 'Štvrtok',
      friday: 'Piatok',
      saturday: 'Sobota',
      sunday: 'Nedeľa',
    },
    close: 'zatvoriť',
    open: 'otvoriť',
    map: 'mapa',
    list: 'zoznam',
    availability: {
      'in stock': 'Dostupné',
      'limited stock': 'Posledné kusy!',
      preorder: 'Príchod',
      'out of stock': 'Expirované',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Zobraziť viac',
      hide: 'Zobraziť menej',
    },
    extendableDrawer: {
      expand: 'Rozbaliť',
      reduce: 'Zbaliť',
      close: 'Zatvoriť',
    },
    description: 'Popis',
  },
  hu_hu: {
    goToStore: 'Menj a bolthoz',
    nearestStoreAt: 'A legközelebbi üzlet',
    searchPlaceholder: 'Keresés a ajánlatok között',
    nearestStore: 'Legközelebbi üzlet',
    nearestStoreApproximated: 'Üzlet - Figyelem, nem sikerült pontosan meghatározni a helyét, ezért a bolt nem lehet tőle messze.',
    nearestStores: 'Üzletek a környezetben',
    emptyStateStores: 'Nincsenek bolti helyszínek a területen',
    callcenterHours: 'Ügyfélszolgálati órák',
    emptyStateTitle: 'Nincs találat',
    emptyStateBody: 'Az amit keres, nem szerepel ebben az ajánlatban, vagy rosszul lett beírva. Próbálkozz újra egy új kereséssel!',
    previousProduct: 'Előző',
    nextProduct: 'Következő',
    viewAllProducts: 'Összes termék megtekintése',
    viewAll: 'Összes megtekintése',
    filters: 'Szűrők',
    uncheckAll: 'Mindent töröl',
    uncheckAllCategories: 'Összes szűrő törlése',
    saveFavourites: 'Mentés',
    removeFavourites: 'Törlés a listáról',
    ok: 'ok',
    show: 'Megjelenít',
    pageOf: 'lap',
    showLongDesc: 'Részletek megtekintése',
    hideLongDesc: 'Részletek bezárása',
    productNotFound: 'Ajánlat nem található',
    otherDeals: 'Egyéb ajánlatok',
    productExpired: 'Sajnos: a kiválasztott ajánlat már nem érvényes',
    daysOfWeek: {
      monday: 'Hétfő',
      tuesday: 'Kedd',
      wednesday: 'Szerda',
      thursday: 'Csütörtök',
      friday: 'Péntek',
      saturday: 'Szombat',
      sunday: 'Vasárnap',
    },
    close: 'bezár',
    open: 'nyit',
    map: 'térkép',
    list: 'lista',
    availability: {
      'in stock': 'Elérhető',
      'limited stock': 'Csak néhány darab van!',
      preorder: 'Elérhető előrendelésre',
      'out of stock': 'Kifogytam',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Több mutat',
      hide: 'Kevesebb mutat',
    },
    extendableDrawer: {
      expand: 'Kibont',
      reduce: 'Összecsuk',
      close: 'Bezár',
    },
    description: 'Leírás',
  },
  fi_fi: {
    goToStore: 'Mennyt kauppaan',
    nearestStoreAt: 'Lähin kauppa',
    searchPlaceholder: 'Hae tarjouksista',
    nearestStore: 'Lähin kauppa',
    nearestStoreApproximated: 'Kauppa - Huomaa, emme voineet tarkistaa täsmällistä sijaintisi, kauppa ei välttämättä ole lähellä sinua.',
    nearestStores: 'Kaupat lähellä',
    emptyStateStores: 'Alueella ei ole kauppojen sijainteita',
    callcenterHours: 'Asiakaspalvelun tunnit',
    emptyStateTitle: 'Ei tulosta',
    emptyStateBody: 'Etsimäsi tuote ei ole tässä tarjouslistassa tai sitä ei ole kirjoitettu oikein. Yritä uudelleen uudella haulla!',
    previousProduct: 'Edellinen',
    nextProduct: 'Seuraava',
    viewAllProducts: 'Näytä kaikki tuotteet',
    viewAll: 'Näytä kaikki',
    filters: 'Suodattimet',
    uncheckAll: 'Tyhjennä kaikki',
    uncheckAllCategories: 'Tyhjennä kaikki suodattimet',
    saveFavourites: 'Tallenna',
    removeFavourites: 'Poista luonnosta',
    ok: 'ok',
    show: 'Näytä',
    pageOf: 'sivulla',
    showLongDesc: 'Näytä tiedot',
    hideLongDesc: 'Sulje tiedot',
    productNotFound: 'Tarjous ei löydy',
    otherDeals: 'Muut tarjoukset',
    productExpired: 'Anteeksi: valitsemasi tarjous ei ole enää voimassa',
    daysOfWeek: {
      monday: 'Maanantai',
      tuesday: 'Tiistai',
      wednesday: 'Keskiviikko',
      thursday: 'Torstai',
      friday: 'Perjantai',
      saturday: 'Lauantai',
      sunday: 'Sunnuntai',
    },
    close: 'sulje',
    open: 'avoinna',
    map: 'kartta',
    list: 'luettelo',
    availability: {
      'in stock': 'Saatavilla',
      'limited stock': 'Varoen, loppuvaihtoehto!',
      preorder: 'Tulo',
      'out of stock': 'Elokuinen',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'Näytä lisää',
      hide: 'Näytä vähemmän',
    },
    extendableDrawer: {
      expand: 'Laajenna',
      reduce: 'Kutista',
      close: 'Sulje',
    },
    description: 'Kuvaus',
  },
  ko_kr: {
    goToStore: '가게에 가',
    nearestStoreAt: '가장 가까운 가게는',
    searchPlaceholder: '제품을 검색하세요',
    nearestStore: '가장 가까운 가게',
    nearestStoreApproximated: '가게 - 주의, 우리가 당신의 정확한 위치를 감지하지 못했습니다. 가게가 당신 근처에 있지 않을 수 있습니다.',
    nearestStores: '당신 근처의 가게',
    emptyStateStores: '지역에 가게 위치가 없습니다',
    callcenterHours: '고객 서비스 시간',
    emptyStateTitle: '결과 없음',
    emptyStateBody: '찾고 있는 제품이 이 제품에 없거나 잘못 입력되었습니다. 새로운 검색으로 다시 시도하세요!',
    previousProduct: '이전',
    nextProduct: '다음',
    viewAllProducts: '모든 제품 보기',
    viewAll: '모두 보기',
    filters: '필터',
    uncheckAll: '모두 지우기',
    uncheckAllCategories: '모든 필터 지우기',
    saveFavourites: '저장',
    removeFavourites: '나의 목록에서 삭제',
    ok: '확인',
    show: '보여주기',
    pageOf: '의',
    showLongDesc: '세부 사항 보기',
    hideLongDesc: '세부 사항 닫기',
    productNotFound: '제품을 찾을 수 없습니다',
    otherDeals: '다른 제품',
    productExpired: '죄송합니다: 선택한 제품은 더 이상 유효하지 않습니다',
    daysOfWeek: {
      monday: '월요일',
      tuesday: '화요일',
      wednesday: '수요일',
      thursday: '목요일',
      friday: '금요일',
      saturday: '토요일',
      sunday: '일요일',
    },
    close: '닫기',
    open: '열기',
    map: '지도',
    list: '목록',
    availability: {
      'in stock': '재고 있음',
      'limited stock': '마지막 상품입니다!',
      preorder: '도착 예정',
      'out of stock': '재고 없음',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: '더 보기',
      hide: '줄이기',
    },
    extendableDrawer: {
      expand: '확장',
      reduce: '축소',
      close: '닫다',
    },
    description: '설명',
  },
  ar_sa: {
    goToStore: 'اذهب إلى المتجر',
    nearestStoreAt: 'المتجر الأقرب إلى',
    searchPlaceholder: 'البحث في العروض',
    nearestStore: 'المتجر الأقرب',
    nearestStoreApproximated: 'المتجر - تنبيه، لم نتمكن من تحديد موقعك الدقيق، قد لا يكون المتجر قريبًا عنك.',
    nearestStores: 'المتاجر القريبة منك',
    emptyStateStores: 'لا توجد مواقع المتاجر في المنطقة',
    callcenterHours: 'ساعات الخدمة المستهلكية',
    emptyStateTitle: 'لا توجد نتائج',
    emptyStateBody: 'المنتج الذي تبحث عنه غير موجود في هذا العرض أو لم يتم كتابته بشكل صحيح. حاول مرة أخرى بحثًا جديدًا!',
    previousProduct: 'السابق',
    nextProduct: 'التالي',
    viewAllProducts: 'عرض جميع المنتجات',
    viewAll: 'عرض الكل',
    filters: 'الفلاتر',
    uncheckAll: 'مسح الكل',
    uncheckAllCategories: 'مسح جميع الفلاتر',
    saveFavourites: 'حفظ',
    removeFavourites: 'إزالة من قائمتي',
    ok: 'حسنًا',
    show: 'إظهار',
    pageOf: 'من',
    showLongDesc: 'عرض التفاصيل',
    hideLongDesc: 'إغلاق التفاصيل',
    productNotFound: 'لم يتم العثور على العرض',
    otherDeals: 'صفقات أخرى',
    productExpired: 'عذرًا: لقد انتهى العرض الذي حددته من الصلاحية',
    daysOfWeek: {
      monday: 'الإثنين',
      tuesday: 'الثلاثاء',
      wednesday: 'الأربعاء',
      thursday: 'الخميس',
      friday: 'الجمعة',
      saturday: 'السبت',
      sunday: 'الأحد',
    },
    close: 'اغلق',
    open: 'افتح',
    map: 'الخريطة',
    list: 'قائمة',
    availability: {
      'in stock': 'متوفر',
      'limited stock': 'آخر القطع!',
      preorder: 'الوصول',
      'out of stock': 'منتهي الأسهم',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'إظهار المزيد',
      hide: 'إظهار أقل',
    },
    extendableDrawer: {
      expand: 'توسيع',
      reduce: 'طي',
      close: 'إغلاق',
    },
    description: 'الوصف',
  },
  ja_jp: {
    goToStore: '店に行く',
    nearestStoreAt: '最も近い店は',
    searchPlaceholder: 'オファーを検索',
    nearestStore: '最も近い店',
    nearestStoreApproximated: '店 - 注意、あなたの正確な位置を検出できませんでした、店があなたの近くにないかもしれません。',
    nearestStores: 'あなたの近くの店',
    emptyStateStores: 'エリア内に店舗がありません',
    callcenterHours: 'カスタマーサービスの時間',
    emptyStateTitle: '結果なし',
    emptyStateBody: '探している製品がこのオファーに存在しないか、正しく入力されていません。新しい検索を試してください！',
    previousProduct: '前へ',
    nextProduct: '次へ',
    viewAllProducts: 'すべての製品を見る',
    viewAll: 'すべてを見る',
    filters: 'フィルター',
    uncheckAll: 'すべてクリア',
    uncheckAllCategories: 'すべてのフィルターをクリア',
    saveFavourites: '保存',
    removeFavourites: 'リストから削除',
    ok: 'ok',
    show: '表示',
    pageOf: 'の',
    showLongDesc: '詳細を見る',
    hideLongDesc: '詳細を閉じる',
    productNotFound: 'オファーが見つかりません',
    otherDeals: '他のディール',
    productExpired: 'すみません：選択したオファーはもはや有効ではありません',
    daysOfWeek: {
      monday: '月曜日',
      tuesday: '火曜日',
      wednesday: '水曜日',
      thursday: '木曜日',
      friday: '金曜日',
      saturday: '土曜日',
      sunday: '日曜日',
    },
    close: '閉じる',
    open: '開く',
    map: '地図',
    list: 'リスト',
    availability: {
      'in stock': '在庫あり',
      'limited stock': '最後の枚！',
      preorder: '予約受付中',
      'out of stock': '在庫切れ',
    },
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    productsExpand: {
      show: 'さらに見る',
      hide: '戻す',
    },
    extendableDrawer: {
      expand: '拡大',
      reduce: '縮小',
      close: '閉じる',
    },
    description: '説明',
  },
}

const dvc = {
  en_au: {
    ...commons.en_au,
    disclaimer: 'ShopFully is not responsible for the content shown.',
  },
  en_nz: {
    ...commons.en_nz,
    disclaimer: 'ShopFully is not responsible for the content shown.',
  },
  es_mx: {
    ...commons.es_mx,
    disclaimer: 'ShopFully no se responsabiliza del contenido publicado.',
  },
  it_it: {
    ...commons.it_it,
    disclaimer: 'DoveConviene non è responsabile del contenuto pubblicato.',
  },
  pt_br: {
    ...commons.pt_br,
    disclaimer: 'Shopfully não se responsabiliza pelo conteúdo publicado.',
  },
  fr_fr: {
    ...commons.fr_fr,
    disclaimer: 'Shopfully n\'est pas responsable du contenu affiché.',
  },
  bg_bg: {
    ...commons.bg_bg,
    disclaimer: 'Shopfully не носи отговорност за показаното съдържание.',
  },
  ro_ro: {
    ...commons.ro_ro,
    disclaimer: 'Shopfully nu este responsabil pentru conținutul afișat.',
  },
  cs_cz: {
    ...commons.cs_cz,
    disclaimer: 'Shopfully neodpovídá za zobrazený obsah.',
  },
}

const tiendeo = {
  en_au: {
    ...commons.en_au,
    disclaimer: 'Tiendeo is not responsible for the content shown.',
  },
  en_nz: {
    ...commons.en_nz,
    disclaimer: 'Tiendeo is not responsible for the content shown.',
  },
  es_mx: {
    ...commons.es_mx,
    disclaimer: 'Tiendeo no se responsabiliza del contenido publicado.',
  },
  es_es: {
    ...commons.es_mx,
    disclaimer: 'Tiendeo no se responsabiliza del contenido publicado.',
  },
  it_it: {
    ...commons.it_it,
    disclaimer: 'Tiendeo non è responsabile del contenuto pubblicato.',
  },
  pt_br: {
    ...commons.pt_br,
    disclaimer: 'Tiendeo não se responsabiliza pelo conteúdo publicado.',
  },
  bg_bg: {
    ...commons.bg_bg,
    disclaimer: 'Tiendeo не носи отговорност за показаното съдържание.',
  },
  ro_ro: {
    ...commons.ro_ro,
    disclaimer: 'Tiendeo nu este responsabil pentru conținutul afișat.',
  },
  cs_cz: {
    ...commons.cs_cz,
    disclaimer: 'Tiendeo neodpovídá za zobrazený obsah.',
  },
  fr_fr: {
    ...commons.fr_fr,
    disclaimer: 'Tiendeo n\'est pas responsable du contenu affiché.',
  },
  pt_pt: {
    ...commons.pt_br,
    disclaimer: 'Tiendeo não se responsabiliza pelo conteúdo publicado.',
  },
  es_cl: {
    ...commons.es_mx,
    disclaimer: 'Tiendeo no se responsabiliza del contenido publicado.',
  },
  es_ar: {
    ...commons.es_mx,
    disclaimer: 'Tiendeo no se responsabiliza del contenido publicado.',
  },
  es_co: {
    ...commons.es_mx,
    disclaimer: 'Tiendeo no se responsabiliza del contenido publicado.',
  },
  es_ec: {
    ...commons.es_mx,
    disclaimer: 'Tiendeo no se responsabiliza del contenido publicado.',
  },
  es_pe: {
    ...commons.es_mx,
    disclaimer: 'Tiendeo no se responsabiliza del contenido publicado.',
  },
  nl_be: {
    ...commons.fr_fr,
    disclaimer: 'Tiendeo n\'est pas responsable du contenu affiché.',
  },
  en_za: {
    ...commons.en_nz,
    disclaimer: 'Tiendeo is not responsible for the content shown.',
  },
  en_gb: {
    ...commons.en_nz,
    disclaimer: 'Tiendeo is not responsible for the content shown.',
  },
  en_ca: {
    ...commons.en_au,
    disclaimer: 'Tiendeo is not responsible for the content shown.',
  },
  tr_tr: {
    ...commons.tr_tr,
    disclaimer: 'Gösterilen içerikten Tiendeo sorumlu değildir',
  },
  de_de: {
    ...commons.de_de,
    disclaimer: 'Tiendeo ist für den dargestellten Inhalt nicht verantwortlich.',
  },
  de_at: {
    ...commons.de_de,
    disclaimer: 'Tiendeo ist für den dargestellten Inhalt nicht verantwortlich.',
  },
  nb_no: {
    ...commons.nb_no,
    disclaimer: 'Tiendeo er ikke ansvarlig for innholdet som vises.',
  },
  nl_nl: {
    ...commons.nl_nl,
    disclaimer: 'Tiendeo is niet verantwoordelijk voor de getoonde inhoud.',
  },
  da_dk: {
    ...commons.da_dk,
    disclaimer: 'Tiendeo er ikke ansvarlig for det viste indhold.',
  },
  sv_se: {
    ...commons.sv_se,
    disclaimer: 'Tiendeo ansvarar inte för innehållet som visas.',
  },
  de_ch: {
    ...commons.de_de,
    disclaimer: 'Tiendeo ist für den dargestellten Inhalt nicht verantwortlich.',
  },
  el_gr: {
    ...commons.el_gr,
    disclaimer: 'Η Tiendeo δεν ευθύνεται για το περιεχόμενο που εμφανίζεται.',
  },
  pl_pl: {
    ...commons.pl_pl,
    disclaimer: 'Tiendeo nie ponosi odpowiedzialności za prezentowane treści.',
  },
  sk_sk: {
    ...commons.sk_sk,
    disclaimer: 'Tiendeo nezodpovedá za zobrazený obsah.',
  },
  hu_hu: {
    ...commons.hu_hu,
    disclaimer: 'A Tiendeo nem vállal felelősséget a megjelenített tartalomért.',
  },
  fi_fi: {
    ...commons.fi_fi,
    disclaimer: 'Tiendeo ei ole vastuussa näytetystä sisällöstä.',
  },
  zh_sg: {
    ...commons.en_au,
    disclaimer: 'Tiendeo is not responsible for the content shown.',
  },
  ko_kr: {
    ...commons.ko_kr,
    disclaimer: 'Tiendeo는 표시된 콘텐츠에 대해 책임을 지지 않습니다.',
  },
  ar_sa: {
    ...commons.ar_sa,
    disclaimer: 'Tiendeo ليست مسؤولة عن المحتوى المعروض.',
  },
  ar_ae: {
    ...commons.ar_sa,
    disclaimer: 'Tiendeo ليست مسؤولة عن المحتوى المعروض.',
  },
  ar_ma: {
    ...commons.ar_sa,
    disclaimer: 'Tiendeo ليست مسؤولة عن المحتوى المعروض.',
  },
  ja_jp: {
    ...commons.ja_jp,
    disclaimer: 'Tiendeoは表示されるコンテンツについて責任を負いません。',
  },
}

const promoqui = {
  it_it: {
    ...commons.it_it,
    disclaimer: 'PromoQui non è responsabile del contenuto pubblicato.',
  },
}

const volantinofacile = {
  it_it: {
    ...commons.it_it,
    disclaimer: 'VolantinoFacile non è responsabile del contenuto pubblicato.',
  },
}

export default {
  default: dvc, dvc, tiendeo, promoqui, volantinofacile,
}
