import { put, select } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import { getCarouselProductPageRoute } from '../../../helpers/getAppRoutes'
import { track as trackEvent } from '../../actions/tracking'
import { selectCurrentCampaignNameId, selectCurrentCollectionIndex } from '../../selectors/ux'
import { selectRouterLocation } from '../../selectors/router'
import { selectProduct } from '../../selectors/product'

function* onProductSwipeRight(action) {
  const { params: { id: prevProductId } } = action.payload
  const product = yield select(selectProduct(prevProductId))
  const campaignNameId = yield select(selectCurrentCampaignNameId)
  const { search } = yield select(selectRouterLocation)
  const position = (yield select(selectCurrentCollectionIndex)) + 1 // from 1 to N

  if (prevProductId) {
    yield put(replace({
      pathname: getCarouselProductPageRoute(campaignNameId, prevProductId),
      search,
    }))

    yield put(trackEvent({
      event: 'swiperight',
      params: {
        id: product.id,
        title: product.title,
        position,
      },
    }))
  }
}

export default onProductSwipeRight
