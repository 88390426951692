/* eslint-disable array-callback-return */

/**
* Checks if a specific adSlot has already been defined.
* We assume that the domNodeId is unique.
* @param {string} domNodeId - the ID of the DOM element containing the ADV
 *
 * @returns {Object} advSlot
 */
function checkIfAdSlotExists(domNodeId) {
  try {
    const oldSlots = window.googletag.pubads().getSlots() || []
    return oldSlots.find(slot => slot?.getSlotElementId() === domNodeId)
  } catch (e) {
    return null
  }
}

/**
 * Defines a new slot
 * @param {string} name - the name of the advSlot
 * @param {Object} sizes - [ [0,768], 'fluid' ]
 * @param {string} domNodeId - the ID of the DOM node
 * @param {Object} targets - a key-value list of specific targets
 *
 * @returns {Object} advSlot
 */
function defineSlot(name, sizes, domNodeId, targets, theme) {
  const {
    advConf: {
      sizeMapping: {
        desktop: desktopSizes,
        mobile: mobileSizes,
        default: defaultSizes,
      },
    },
  } = theme

  const mapping = window.googletag.sizeMapping()
    .addSize(desktopSizes.viewport, desktopSizes.sizes)
    .addSize(mobileSizes.viewport, mobileSizes.sizes)
    .addSize(defaultSizes.viewport, defaultSizes.sizes)
    .build()

  const advSlot = window.googletag.defineSlot(name, sizes, domNodeId)
    .defineSizeMapping(mapping)

  if (targets && Object.keys(targets).length) {
    Object.keys(targets).map(targetKey => {
      advSlot.setTargeting(targetKey, targets[targetKey])
    })
  }

  advSlot.addService(window.googletag.pubads())

  return advSlot
}

/**
 * Initializes a single advSlot using window.googletag
 *
 * @param {Object} slot
 * @param {string} slot.domNodeId - the DOM node ID
 * @param {Object} slot.sizes - [[0,768],'fluid']
 * @param {string} slot.slotId - the AdSlot ID
 * @param {string} slot.slotName - the AdSlot name
 * @param {Object} slot.targets - The list of customized targets for a specific ADV
 */
export function addAdvSlot(slot, theme) {
  let advSlot
  const {
    slotId,
    slotName,
    sizes,
    domNodeId,
    targets,
  } = slot

  window.googletag = window.googletag || {}
  window.googletag.cmd = window.googletag.cmd || []

  window.googletag.cmd.push(() => {
    const slotAlreadyDefined = checkIfAdSlotExists(domNodeId)

    if (slotAlreadyDefined) {
      advSlot = slotAlreadyDefined
    } else {
      advSlot = defineSlot(`/${slotId}/${slotName}`, sizes, domNodeId, targets, theme)
    }

    // We disable the initial load to wait any other provider
    // to make their bids, than we make a single request with all
    // advs defined in page.
    window.googletag.pubads().collapseEmptyDivs()
    window.googletag.pubads().disableInitialLoad()
    window.googletag.enableServices()
  })

  window.googletag.cmd.push(() => {
    window.googletag.display(domNodeId)
    window.googletag.pubads().refresh([advSlot])
  })
}
