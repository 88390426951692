import qs from 'qs'
import { PLACEHOLDER_REGEX } from '../helpers/const'

/**
 * Parse the string of window.location.search and return an Object of each queryParams
 *
 * @param excludePlaceholder {boolean} if it is true excludes parameter values that
 * have a placeholderlike param={{value}} or param=[[value]]
 *
 * @returns object of parsed window.location.search
 */
const getQueryParams = (excludePlaceholder = true) => {
  const query = window.location.search
  let params = qs.parse(query, { ignoreQueryPrefix: true })

  if (excludePlaceholder) {
    params = Object.keys(params).reduce((acc, param) => {
      const value = params[param]
      const isPlaceholder = value.match(PLACEHOLDER_REGEX)
      return isPlaceholder ? acc : { ...acc, [param]: value }
    }, {})
  }

  return params
}

export default getQueryParams
