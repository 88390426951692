import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'

function* openAllStores() {
  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'oas',
    s2s_ec: 'c',
  })
}

export default openAllStores
