import { types as actionTypes } from '../actions/layout'
import initialState from '../initialState/layout'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LAYOUT: {
      const { layout } = action.payload
      return {
        ...state,
        ...layout,
      }
    }
    case actionTypes.SET_PRODUCT_SEARCH: {
      const { isSearchVisible } = action.payload
      return {
        ...state,
        isSearchVisible,
      }
    }
    default:
      return state
  }
}
