import { put } from 'redux-saga/effects'
import getGeolocation from '../../../../helpers/getGeolocation'
import getUserId from '../../../../helpers/getUserId'
import getSessionId from '../../../../helpers/getSessionId'
import getQueryParams from '../../../../utils/getQueryParams'
import { WEBVIEW_MODE } from '../../../../helpers/const'
import { setUserGeolocation, setUserId, setSessionId } from '../../../actions/user'
import { setIsWebView } from '../../../actions/ux'

// eslint-disable-next-line consistent-return
export function* userJob() {
  const geolocation = getGeolocation()
  const userId = getUserId()
  const sessionId = getSessionId()
  const queryParams = getQueryParams()
  const { t: viewType } = queryParams
  const isWebView = viewType ? viewType === WEBVIEW_MODE : false

  yield put(setUserGeolocation(geolocation))
  yield put(setIsWebView(isWebView))
  yield put(setUserId(userId))
  yield put(setSessionId(sessionId))
}
