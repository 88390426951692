import { put, select } from 'redux-saga/effects'

import { track as trackEvent } from '../../../actions/tracking'
import { selectProductCategory } from '../../../selectors/products'
import { selectCurrentCollectionIndex } from '../../../selectors/ux'

import {
  ORIGIN_EXTERNAL,
  ORIGIN_EXTERNAL_PLACEHOLDER,
} from '../../../../helpers/const'
import getQueryParams from '../../../../utils/getQueryParams'

const getOrigin = () => {
  const { utm_medium: utmMedium } = getQueryParams()

  switch (utmMedium) {
    case 'ss':
    case 'ssrc_v':
    case 'ssrc_rv':
      return ORIGIN_EXTERNAL.replace(ORIGIN_EXTERNAL_PLACEHOLDER, 'search')
    default:
      return ORIGIN_EXTERNAL.replace(ORIGIN_EXTERNAL_PLACEHOLDER, 'external')
  }
}

export function* trackLoadPageProductsItem(product) {
  const collectionIndex = yield select(selectCurrentCollectionIndex)
  const category = yield select(selectProductCategory(collectionIndex))

  yield put(trackEvent({
    event: 'openDetail',
    params: {
      id: product.id,
      title: product.title,
      position: collectionIndex + 1,
      category,
      origin: getOrigin(),
    },
  }))
}
