import { select, put } from 'redux-saga/effects'

import { setProductInfoDrawerMode } from '../../actions/ux'
import { track as trackEvent } from '../../actions/tracking'
import { selectProduct } from '../../selectors/product'
import { selectCurrentCollectionIndex } from '../../selectors/ux'

import {
  EXPANDABLE_DRAWER_MODE_EXPANDED,
  EXPANDABLE_DRAWER_ACTION_SWIPE,
} from '../../../helpers/const'

function getEventName(action) {
  return action === EXPANDABLE_DRAWER_ACTION_SWIPE ? 'swipeProductDrawer' : 'toggleProductDrawer'
}

function getParamTo(mode, action) {
  if (mode === EXPANDABLE_DRAWER_MODE_EXPANDED) {
    return action === EXPANDABLE_DRAWER_ACTION_SWIPE ? 'up' : 'expand'
  }
  return action === EXPANDABLE_DRAWER_ACTION_SWIPE ? 'down' : 'reduce'
}

function* onProductInfoDrawerSetMode(action) {
  const { params: { mode, action: drawerAction, productId } } = action.payload
  const product = yield select(selectProduct(productId))
  const position = (yield select(selectCurrentCollectionIndex)) + 1

  yield put(setProductInfoDrawerMode(mode))
  yield put(trackEvent({
    event: getEventName(drawerAction),
    params: {
      id: product.id,
      title: product.title,
      position,
      to: getParamTo(mode, drawerAction),
    },
  }))
}

export default onProductInfoDrawerSetMode
