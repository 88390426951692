import { types as actionTypes } from '../actions/tracking'

import initialState from '../initialState/tracking'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_TRACKING_FLYER_ID: {
      const { flyerId } = action.payload
      return {
        ...state,
        flyerId,
      }
    }
    case actionTypes.SET_TRACKING_RETAILER_ID: {
      const { retailerId } = action.payload
      return {
        ...state,
        retailerId,
      }
    }
    case actionTypes.SET_TRACKING_CATEGORY_SLUG: {
      const { categorySlug } = action.payload
      return {
        ...state,
        categorySlug,
      }
    }
    case actionTypes.SET_TRACKING_RETAILER_SLUG: {
      const { retailerSlug } = action.payload
      return {
        ...state,
        retailerSlug,
      }
    }
    case actionTypes.SET_PRODUCT_DETAIL_TRACKING: {
      const { productId, status } = action.payload

      return {
        ...state,
        products: {
          ...state.products,
          [productId]: {
            ...state.products[productId],
            detail: status,
          },
        },
      }
    }
    case actionTypes.SET_COOKIE_PREFERENCES: {
      const { isPreferenceExpressed, preferences } = action.payload

      return {
        ...state,
        cookies: {
          ...state.cookies,
          isPreferenceExpressed,
          preferences: [...preferences],
        },
      }
    }
    case actionTypes.SET_PIXELS_INITIALIZED: {
      const { arePixelsInitialized } = action.payload
      return {
        ...state,
        cookies: {
          ...state.cookies,
          arePixelsInitialized,
        },
      }
    }
    case actionTypes.SET_CAMPAIGN_NAME: {
      const { campaignName } = action.payload
      return {
        ...state,
        campaignName,
      }
    }
    case actionTypes.SET_TRACKING_IMPRESSION_TAG_URL: {
      const { url: impressionTagURL } = action.payload
      return {
        ...state,
        customer: {
          ...state.customer,
          impressionTagURL,
        },
      }
    }
    case actionTypes.SET_GCLID_EVENT_SENT: {
      const { preference } = action.payload
      return {
        ...state,
        gclidEventSent: {
          ...state.gclidEventSent,
          [preference]: true,
        },
      }
    }
    default:
      return state
  }
}
