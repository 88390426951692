import { call } from 'redux-saga/effects'

import { trackSF, trackGA } from '../providers'
import { NOT_AVAILABLE } from '../../../../helpers/const'

function* viewProductInList(action) {
  const { params } = action.payload
  const {
    id, title, position, category,
  } = params

  const productTitle = title || NOT_AVAILABLE
  const eventLabel = `Position${position}-${id}-${productTitle}`

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'vpil',
    s2s_ec: 'i',
    s2s_el: eventLabel,
    s2s_pos: position,
    s2s_iid: id,
    s2s_pc: category,
    s2s_title: productTitle,
  })

  // Google Analytics
  yield call(trackGA, {
    hitType: 'event',
    eventCategory: 'Impression',
    eventAction: 'View Product Grid',
    eventLabel,
    eventValue: id,
  })
}

export default viewProductInList
