import { selectConst } from './i18n'
import {
  EXPANDABLE_DRAWER_MODE_EXPANDED,
} from '../../helpers/const'

export const selectIsWebView = state => state.ux.isWebView

export const selectIsCampaignExpired = state => state.ux.isCampaignExpired

export const selectIsCampaignReady = state => state.ux.isCampaignReady

export const selectCurrentCategories = state => state.ux.currentCategories

export const selectCurrentCampaignNameId = state => state.ux.currentCampaignNameId

export const selectCurrentProductId = state => state.ux.currentProductId

export const selectCurrentCollectionIndex = state => state.ux.currentCollectionIndex

export const selectSearch = state => state.ux.search

export const selectHasStores = state => state.ux.hasStores

export const selectCountry = state => state.ux.country

export const selectWebsiteURL = state => {
  const isWebView = selectIsWebView(state)
  const protocolPath = isWebView ? 'mobile.protocol' : 'web.protocol'
  const protocol = selectConst(protocolPath)(state)
  const domain = selectConst('domain')(state)
  const disableWebsiteTracking = selectConst('disableWebsiteTracking')(state)

  return `${protocol}${domain}${!disableWebsiteTracking ? '?utm_source=S2S' : ''}`
}

export const selectHasCategories = state => state.ux.hasCategories

export const selectHasTitles = state => state.ux.hasTitles

export const selectExpandedCategories = state => state.ux.expandedCategories

export const selectIsStoresListDrawerOpen = state => state.ux.isStoresListDrawerOpen

export const selectProductInfoDrawerOpen = state => state.ux.productInfoDrawer?.isOpen

export const selectProductInfoMode = state => state.ux.productInfoDrawer?.mode

export const selectProductInfoModeIsExpanded = state => {
  const isExpanded = state.ux.productInfoDrawer?.mode === EXPANDABLE_DRAWER_MODE_EXPANDED
  return isExpanded
}

export const selectCurrentTheme = state => state.ux.theme

export const selectContext = state => state.ux.context
