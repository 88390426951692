import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a categoryId, fetch category info
 * @param {Object} params
 * @param {string} params.categoryId
 */
export const fetchCategory = ({ categoryId }) => {
  const options = {
    resource: `categories/${categoryId}`,
    paginate: false,
    dataOnly: true,
    params: {
      fields: 'slug',
    },
  }

  return fetchRestData(options)
}
