import { put } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'
import { setStoresListIsOpen } from '../../actions/ux'

function* onStoresCtaClick(action) {
  const { params } = action.payload
  const { hasStoresList } = params

  yield put(trackEvent({
    event: 'openAllStores',
    params: {},
  }))

  if (hasStoresList) {
    yield put(setStoresListIsOpen(true))
  }
}

export default onStoresCtaClick
