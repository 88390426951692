import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a campaignId, fetch s2s campaign info
 * @param {Object} params
 * @param {string} params.campaignId
 */
export const fetchS2sCampaign = ({ campaignId }) => {
  const options = {
    resource: `s2s_campaigns/${campaignId}`,
    dataOnly: true,
    params: {
      fields: 'flyer_id,title,type,settings',
    },
  }

  return fetchRestData(options)
}
