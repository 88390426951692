import { put, call } from 'redux-saga/effects'

import { setRetailer } from '../../../../actions/retailers'
import { fetchRetailer } from '../../../../../api/retailers/fetchRetailer'

function* getRetailerSlug(action) {
  const { retailerId, country } = action.payload
  try {
    const retailer = (yield call(fetchRetailer, { country, retailerId })) || {}
    yield put(setRetailer(retailerId, { id: retailerId, ...retailer }))
  } catch (error) { console.error(error) } // eslint-disable-line no-console
}

export default getRetailerSlug
