import { fork, spawn, take } from 'redux-saga/effects'

import { types as uxActionTypes } from '../actions/ux'

const takeEveryUxEvent = (saga, options = {}, ...args) => fork(
  function* handler() {
    while (true) {
      const action = yield take(uxActionTypes.EVENT)
      const { payload = {} } = action

      let handle = true
      Object
        .keys(options)
        .forEach(key => {
          if (payload[key] !== options[key]) handle = false
        })

      if (handle) yield spawn(saga, ...args.concat(action))
    }
  },
)

export default takeEveryUxEvent
