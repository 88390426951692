import { isWithinInterval } from 'date-fns'
import productDateAvailableParser from './productDateAvailableParser'

export default (sDate, currentDate, eDate) => {
  if (!sDate && !eDate) return true

  const { start, end } = productDateAvailableParser(sDate, eDate)
  const isDateInRage = isWithinInterval(currentDate, { start, end })

  return isDateInRage
}
