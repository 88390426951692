import createActionTypes from './createActionTypes'

export const namespace = 'CATEGORIZED_PRODUCTS'

export const types = createActionTypes(namespace, [
  'SET_CATEGORIZED_PRODUCTS',
])

export const setCategorizedProducts = products => ({
  type: types.SET_CATEGORIZED_PRODUCTS,
  payload: { products },
})
