import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ReactComponent as ErrorIcon } from '@doveconviene/sf-ui/icons/error.svg'

const StyledRoot = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const StyledIcon = styled.div(({ fill }) => ({
  width: 60,
  height: 60,
  fill,
  margin: '0 auto',
}))

const StyledMessage = styled.div({
  marginTop: 16,
  fontFamily: 'Nunito, Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif',
})

const ErrorMessage = ({ message, color }) => (
  <StyledRoot>
    <StyledIcon fill={color}>
      <ErrorIcon />
    </StyledIcon>
    <StyledMessage>
      {message}
    </StyledMessage>
  </StyledRoot>
)

ErrorMessage.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string.isRequired,
}

ErrorMessage.defaultProps = {
  message: '',
}

export default ErrorMessage
