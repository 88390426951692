import createFetchRestData from '@doveconviene/sf-web-js-sdk/createFetchRestData'

import { urlOptions, fetchParams } from '../api/config'

const fetchFn = async (...args) => (await fetch(...args)).json()

const fetchRestData = createFetchRestData(
  fetchFn,
  urlOptions,
  fetchParams,
)

export default fetchRestData
