import { OT_MANDATORY_COOKIE_PREFERENCE } from '../../helpers/const'
import getQueryParams from '../../utils/getQueryParams'

const { property_id: enPropertyId } = getQueryParams()

export default {
  campaignName: undefined,
  propertyID: undefined,
  countryPropertyID: undefined,
  retailerId: undefined,
  fbPixelID: undefined,
  flyerID: undefined,
  retailerSlug: undefined,
  categorySlug: undefined,
  products: {},
  customer: {},
  cookies: {
    arePixelsInitialized: false,
    isPreferenceExpressed: false,
    preferences: [OT_MANDATORY_COOKIE_PREFERENCE],
  },
  enPropertyId: enPropertyId || undefined, // Extended Network Property Id
  gclidEventSent: {
    granted: false,
    denied: false,
  },
}
