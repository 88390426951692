import { call, select, put } from 'redux-saga/effects'

import {
  trackSF, trackFBQ, trackGA, trackGAW, trackGAWR,
} from '../providers'
import { selectProductDetailTracking } from '../../../selectors/tracking'
import { setProductDetailTracking } from '../../../actions/tracking'
import { NOT_AVAILABLE } from '../../../../helpers/const'

function* viewProductCarousel(action) {
  const { params } = action.payload
  const { product, position } = params

  const isProductDetailTracked = yield select(selectProductDetailTracking(product.id))

  const productTitle = product.title || NOT_AVAILABLE
  const eventLabel = `Position${position}-${product.id}-${productTitle}`

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'vpc',
    s2s_ec: 'i',
    s2s_el: eventLabel,
    s2s_pos: position,
    s2s_iid: product.id,
    s2s_title: productTitle,
  })

  // Google Analytics
  yield call(trackGA, {
    hitType: 'event',
    eventCategory: 'Impression',
    eventAction: 'View Product Detail',
    eventLabel,
    eventValue: product.id,
  })

  // FB and GAW GAWR unique session impression
  if (!isProductDetailTracked) {
    yield put(setProductDetailTracking(product.id, true))
    const productCategories = product?.settings?.category ? product.settings.category.toString() : ''

    const trackingPayload = {
      s2s_ea: 'S2S View Product',
      s2s_ec: 'i',
      s2s_pos: position,
      s2s_iid: product.id,
      s2s_title: productTitle,
      s2s_categories: productCategories,
    }

    // Facebook
    yield call(trackFBQ, 'trackCustom', 'S2S View Product', { ...trackingPayload })

    // Google AdWords
    yield call(trackGAW, {
      google_custom_params: { ...trackingPayload },
    })

    // Google AdWords - Remarketing
    yield call(trackGAWR, {
      google_custom_params: { ...trackingPayload },
    })
  }
}

export default viewProductCarousel
