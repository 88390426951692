import distanceBetween from '@doveconviene/sf-web-js-sdk/distanceBetween'

export const manipulateStores = (items = [], userGeolocation = false) => {
  if (!items || !items.length) return { stores: {}, nearestStoreId: null }

  const stores = {}
  for (const store of items) {
    stores[store.id] = {
      ...store,
      distance: distanceBetween(userGeolocation, { lat: store.lat, lng: store.lng }),
    }
  }

  const orderedStores = Object.values(stores).sort((a, b) => (a.distance - b.distance))
  const nearestStoreId = orderedStores[0].id

  return { stores, nearestStoreId }
}
