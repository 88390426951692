import { all, fork } from 'redux-saga/effects'

import watchInitCampaign from './watchInitCampaign'

function* initSagas() {
  yield all([
    fork(watchInitCampaign),
  ])
}

export default initSagas
