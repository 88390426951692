import React from 'react'
import ReactDOM from 'react-dom'

import { isBrowserUnsupported, getPolyFillUrl, getBrowserDisabledFeatures } from './utils/isBrowserSupported'
import { loadScript } from './utils/loadScript'
import { errorTrackingInit } from './helpers/errorTracking'
import { initDebugFeatures } from './helpers/debugFeatures'
import setImageFallback from './utils/setImageFallback'
import noMediaImage from './images/svg/noMedia.svg'
import getQueryParams from './utils/getQueryParams'
import store from './flux'
import { initCampaign } from './flux/actions/init'

import App from './app'

const { debug } = getQueryParams()
if (debug) initDebugFeatures()

const startApp = () => {
  errorTrackingInit()
  setImageFallback(noMediaImage)

  store.dispatch(initCampaign())

  ReactDOM.render(
    React.createElement(App, { store }),
    document.getElementById('root'),
  )
}

if (isBrowserUnsupported()) {
  loadScript(getPolyFillUrl(), () => getBrowserDisabledFeatures(startApp))
} else {
  startApp()
}
