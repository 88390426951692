import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'

function* viewAllSimilarProduct(action) {
  const { params } = action.payload
  const { category } = params

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'vasp',
    s2s_ec: 'c',
    s2s_cc: category,
  })
}

export default viewAllSimilarProduct
