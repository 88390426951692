export const selectBrandColorBg = state => state.layout.colorBrandBg

export const selectBrandColor = state => state.layout.colorBtnBg

export const selectBrandColorText = state => state.layout.colorBtnText

export const selectProductBannerMobile = state => state.layout.noStoresMobileImageURL

export const selectProductBannerDesktop = state => state.layout.noStoresDesktopImageURL

export const selectCtaList = state => state.layout.ctaList

export const selectNoStoresCtaPhone = state => state.layout.noStoresCTAPhone

export const selectHasPhoneFromStore = state => state.layout.phoneFromStore

export const selectHeroSection = state => state.layout.heroSection

export const selectHideProductsTitles = state => state.layout.hideProductsTitles

export const selectViewMode = state => state.layout.viewMode

export const selectIsSearchVisible = state => state.layout.isSearchVisible
