import { select, put } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'
import { selectCurrentCollectionIndex } from '../../selectors/ux'

function* onProducInfoCtaClick(action) {
  const { params: { productId, context, position: gridPosition } } = action.payload

  const product = yield select(selectProduct(productId))
  const categories = product?.settings?.category ? product.settings.category.toString() : ''
  const position = gridPosition || ((yield select(selectCurrentCollectionIndex)) + 1) // from 1 to N

  yield put(trackEvent({
    event: 'productInfo',
    params: {
      title: product.title,
      position,
      categories,
      id: product.id,
      origin: context,
    },
  }))
}

export default onProducInfoCtaClick
