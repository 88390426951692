import { call, select } from 'redux-saga/effects'

import { trackSF } from '../providers'
import { selectHasStores } from '../../../selectors/ux'
import { selectUserNearestStoreId } from '../../../selectors/user'

function* callEvent(action) {
  const { params } = action.payload
  const { to } = params

  const hasStores = yield select(selectHasStores)
  const SfValue = hasStores ? yield select(selectUserNearestStoreId) : ''

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'c',
    s2s_ec: 'c',
    s2s_to: to,
    s2s_ev: SfValue,
  })
}

export default callEvent
