import {
  takeEvery, put, select, spawn,
} from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'

import { getProductDataFromUrl } from '../../../helpers/getInfoFromUrl'
import { selectProduct } from '../../selectors/product'
import { selectProducts, selectCollectionProductsList } from '../../selectors/products'
import { selectCurrentProductId, selectCurrentCollectionIndex, selectProductInfoDrawerOpen } from '../../selectors/ux'
import { setCurrentProduct, uxEvent, setProductInfoDrawerOpen } from '../../actions/ux'
import { selectViewMode } from '../../selectors/layout'
import { track as trackEvent } from '../../actions/tracking'

import {
  ORIGIN_PRODUCT,
  CONTEXT_BROWSER_BACK,
  EXPANDABLE_DRAWER_MODE_EXPANDED,
  COLLECTION_VIEW_MODE,
} from '../../../helpers/const'

function* onLandingPage({ prevProductId, from }) {
  const isProductDrawerOpen = yield select(selectProductInfoDrawerOpen)

  if (!prevProductId || !isProductDrawerOpen) return

  yield put(uxEvent({
    name: 'toggle',
    target: 'ProductInfoDrawer/Close',
    params: {
      productId: prevProductId,
      to: from,
    },
  }))
}

function* onProductPage(productId) {
  const products = yield select(selectProducts)
  const product = yield select(selectProduct(productId))
  const viewMode = yield select(selectViewMode)
  if (!products || !product) return

  const position = (yield select(selectCurrentCollectionIndex)) + 1 // from 1 to N
  const total = (yield select(selectCollectionProductsList) || []).length

  const isLastProduct = (position === total)
  if (isLastProduct) {
    yield put(trackEvent({
      event: 'viewLastProduct',
      params: {
        origin: ORIGIN_PRODUCT,
        lastProductId: productId,
      },
    }))
  }

  const isProductDrawerOpen = yield select(selectProductInfoDrawerOpen)
  const allowDrawerOpen = viewMode === COLLECTION_VIEW_MODE && !isProductDrawerOpen
  if (allowDrawerOpen) {
    yield put(setProductInfoDrawerOpen(EXPANDABLE_DRAWER_MODE_EXPANDED))
  }

  yield put(trackEvent({
    event: 'viewProductCarousel',
    params: {
      product,
      position,
    },
  }))
}

function* onLocationChange(action) {
  const { location: { state: locationState } } = action.payload

  const { productId } = getProductDataFromUrl()

  const prevProductId = yield select(selectCurrentProductId)

  yield put(setCurrentProduct(productId))
  if (productId) {
    yield spawn(onProductPage, productId)
  } else {
    const from = locationState && locationState?.from ? locationState.from : CONTEXT_BROWSER_BACK
    yield spawn(onLandingPage, { prevProductId, from })
  }
}

function* watchLocationChange() {
  yield takeEvery(LOCATION_CHANGE, onLocationChange)
}

export default watchLocationChange
