import getContext from './context/getContext'
import { getSecret } from './i18n/secret'

const setDynamicDocumentHead = ({ campaign, country }) => {
  /* HTML LANG & TITLE attributes */
  const lang = getSecret('LANG')({ country })
  document.title = `${campaign.title}`
  document.documentElement.setAttribute('lang', lang)
  document.documentElement.setAttribute('xml:lang', lang)
  /* end HTML LANG & TITLE attributes */

  /* set FAVICON dynamically based on context */
  const context = getContext()
  let favicon = document.querySelector("link[rel~='icon']")
  if (!favicon) {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    document.head.appendChild(favicon)
  }
  favicon.href = `${process.env.PUBLIC_URL}/img/brand/${context}/favicon.ico`
  /* end set FAVICON dynamically based on context */
}

export default setDynamicDocumentHead
