import { select, put } from 'redux-saga/effects'

import { getCollectionHomePageRoute } from '../../../helpers/getAppRoutes'
import { track as trackEvent } from '../../actions/tracking'
import { setProductInfoDrawerClose } from '../../actions/ux'
import { selectProduct } from '../../selectors/product'
import { selectCurrentCollectionIndex, selectCurrentCampaignNameId } from '../../selectors/ux'
import { selectRouterLocation } from '../../selectors/router'
import { history } from '../..'

function* onProductInfoDrawerClose(action) {
  const { params: { productId, to } } = action.payload
  const { search } = yield select(selectRouterLocation)
  const campaignNameId = yield select(selectCurrentCampaignNameId)
  const product = yield select(selectProduct(productId))
  const position = (yield select(selectCurrentCollectionIndex)) + 1

  yield put(setProductInfoDrawerClose())

  history.replace({
    pathname: getCollectionHomePageRoute(campaignNameId),
    search,
  })

  yield put(trackEvent({
    event: 'closeDetail',
    params: {
      id: product.id,
      title: product.title,
      position,
      to,
    },
  }))
}

export default onProductInfoDrawerClose
