import { put, select, call } from 'redux-saga/effects'

import {
  COLLECTION_VIEW_MODE,
  CAROUSEL_VIEW_MODE,
  EXPANDABLE_DRAWER_MODE_EXPANDED,
} from '../../../../helpers/const'
import { getCarouselProductPageRoute, getCollectionHomePageRoute, getCollectionProductPageRoute } from '../../../../helpers/getAppRoutes'
import { history } from '../../..'
import { selectCategorizedProducts } from '../../../selectors/products'
import { selectProduct } from '../../../selectors/product'
import { setCurrentCollectionIndex, setProductInfoDrawerOpen } from '../../../actions/ux'
import { trackLoadPageProductsItem } from './trackLoadPageProductsItem'

export function* validateRoute({
  campaignNameId,
  productId,
  viewMode,
}) {
  const { search } = window.location

  if (viewMode === CAROUSEL_VIEW_MODE) {
    if (productId) {
      history.replace({
        pathname: getCarouselProductPageRoute(campaignNameId, productId),
        search,
      })
    } else {
      // Select the first product of categorizedProducts:
      // [0] -> first categoryzedArray; [1] -> select array of products; [0] -> select first product
      yield put(setCurrentCollectionIndex(0))
      const categorizedProducts = yield select(selectCategorizedProducts)
      const { id: firstProductId } = categorizedProducts[0][1][0]
      history.replace({
        pathname: getCarouselProductPageRoute(campaignNameId, firstProductId),
        search,
      })
    }
  }

  if (viewMode === COLLECTION_VIEW_MODE) {
    if (productId) {
      const product = yield select(selectProduct(productId))
      yield call(trackLoadPageProductsItem, product)
      history.replace({
        pathname: getCollectionProductPageRoute(campaignNameId, productId),
        search,
      })
      yield put(setProductInfoDrawerOpen(EXPANDABLE_DRAWER_MODE_EXPANDED))
    } else {
      history.replace({
        pathname: getCollectionHomePageRoute(campaignNameId),
        search,
      })
    }
  }
}
