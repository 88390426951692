import createActionTypes from './createActionTypes'

export const namespace = 'API'

export const types = createActionTypes(namespace, [
  'GET_STORES',
  'GET_STORE_RETAILER',
  'GET_STORE_HOURS',
  'GET_RETAILER_ASSETS',
  'GET_RETAILER_SLUG',
])

export const getStores = (flyerId, lat, lng) => ({
  type: types.GET_STORES,
  payload: { flyerId, lat, lng },
})

export const getStoreHours = storeId => ({
  type: types.GET_STORE_HOURS,
  payload: { storeId },
})

export const getStoreRetailer = storeId => ({
  type: types.GET_STORE_RETAILER,
  payload: { storeId },
})

export const getRetailerAssets = retailerId => ({
  type: types.GET_RETAILER_ASSETS,
  payload: { retailerId },
})

export const getRetailerSlug = retailerId => ({
  type: types.GET_RETAILER_SLUG,
  payload: { retailerId },
})
