export const selectCustomerImpressionTagURL = state => state.tracking.customer.impressionTagURL

export const selectProductsTracking = state => state.tracking.products

export const selectProductTracking = productId => state => {
  const productsTracking = selectProductsTracking(state)
  return productsTracking[productId]
}

export const selectProductDetailTracking = productId => state => {
  const productTracking = selectProductTracking(productId)(state)
  return productTracking && productTracking.detail
}

export const selectIsPreferenceExpressed = state => state.tracking.cookies.isPreferenceExpressed

export const selectArePixelsInitialized = state => state.tracking.cookies.arePixelsInitialized

export const selectUserCookiesPreferenceActive = type => state => (
  state.tracking.cookies.preferences.indexOf(type) >= 0
)

export const selectENPropertyId = state => state.tracking.enPropertyId

export const selectGClidEventSent = state => state.tracking.gclidEventSent
