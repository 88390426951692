import { types as actionTypes } from '../actions/user'
import initialState from '../initialState/user'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ID: {
      const { id } = action.payload
      return { ...state, id }
    }
    case actionTypes.SET_SID: {
      const { sid } = action.payload
      return { ...state, sid }
    }
    case actionTypes.SET_GEOLOCATION: {
      const { geolocation } = action.payload
      return {
        ...state,
        geolocation,
      }
    }
    case actionTypes.SET_USER_NEAREST_STORE_ID: {
      const { storeId } = action.payload

      return {
        ...state,
        storeId,
      }
    }
    default:
      return state
  }
}
