import { put, call } from 'redux-saga/effects'

import { track as trackEvent, setPixelsInitialized } from '../../actions/tracking'
import {
  initFacebookPixel,
  initGoogleAnalytics,
  initGoogleAnalyticsCountry,
  initGoogleAdwords,
  initGoogleAdwordsRemarketing,
  initComscoreScript,
} from './providers/index'

export function* initProviders() {
  yield call(initGoogleAnalytics)
  yield call(initGoogleAnalyticsCountry)
  yield call(initComscoreScript)

  yield put(trackEvent({ event: 'offerOpen' }))
}

export function* initProvidersWithCookie() {
  yield call(initFacebookPixel)
  yield call(initGoogleAdwords)
  yield call(initGoogleAdwordsRemarketing)

  yield put(trackEvent({ event: 'offerOpenWithCookie' }))
  yield put(setPixelsInitialized(true))
}
