import {
  call, all, put, select,
} from 'redux-saga/effects'

import { fetchS2sCampaign } from '../../../../api/campaign/fetchS2sCampaign'
import { fetchS2sCampaignAssets } from '../../../../api/campaign/fetchS2sCampaignAssets'

import manipulateI18n from '../../../manipulators/2.0/i18n'

import { ERROR_TYPE_ERROR } from '../../../../helpers/const'
import formatHours from '../../../../helpers/formatHours'

import { setLayout } from '../../../actions/layout'
import { setCurrentCampaign, setHasStores } from '../../../actions/ux'
import { setTrackingFlyerId, setCampaignName } from '../../../actions/tracking'
import { setDefaultStoreHours } from '../../../actions/defaultStoreHours'
import { setCallcenterHours } from '../../../actions/callcenterHours'
import { selectCurrentTheme } from '../../../selectors/ux'

import { setI18n } from '../../../actions/i18n'

import { renderSplash } from '../../../../splash/index'
import setDynamicDocumentHead from '../../../../utils/setDynamicDocumentHead'

// eslint-disable-next-line consistent-return
export function* campaignJob({
  country,
  campaignNameId,
  campaignId,
}) {
  const theme = yield select(selectCurrentTheme)

  try {
    const [campaign, assets] = yield all([
      call(fetchS2sCampaign, { country, campaignId }) || {},
      call(fetchS2sCampaignAssets, { country, campaignId }) || {},
    ])

    if (!campaign) {
      return renderSplash({
        type: ERROR_TYPE_ERROR,
        message: 'Campaign Error',
        theme,
      })
    }

    const categoriesList = campaign.settings.categories_list
    const flyerId = campaign.flyer_id
    const viewMode = campaign.type
    const campaignAssets = assets.reduce((acc, val) => ({
      ...acc,
      [val.name]: {
        ...val,
      },
    }), {})

    /* HTML LANG, TITLE and FAVICON */
    setDynamicDocumentHead({ campaign, country })
    /* end HTML LANG, TITLE and FAVICON */

    yield put(setCurrentCampaign(campaignNameId))
    yield put(setCampaignName(campaign.title))
    yield put(setTrackingFlyerId(flyerId))

    /*  CAMPAIGN LAYOUT */
    const layout = {
      noStoresDesktopImageURL: campaignAssets.no_stores_desktop?.url,
      noStoresMobileImageURL: campaignAssets.no_stores_mobile?.url,
      noStoresURL: campaign.settings.cta_no_stores?.url,
      noStoresCTALabel: campaign.settings.cta_no_stores?.label,
      noStoresCTAPhone: campaign.settings.cta_no_stores?.phone,
      phoneFromStore: campaign.settings.phoneFromStore,
      colorBrandBg: campaign.settings.layout.colors.brand_bg,
      colorBtnBg: campaign.settings.layout.colors.btn_bg,
      colorCoverBg: campaign.settings.layout.colors.cover_bg,
      colorHighlight: campaign.settings.layout.colors.highlight_text,
      colorBtnText: campaign.settings.layout.colors.btn_text,
      colorCoverText: campaign.settings.layout.colors.cover_text,
      hideProductsTitles: campaign.settings.hide_products_titles,
      logoURL: campaignAssets.logo?.url,
      defaultPinURL: campaignAssets.pin?.url,
      ctaList: campaign.settings.ctas || [],
      hasStores: campaign.settings.has_stores !== '0',
      heroSection: {
        url: campaign.settings.cta_hero_image?.url,
        custom: !!(
          campaignAssets.heroImage_mobile?.url
          || campaignAssets.heroImage_desktop?.url
          || campaignAssets.heroImage_tablet?.url
        ),
        desktop: campaignAssets.heroImage_desktop?.url,
        tablet: campaignAssets.heroImage_tablet?.url,
        mobile: campaignAssets.heroImage_mobile?.url,
      },
      viewMode,
      categoriesList,
    }
    yield put(setLayout(layout))
    /* 18n CAMPAIGN LABELS */
    const ctas = (campaign.settings.ctas
      ? Object.values(campaign.settings.ctas).reduce((acc, val) => ({
        ...acc, [val.type]: { ...val },
      }), {})
      : {})

    const labels = {
      storesList: campaign.settings.labels.stores_list,
      showNearbyStores: campaign.settings.labels.show_nearby_stores,
      nearbyStores: campaign.settings.labels.nearby_stores,
      siteName: campaign.title,
      ctaCall: ctas.call?.label,
      ctaCallNum: ctas.call?.phone,
      ctaProduct: ctas.product?.label,
    }
    const i18n = manipulateI18n({ labels }, country)
    yield put(setI18n(i18n))

    /* DEFAULT STORE HOURS */
    const { daysOfWeek } = i18n.labels
    const defaultStoreHours = formatHours(daysOfWeek, campaign.settings.default_store_hours)
    yield put(setDefaultStoreHours(defaultStoreHours))
    if (campaign.settings.call_hours) {
      yield put(setCallcenterHours(formatHours(daysOfWeek, campaign.settings.call_hours)))
    }
    const { hasStores } = layout
    yield put(setHasStores(hasStores))
    const campaignCategories = campaign.settings.categories_list

    return {
      flyerId,
      hasStores,
      campaignCategories,
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    return renderSplash({
      type: ERROR_TYPE_ERROR,
      message: 'Campaign Error',
      theme,
    })
  }
}
