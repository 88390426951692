import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a retailerId, fetch retailer info
 * @param {Object} params
 * @param {string} params.retailerId
 */
export const fetchRetailer = ({ retailerId }) => {
  const options = {
    resource: `retailers/${retailerId}`,
    paginate: false,
    dataOnly: true,
    params: {
      fields: 'slug,name,category_id,settings',
    },
  }

  return fetchRestData(options)
}
