import { put, select } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'
import { ORIGIN_LANDING, CONTEXT_HEADER } from '../../../helpers/const'
import { selectUserNearestStore } from '../../selectors/stores'

function* onNearestStoreMapClick() {
  const store = yield select(selectUserNearestStore)

  if (store) {
    yield put(trackEvent({
      event: 'navigateTo',
      params: {
        id: store.id,
        address: store.address,
        zip: store.zip,
        city: store.city,
        to: `${ORIGIN_LANDING}-${CONTEXT_HEADER}`,
      },
    }))
  }
}

export default onNearestStoreMapClick
