import { all, fork } from 'redux-saga/effects'

import { watchAdManager } from './watchAdManager'

function* initSagas() {
  yield all([
    fork(watchAdManager),
  ])
}

export default initSagas
