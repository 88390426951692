import { types as actionTypes } from '../actions/adv'
import initialState from '../initialState/adv'
import {
  manipulateCategoryAdvToInitialize,
  removeFromCategoryAdvList,
} from '../manipulators/2.0/categoryAdvToInitialize'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_AD_MANAGER_INITIALIZED: {
      const { isAdManagerInitialized } = action.payload
      return {
        ...state,
        isAdManagerInitialized,
      }
    }
    case actionTypes.SET_CATEGORY_ADUNIT_INITIALIZED: {
      const { adSlot } = action.payload
      return {
        ...state,
        adUnits: {
          ...(state.adUnits || {}),
          toBeInitialized: manipulateCategoryAdvToInitialize(state?.adv?.toBeInitialized, adSlot),
        },
      }
    }
    case actionTypes.REMOVE_FROM_CATEGORY_ADUNITS_LIST: {
      const { list: toBeRemoved } = action.payload
      return {
        ...state,
        adUnits: {
          ...(state.adUnits || {}),
          toBeInitialized: removeFromCategoryAdvList(state?.adv?.toBeInitialized, toBeRemoved),
        },
      }
    }
    default:
      return state
  }
}
