export const selectUserId = state => state.user.id

export const selectSessionId = state => state.user.sid

export const selectUserGeolocation = state => state.user.geolocation

export const selectUserNearestStoreId = state => state.user.storeId

export const selectUser = state => ({
  id: selectUserId(state),
  sid: selectSessionId(state),
  geolocation: selectUserGeolocation(state),
})
