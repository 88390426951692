import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a retailerId, fetch retailer assets
 * @param {Object} params - Accepted params
 * @param {string} params.retailerId - The retailerId
 */
export const fetchRetailerAssets = ({ retailerId }) => {
  const options = {
    resource: `retailers/${retailerId}/assets`,
    paginate: false,
    dataOnly: true,
    params: {
      conditions: {
        name: 'logo_segnalino,logo_transparent,logo_segnalino_web',
      },
    },
  }

  return fetchRestData(options)
}
