import createActionTypes from './createActionTypes'

export const namespace = 'INIT'

export const types = createActionTypes(namespace, [
  'INIT_CAMPAIGN',
])

export const initCampaign = () => ({
  type: types.INIT_CAMPAIGN,
  payload: {},
})
