import { select, call } from 'redux-saga/effects'

import { selectFlyerId } from '../../../selectors/campaign'
import { selectCountry } from '../../../selectors/ux'
import { getSecret } from '../../../../utils/i18n/secret'
import getInteger from '../../../../utils/getInteger'
import removeUndefinedProps from '../../../../helpers/removeUndefinedProps'

export function* trackGA(params) {
  const googleAnalyticsGA4PropertyId = getSecret('GA4_PROPERTY_ID')()
  const flyerId = yield select(selectFlyerId)
  const country = yield select(selectCountry)

  const {
    customParams = {
      dimension1: flyerId,
      dimension2: country,
    },
    hitType,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
  } = params

  const gaParams = {
    event_category: eventCategory,
    event_label: eventLabel,
    value: eventValue ? getInteger(eventValue) : undefined,
  }

  const formattedParams = removeUndefinedProps({ ...gaParams, ...customParams })

  try {
    yield call(window.gtag, hitType, eventAction, {
      ...formattedParams, send_to: googleAnalyticsGA4PropertyId,
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function* initGoogleAnalytics() {
  const googleAnalyticsGA4PropertyId = getSecret('GA4_PROPERTY_ID')()

  if (typeof window.gtag !== 'function' || !googleAnalyticsGA4PropertyId) {
    // eslint-disable-next-line no-console
    console.error('error', 'Cannot init Google Analytics')
    return
  }
  try {
    const gaConfig = {
      anonymize_ip: true,
      transport_type: 'beacon',
    }
    yield call(window.gtag, 'config', googleAnalyticsGA4PropertyId, gaConfig)
  } catch (error) {
  // eslint-disable-next-line no-console
    console.error(error)
  }
}
