import { call, select } from 'redux-saga/effects'

import { trackSF, trackFBQ, trackGAW } from '../providers'
import { NOT_AVAILABLE } from '../../../../helpers/const'
import { selectHasStores, selectCountry, selectContext } from '../../../selectors/ux'
import { selectUserNearestStoreId } from '../../../selectors/user'
import { getSecretGaw } from '../../../../utils/i18n/secret'

function* callProduct(action) {
  const { params } = action.payload
  const {
    position, to, id, title,
  } = params
  const country = yield select(selectCountry)
  const context = yield select(selectContext)
  const googleAdwords = getSecretGaw({ context, country })

  const hasStores = yield select(selectHasStores)
  const SfValue = hasStores ? yield select(selectUserNearestStoreId) : ''

  const productTitle = title || NOT_AVAILABLE

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'c',
    s2s_ec: 'c',
    s2s_to: to,
    s2s_ev: SfValue,
    s2s_pos: position,
    s2s_iid: id,
    s2s_title: productTitle,
  })

  // Facebook
  yield call(trackFBQ, 'trackCustom', 'Call Now')

  // Google AdWords
  yield call(trackGAW, {
    conversionID: googleAdwords.conversionID,
    conversionLabel: googleAdwords.conversionLabel,
    google_remarketing_only: false,
    google_custom_params: {
      s2s_ea: 'c',
      s2s_ec: 'c',
      s2s_pos: position,
      s2s_iid: id,
      s2s_title: productTitle,
    },
  })

  // Google AdWords - Remarketing
  yield call(trackGAW, {
    conversionID: googleAdwords.remarketingConversionID,
    conversionLabel: googleAdwords.remarketingConversionLabel,
    google_remarketing_only: true,
    google_custom_params: {
      s2s_ea: 'c',
      s2s_ec: 'c',
      s2s_pos: position,
      s2s_iid: id,
      s2s_title: productTitle,
    },
  })
}

export default callProduct
