import { select, put } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'
import { ORIGIN_SIMILAR_CAROUSEL } from '../../../helpers/const'

function* onSimilarProductCarouselClick(action) {
  const { params } = action.payload
  const { productId, category, position } = params

  const product = yield select(selectProduct(productId))

  yield put(trackEvent({
    event: 'openDetail',
    params: {
      id: product.id,
      title: product.title,
      position,
      category,
      origin: ORIGIN_SIMILAR_CAROUSEL,
    },
  }))
}

export default onSimilarProductCarouselClick
