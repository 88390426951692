import { select, put } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'
import { selectCurrentProductId, selectCurrentCollectionIndex } from '../../selectors/ux'

function* onProductBannerClick() {
  const productId = yield select(selectCurrentProductId)
  const product = yield select(selectProduct(productId))
  const position = (yield select(selectCurrentCollectionIndex)) + 1 // from 1 to N

  yield put(trackEvent({
    event: 'noStoresClick',
    params: {
      id: product.id,
      title: product.title,
      position,
    },
  }))
}

export default onProductBannerClick
