import getContext from './getContext'

import labels from '../i18n/labels'

const geti18nLabelsByContext = () => {
  const context = getContext()
  return labels[context] || labels.default
}

export default geti18nLabelsByContext
