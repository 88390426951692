import clone from 'lodash.clonedeep'

/*
The algorithm cycles the first category of the product, with a `shift`,
maintaining the order of products and the order of categories.
To maintain the immutability of the state ( state.products ) a deep-clone of the array is necessary.

Pseudo Example

{ order: 1, category: ["iphone", "smartphone", "elettronica"]}
{ order: 2, category: ["smartphone"]}
{ order: 3, category: ["lavatrici", "samsung", "elettronica"]}
{ order: 4, category: ["samsung", "smartphone", "elettronica"]}
{ order: 5, category: ["elettronica", "iphone"]}

RESULT
[
  "iphone": [1, 5],
  "smartphone": [2, 1, 4],
  "lavatrici": [3],
  "samsung": [4, 3],
  "elettronica": [5, 1, 3, 4],
]

NB: this algorithm can be improved by performing a single scan of the array => O(n)
    but need a supplementary data structure to save the temporary order of products
*/

const groupProductsByCategory = (items, otherDealsLabel) => {
  const grouped = new Map()
  let index = 0
  const products = clone(items)
  const realProducts = clone(items)
  let product = products[index]

  while (product) {
    if (!product?.settings?.category?.length) {
      const groupedCategory = grouped.get(otherDealsLabel)
      if (groupedCategory) {
        grouped.set(otherDealsLabel, [...groupedCategory, realProducts[index]])
      } else {
        grouped.set(otherDealsLabel, [realProducts[index]])
      }
      products.splice(index, 1)
      realProducts.splice(index, 1)
      index = index >= products.length ? 0 : index
      product = products[index]
    } else {
      const category = product.settings.category.shift()
      const groupedCategory = grouped.get(category)
      if (groupedCategory) {
        grouped.set(category, [...groupedCategory, realProducts[index]])
      } else {
        grouped.set(category, [realProducts[index]])
      }

      if (product.settings.category.length === 0) {
        products.splice(index, 1)
        realProducts.splice(index, 1)
        index = index >= products.length ? 0 : index
        product = products[index]
      } else {
        index = products[index + 1] ? index + 1 : 0
        product = products[index]
      }
    }
  }

  const arrProducts = Array.from(grouped.entries())
  let collectionIndex = -1

  for (let i = 0; i <= arrProducts.length - 1; i += 1) {
    // eslint-disable-next-line no-loop-func
    arrProducts[i][1] = arrProducts[i][1].map(el => {
      collectionIndex += 1

      return ({
        collectionIndex,
        title: el.title,
        id: el.id,
        mediaType: el.mediaType,
      })
    })
  }

  return [...arrProducts]
}

export default groupProductsByCategory
