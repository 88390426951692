import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'
import { ALL_CATEGORIES_KEY } from '../../../../helpers/const'

function* filterSelected(action) {
  const { params } = action.payload
  const { categories } = params

  const items = categories.length ? categories : [ALL_CATEGORIES_KEY]
  const selected = {}

  for (const [index, category] of items.entries()) {
    selected[`s2s_el[${index}]`] = category
  }

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'fs',
    s2s_ec: 'c',
    ...selected,
  })
}

export default filterSelected
