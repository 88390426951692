import { put } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'

function* onBrandHeaderLogoClick() {
  yield put(trackEvent({
    event: 'goTo',
    params: { destination: 'web' },
  }))
}

export default onBrandHeaderLogoClick
