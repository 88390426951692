import createActionTypes from './createActionTypes'

export const namespace = 'UX'

export const types = createActionTypes(namespace, [
  'EVENT',
  'SET_IS_WEBVIEW',
  'SET_IS_CAMPAIGN_EXPIRED',
  'SET_CURRENT_CATEGORIES',
  'SET_CURRENT_CAMPAIGN',
  'SET_CURRENT_PRODUCT',
  'SET_CURRENT_COLLECTION_INDEX',
  'SET_SEARCH',
  'SET_HAS_STORES',
  'SET_COUNTRY',
  'SET_HAS_TITLES',
  'SET_HAS_CATEGORIES',
  'ADD_EXPANDED_CATEGORY',
  'REMOVE_EXPANDED_CATEGORY',
  'SET_STORES_LIST_IS_OPEN',
  'SET_PRODUCT_INFO_IS_OPEN',
  'SET_PRODUCT_INFO_CLOSE',
  'SET_PRODUCT_INFO_MODE',
])

export const uxEvent = ({ name, target, params = {} }) => ({
  type: types.EVENT,
  payload: { name, target, params },
})

export const setIsWebView = mode => ({
  type: types.SET_IS_WEBVIEW,
  payload: { mode },
})

export const setIsCampaignExpired = isCampaignExpired => ({
  type: types.SET_IS_CAMPAIGN_EXPIRED,
  payload: { isCampaignExpired },
})

export const setCurrentCategories = categories => ({
  type: types.SET_CURRENT_CATEGORIES,
  payload: { categories },
})

export const setCurrentCampaign = (campaign = null) => ({
  type: types.SET_CURRENT_CAMPAIGN,
  payload: { campaign },
})

export const setCurrentProduct = (product = null) => ({
  type: types.SET_CURRENT_PRODUCT,
  payload: { product },
})

export const setCurrentCollectionIndex = (collectionIndex = null) => ({
  type: types.SET_CURRENT_COLLECTION_INDEX,
  payload: { collectionIndex },
})

export const setSearch = search => ({
  type: types.SET_SEARCH,
  payload: { search },
})

export const setHasStores = hasStores => ({
  type: types.SET_HAS_STORES,
  payload: { hasStores },
})

export const setCountry = country => ({
  type: types.SET_COUNTRY,
  payload: { country },
})

export const setHasTitles = hasTitles => ({
  type: types.SET_HAS_TITLES,
  payload: { hasTitles },
})

export const setHasCategories = hasCategories => ({
  type: types.SET_HAS_CATEGORIES,
  payload: { hasCategories },
})

export const addExpandedCategory = categoryName => ({
  type: types.ADD_EXPANDED_CATEGORY,
  payload: { categoryName },
})

export const removeExpandedCategory = categoryName => ({
  type: types.REMOVE_EXPANDED_CATEGORY,
  payload: { categoryName },
})

export const setStoresListIsOpen = open => ({
  type: types.SET_STORES_LIST_IS_OPEN,
  payload: { open },
})

export const setProductInfoDrawerOpen = mode => ({
  type: types.SET_PRODUCT_INFO_IS_OPEN,
  payload: { mode },
})

export const setProductInfoDrawerClose = () => ({
  type: types.SET_PRODUCT_INFO_CLOSE,
  payload: {},
})

export const setProductInfoDrawerMode = mode => ({
  type: types.SET_PRODUCT_INFO_MODE,
  payload: { mode },
})
