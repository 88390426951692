import getContext from './getContext'

import consts from '../i18n/consts'

const geti18nConstsByContext = () => {
  const context = getContext()
  return consts[context] || consts.default
}

export default geti18nConstsByContext
