export const ALL_CATEGORIES_KEY = '*'

export const WEBVIEW_MODE = 'm'
export const CAROUSEL_VIEW_MODE = 'carousel'
export const COLLECTION_VIEW_MODE = 'collection'
export const MEDIA_TYPE_IMAGE = 'MEDIA_TYPE_IMAGE'
export const MEDIA_TYPE_VIDEO = 'MEDIA_TYPE_VIDEO'
export const NOT_AVAILABLE = 'N/A'
export const DIRECTION_LEFT = 'LEFT'
export const DIRECTION_RIGHT = 'RIGHT'

const campaignNameIdPlaceholder = '{{s2s_campaign_name_id}}'
const productIdPlaceholder = '{{productId}}'
export const APP_ROUTES = {
  campaignNameIdPlaceholder,
  productIdPlaceholder,
  carouselProductPage: `/${campaignNameIdPlaceholder}/carousel/${productIdPlaceholder}`,
  collectionHomePage: `/${campaignNameIdPlaceholder}/collection`,
  collectionProductPage: `/${campaignNameIdPlaceholder}/collection/${productIdPlaceholder}`,
}

// Tracking
export const ORIGIN_LANDING = 'landing'
export const ORIGIN_SIMILAR_CAROUSEL = 'similarProducts'
export const ORIGIN_EXTERNAL_PLACEHOLDER = '{{source}}'
export const ORIGIN_EXTERNAL = `from-${ORIGIN_EXTERNAL_PLACEHOLDER}`
export const ORIGIN_PRODUCT = 'product'
export const CONTEXT_HEADER = 'header'
export const CONTEXT_DETAIL = 'detail'
export const CONTEXT_STORE = 'store'
export const CONTEXT_NAVIGATOR = 'navigator'
export const CONTEXT_BROWSER_BACK = 'browser-back'
export const CONTEXT_DRAWER = 'product-drawer'
export const OT_TARGETING_ADS_PREFERENCE = 'C0004'
export const OT_MANDATORY_COOKIE_PREFERENCE = 'C0001'
export const OT_ALL_PREFERENCES = [OT_TARGETING_ADS_PREFERENCE, 'C0002', OT_MANDATORY_COOKIE_PREFERENCE, 'V2STACK42']

// ctas
export const MOBILE_APP = 'mobile'
export const BROWSER = 'browser'
export const CTAS_TYPES = {
  FavouriteCta: 'FavouriteCta',
  ProductCta: 'ProductCta',
  CallCta: 'CallCta',
}

// manipulator
export const MAX_MANIPULATOR_STORES = 50

// product
export const PRODUCT_AVAILABILITY = {
  'in stock': {
    color: 'green',
    visiblePreview: false,
  },
  'limited stock': {
    color: 'green',
    visiblePreview: true,
  },
  'out of stock': {
    color: 'primary',
    visiblePreview: true,
  },
  preorder: {
    color: 'orange',
    visiblePreview: true,
  },
}

// adv targets
export const CLIENT_TYPES = {
  app: 'app',
  web: 'web',
}

// regex
export const PLACEHOLDER_REGEX = /\[\[.*\]\]|{{.*}}/
export const CAMPAIGN_NAME_ID_REGEX = /[a-z]{2}_[a-z]{2}-[a-z0-9]+/
export const PRODUCT_ID_REGEX = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/

export const ERROR_TYPE_NOT_FOUND = 'notFound'
export const ERROR_TYPE_EXPIRED = 'expired'
export const ERROR_TYPE_ERROR = 'error'

/**
 * See [MediaError.code]{@link https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code}.
 */
export const VIDEO_MEDIA_ERROR_CODES = {
  1: 'MEDIA_ERR_ABORTED', // The fetching of the associated resource was aborted by the user's request.
  2: 'MEDIA_ERR_NETWORK', // Some kind of network error occurred which prevented the media from being successfully fetched, despite having previously been available.
  3: 'MEDIA_ERR_DECODE', // Despite having previously been determined to be usable, an error occurred while trying to decode the media resource, resulting in an error.
  4: 'MEDIA_ERR_SRC_NOT_SUPPORTED', // The associated resource or media provider object (such as a MediaStream) has been found to be unsuitable.
}
export const EXPANDABLE_DRAWER_MODE_EXPANDED = 'expanded'
export const EXPANDABLE_DRAWER_MODE_PREVIEW = 'preview'
export const EXPANDABLE_DRAWER_ACTION_SWIPE = 'swipe'
export const EXPANDABLE_DRAWER_ACTION_CTA = 'cta'

export const SWIPER_NO_SWIPING_CLASS = 'swiper-no-swiping'

export const HERO_SECTION_HEIGHT = 150

export const CONSENT_MODE_VALUES = {
  0: 'denied',
  1: 'granted',
}
