import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'

function* navigateTo(action) {
  const { params } = action.payload
  const {
    id, address, zip, city, to,
  } = params

  const eventLabel = [address, zip, city].join(' - ')

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'nt',
    s2s_ec: 'c',
    s2s_el: eventLabel,
    s2s_ev: id,
    s2s_to: to,
  })
}

export default navigateTo
