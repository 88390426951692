import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a flyerId, fetch flyer's gibs list
 * @param {Object} params
 * @param {string} params.flyerId
 */

export const fetchFlyerGibs = ({ flyerId }) => {
  const options = {
    resource: `flyers/${flyerId}/flyer_gibs`,
    paginate: true,
    dataOnly: true,
  }

  return fetchRestData(options)
}
