import { put } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'
import { setCurrentCategories } from '../../actions/ux'

function* onShowAllSimilarProductsCtaClick(action) {
  const { params: { category } } = action.payload

  yield put(trackEvent({
    event: 'filterSelected',
    params: {
      categories: [category],
    },
  }))

  yield put(trackEvent({
    event: 'viewAllSimilarProduct',
    params: {
      category,
    },
  }))

  yield put(setCurrentCategories([category]))
}

export default onShowAllSimilarProductsCtaClick
