import getMediaType from '../../../helpers/getMediaType'
import isDateInRange from '../../../helpers/isDateInRange'
import sortProducts from '../../../helpers/sortProducts'

const manipulateProducts = (items, currentDate, isPreview, favouriteProductIds = []) => {
  const products = {}
  const categories = new Set()
  if (!items || items.length === 0) return { productCategories: [], products: {}, hasTitles: false }

  const sortedProducts = sortProducts(items)
  let productOrderIndex = 0
  let hasTitles = false

  for (let index = 0; index < sortedProducts.length; index += 1) {
    const {
      id: productId,
      settings: {
        category: productCategories,
        available_start: availableStart,
        available_end: availableEnd,
        gibCover,
        video_url: videoUrl,
      },
    } = sortedProducts[index]
    hasTitles = !!(sortedProducts[index].title || hasTitles)

    const isFavouriteProduct = favouriteProductIds.includes(productId)
    const hasCategories = productCategories && productCategories.length > 0
    const isAvailable = (!isPreview && (!!availableStart && !!availableEnd))
      ? isDateInRange(availableStart, currentDate, availableEnd)
      : true

    if (isAvailable) {
      const productMedia = () => {
        if (videoUrl) return videoUrl
        return gibCover?.default?.[0] || gibCover?.big?.[0] || ''
      }

      productOrderIndex += 1
      products[productId] = {
        ...sortedProducts[index],
        mediaType: getMediaType(productMedia()),
        isFavouriteProduct,
        settings: {
          ...sortedProducts[index].settings,
          order: productOrderIndex,
        },
      }
    }

    if (isAvailable && hasCategories) {
      for (const category of productCategories) {
        categories.add(category)
      }
    }
  }

  return {
    products,
    productCategories: Array.from(categories),
    hasTitles,
  }
}

export default manipulateProducts
