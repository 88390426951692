import { selectConst } from './i18n'
import { selectIsWebView } from './ux'
import stringTemplate from '../../utils/stringTemplate'

export const selectCampaignRetailerLogoSrc = state => state.layout.logoURL

export const selectCampaignCategoryList = state => state.layout.categoriesList

export const selectCampaignRetailerPageURL = state => {
  const { retailerId, retailerSlug } = state.tracking
  const isWebView = selectIsWebView(state)

  const domain = selectConst('domain')(state)

  const protocolPath = isWebView ? 'mobile.protocol' : 'web.protocol'
  const protocol = selectConst(protocolPath)(state)

  const retailerPath = isWebView ? 'mobile.retailer' : 'web.retailer'
  const path = selectConst(retailerPath)(state)

  if (path) {
    return stringTemplate(`${protocol}${domain}${path}`, { retailerId, retailerSlug })
  }
  return ''
}

export const selectCampaignRetailerPinSrc = state => (
  state.layout.pinURL || state.layout.defaultPinURL
)

export const selectCampaignRetailerName = state => {
  const { retailerId } = state.tracking
  const retailer = state.retailers[retailerId]
  return retailer?.name
}

export const selectCampaignRetailerId = state => state.tracking.retailerId

export const selectCampaignRetailerSlug = state => state.tracking.retailerSlug

export const selectCategorySlug = state => state.tracking.categorySlug

export const selectFlyerId = state => state.tracking.flyerId

export const selectCampaignName = state => state.tracking.campaignName
