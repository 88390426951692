import { WEBVIEW_MODE } from '../../helpers/const'
import getQueryParams from '../../utils/getQueryParams'
import theme from '../../app/ui/Layout/theme'
import getContext from '../../utils/context/getContext'

const { t } = getQueryParams()
const context = getContext()

export default {
  isWebView: t ? t === WEBVIEW_MODE : false,
  isCampaignReady: false,
  isCampaignExpired: null,
  currentCategories: [],
  currentCampaignNameId: null,
  currentProductId: null,
  currentCollectionIndex: null,
  search: '',
  hasStores: undefined,
  hasTitles: undefined,
  hasCategories: undefined,
  country: null,
  expandedCategories: [],
  isStoresListDrawerOpen: false,
  productInfoDrawer: {
    isOpen: false,
    mode: 'preview',
  },
  context,
  theme: theme[context] || theme.default,
}
