import { fork, spawn, take } from 'redux-saga/effects'

import { types as trackingActionTypes } from '../actions/tracking'

const takeEveryTrackingEvent = (saga, { event }, ...args) => fork(
  function* handler() {
    while (true) {
      const action = yield take(trackingActionTypes.TRACK)
      const { payload = {} } = action

      let handle = true
      if (payload.event !== event) handle = false
      if (handle) yield spawn(saga, ...args.concat(action))
    }
  },
)

export default takeEveryTrackingEvent
