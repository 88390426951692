import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a flyerId, fetch flyer info
 * @param {Object} params
 * @param {string} params.flyerId
 */
export const fetchFlyer = ({ flyerId }) => {
  const options = {
    resource: `flyers/${flyerId}`,
    paginate: false,
    dataOnly: true,
    oneResource: true,
    params: {
      fields: 'id,title,retailer_id,settings,end_date',
    },
  }

  return fetchRestData(options)
}
