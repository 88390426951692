import {
  all,
  fork,
} from 'redux-saga/effects'

import watchApi from './watchApi'

function* apiSagas() {
  yield all([
    fork(watchApi),
  ])
}

export default apiSagas
