import { put, call, select } from 'redux-saga/effects'

import { fetchRetailer } from '../../../../api/retailers/fetchRetailer'

import { setRetailer } from '../../../actions/retailers'
import { getRetailerAssets } from '../../../actions/api'
import { selectCampaignRetailerId } from '../../../selectors/campaign'
import { setTrackingRetailerSlug } from '../../../actions/tracking'

// eslint-disable-next-line consistent-return
export function* retailerJob({ country }) {
  const retailerId = yield select(selectCampaignRetailerId)
  const retailer = (yield call(fetchRetailer, { country, retailerId })) || {}

  yield put(setRetailer(retailerId, { id: retailerId, ...retailer }))
  yield put(getRetailerAssets(retailerId))
  yield put(setTrackingRetailerSlug(retailer.slug))

  return {
    retailer,
  }
}
