import { call } from 'redux-saga/effects'

import {
  trackSF,
  trackFBQ,
  trackGAW,
  trackGAWR,
} from '../providers'
import { NOT_AVAILABLE } from '../../../../helpers/const'

function* productInfo(action) {
  const { params } = action.payload
  const {
    id, title, position, categories, origin,
  } = params

  const productTitle = title || NOT_AVAILABLE
  const eventLabel = `Position${position}-${id}-${title}`

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'mi',
    s2s_ec: 'c',
    s2s_el: eventLabel,
    s2s_pos: position,
    s2s_iid: id,
    s2s_title: productTitle,
    s2s_origin: origin,
  })

  // Facebook
  yield call(trackFBQ, 'trackCustom', 'More Info')

  // Google AdWords
  yield call(trackGAW, {
    google_custom_params: {
      s2s_ea: 'mi',
      s2s_ec: 'c',
      s2s_pos: position,
      s2s_iid: id,
      s2s_title: productTitle,
      s2s_categories: categories,
    },
  })

  // Google AdWords - Remarketing
  yield call(trackGAWR, {
    google_custom_params: {
      s2s_ea: 'mi',
      s2s_ec: 'c',
      s2s_pos: position,
      s2s_iid: id,
      s2s_title: productTitle,
      s2s_categories: categories,
    },
  })
}

export default productInfo
