import createActionTypes from './createActionTypes'

export const namespace = 'STORES'

export const types = createActionTypes(namespace, [
  'SET_STORES',
])

export const setStores = stores => ({
  type: types.SET_STORES,
  payload: { stores },
})
