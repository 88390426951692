import { put, call, select } from 'redux-saga/effects'
import { fetchCategory } from '../../../../api/categories/fetchCategory'
import { setTrackingCategorySlug } from '../../../actions/tracking'
import { selectCountry } from '../../../selectors/ux'

// eslint-disable-next-line consistent-return
export function* categoryJob({ categoryId }) {
  const country = yield select(selectCountry)
  const category = (yield call(fetchCategory, { country, categoryId })) || {}

  yield put(setTrackingCategorySlug(category.slug))
}
