import initialState from '../initialState/ux'

import { types as actionTypes } from '../actions/ux'
import { EXPANDABLE_DRAWER_MODE_EXPANDED } from '../../helpers/const'

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_WEBVIEW: {
      const { mode } = action.payload

      return {
        ...state,
        isWebView: mode,
      }
    }
    case actionTypes.SET_IS_CAMPAIGN_EXPIRED: {
      const { isCampaignExpired } = action.payload

      return {
        ...state,
        isCampaignExpired,
      }
    }
    case actionTypes.SET_CURRENT_CATEGORIES: {
      const { categories } = action.payload

      return {
        ...state,
        currentCategories: [
          ...categories,
        ],
      }
    }
    case actionTypes.SET_CURRENT_CAMPAIGN: {
      const { campaign } = action.payload

      return {
        ...state,
        currentCampaignNameId: campaign,
      }
    }
    case actionTypes.SET_CURRENT_PRODUCT: {
      const { product } = action.payload

      return {
        ...state,
        currentProductId: product,
      }
    }
    case actionTypes.SET_CURRENT_COLLECTION_INDEX: {
      const { collectionIndex } = action.payload

      return {
        ...state,
        currentCollectionIndex: collectionIndex,
      }
    }
    case actionTypes.SET_SEARCH: {
      const { search } = action.payload

      return {
        ...state,
        search,
      }
    }
    case actionTypes.SET_HAS_STORES: {
      const { hasStores } = action.payload

      return {
        ...state,
        hasStores,
      }
    }
    case actionTypes.SET_COUNTRY: {
      const { country } = action.payload

      return {
        ...state,
        country,
      }
    }
    case actionTypes.SET_HAS_TITLES: {
      const { hasTitles } = action.payload

      return {
        ...state,
        hasTitles,
      }
    }
    case actionTypes.SET_HAS_CATEGORIES: {
      const { hasCategories } = action.payload

      return {
        ...state,
        hasCategories,
      }
    }
    case actionTypes.ADD_EXPANDED_CATEGORY: {
      const { categoryName } = action.payload

      return {
        ...state,
        expandedCategories: [...state.expandedCategories, categoryName],
      }
    }
    case actionTypes.REMOVE_EXPANDED_CATEGORY: {
      const { categoryName } = action.payload

      return {
        ...state,
        expandedCategories: state.expandedCategories.filter(cat => cat !== categoryName),
      }
    }

    case actionTypes.SET_STORES_LIST_IS_OPEN: {
      const { open } = action.payload

      return {
        ...state,
        isStoresListDrawerOpen: open,
      }
    }

    case actionTypes.SET_PRODUCT_INFO_IS_OPEN: {
      const { mode } = action.payload

      const alreadyOpen = state.productInfoDrawer?.isOpen
      const previousMode = state.productInfoDrawer?.mode

      return {
        ...state,
        productInfoDrawer: {
          isOpen: true,
          mode: (
            alreadyOpen && previousMode === EXPANDABLE_DRAWER_MODE_EXPANDED
              ? previousMode
              : mode
          ),
        },
      }
    }

    case actionTypes.SET_PRODUCT_INFO_CLOSE: {
      return {
        ...state,
        productInfoDrawer: initialState.productInfoDrawer,
      }
    }

    case actionTypes.SET_PRODUCT_INFO_MODE: {
      const { mode } = action.payload

      return {
        ...state,
        productInfoDrawer: {
          isOpen: true,
          mode,
        },
      }
    }
    default:
      return state
  }
}
