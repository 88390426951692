import { parse, isValid } from 'date-fns'

import getQueryParams from '../utils/getQueryParams'

export const parsePreview = preview => {
  const previewDate = parse(preview, 'dd/MM/yyyy', new Date())
  const currentDate = isValid(previewDate) ? previewDate : new Date()
  return currentDate
}

export const getCurrentDate = () => {
  const { preview } = getQueryParams()
  const date = parsePreview(preview)
  return date.getTime()
}
