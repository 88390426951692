import remove from 'lodash.remove'
import { createMemoSelector } from '../helpers/selector'
import { selectConst, selectLabel } from './i18n'
import stringTemplate from '../../utils/stringTemplate'
import {
  selectBrandColorText, selectBrandColor, selectCtaList, selectHasPhoneFromStore,
} from './layout'
import {
  BROWSER, MOBILE_APP, CTAS_TYPES,
} from '../../helpers/const'
import { selectIsWebView, selectCurrentTheme } from './ux'
import { selectStorePhone } from './stores'

export const selectProductDescription = productId => state => (
  state.products[productId].description
)

export const selectProduct = productId => state => state.products?.[productId]

export const selectProductImage = productId => state => {
  const product = selectProduct(productId)(state)
  if (!product?.settings) {
    return null
  }

  const { settings: { gibCover } } = product
  return gibCover?.default?.[0] || gibCover?.big?.[0] || ''
}

export const selectProductPreviewImage = productId => state => {
  const product = selectProduct(productId)(state)

  if (!product?.settings) {
    return null
  }

  const { settings: { gibCover } } = selectProduct(productId)(state)
  const theme = selectCurrentTheme(state)

  if (window.innerWidth < theme.breakpoints.medium) {
    return gibCover?.small?.[0] || ''
  }
  return gibCover?.default?.[0] || ''
}

export const selectProductMediaType = productId => state => {
  const product = selectProduct(productId)(state)
  return product?.mediaType
}

export const selectProductMedia = productId => state => {
  const product = selectProduct(productId)(state)

  if (!product) {
    return ''
  }

  const {
    settings: { video_url: videoUrl } = {},
  } = product

  return videoUrl || selectProductImage(productId)(state)
}

export const selectProductDiscountedPrice = productId => state => {
  const { settings: { starting_price: startingPrice } } = selectProduct(productId)(state)
  return (
    `${startingPrice.currency_symbol} ${startingPrice.digits}`
  )
}

export const selectProductFavouritesURL = productId => createMemoSelector(
  selectConst('domain'),
  selectConst('mobile.favourite'),
  selectConst('mobile.protocol'),
  (domain, path, protocol) => (
    path ? stringTemplate(`${protocol}${domain}${path}`, { productId }) : ''
  ),
)

const parseEnabledCTAs = ({
  productCtaTypes,
  ctaList,
  previewMode,
  gridCardMode,
  isWebView,
}) => {
  const ctas = []
  const currentVisibility = isWebView ? MOBILE_APP : BROWSER

  for (const ctaType of productCtaTypes) {
    const productCta = ctaList[ctaType]
    const isCtaVisible = productCta?.visibility.includes(currentVisibility)
    if (isCtaVisible) {
      ctas.push(productCta)
    }
  }

  if (previewMode) {
    const variableCTAs = ctas.filter(
      x => x.type === CTAS_TYPES.ProductCta || x.type === CTAS_TYPES.CallCta,
    )

    if (variableCTAs.length > 1) {
      remove(ctas, x => x.type === CTAS_TYPES.CallCta)
    }
  } else if (gridCardMode) {
    remove(ctas, x => x.type !== CTAS_TYPES.ProductCta)
  }

  return ctas
}

export const getProductCtas = (
  product,
  ctaFavouritesURL,
  ctaFavouritesSaveText,
  ctaProductText,
  ctaCallText,
  ctaCallNum,
  brandColor,
  brandColorText,
  productCtas,
  hasPhoneFromStore,
  storePhone,
  isWebView,
  theme,
  settings = {},
) => {
  const { previewMode, gridCardMode } = settings
  const productCtaTypes = productCtas.reduce((list, { type }) => [...list, type], [])

  const ctaProductUrl = product.settings.url

  const { isFavouriteProduct } = product
  const ctaCallUrl = () => {
    if (hasPhoneFromStore && storePhone) return `tel://${storePhone}`
    if (ctaCallNum) return `tel://${ctaCallNum}`
    return ''
  }
  const ctaList = {
    reminder: {
      type: CTAS_TYPES.FavouriteCta,
      text: ctaFavouritesSaveText,
      href: ctaFavouritesURL,
      target: '_self',
      color: theme.colors.primary,
      borderColor: theme.colors.primary,
      backgroundColor: theme.colors.white,
      hasIcon: !isFavouriteProduct,
      hasToggleIcon: true,
      visibility: ctaFavouritesURL ? [MOBILE_APP] : [],
    },
    product: {
      type: CTAS_TYPES.ProductCta,
      text: ctaProductText,
      href: ctaProductUrl,
      target: '_blank',
      color: brandColorText,
      backgroundColor: brandColor,
      visibility: ctaProductUrl ? [BROWSER, MOBILE_APP] : [],
    },
    call: {
      type: CTAS_TYPES.CallCta,
      text: ctaCallText,
      href: ctaCallUrl(),
      target: '_self',
      color: brandColorText,
      backgroundColor: brandColor,
      hasIcon: true,
      hasToggleIcon: true,
      visibility: ctaCallUrl() ? [BROWSER, MOBILE_APP] : [],
    },
  }

  return parseEnabledCTAs({
    productCtaTypes,
    ctaList,
    previewMode: previewMode && isWebView,
    gridCardMode,
    isWebView,
  })
}

export const selectProductCtas = (productId, storeId, settings = {}) => createMemoSelector(
  selectProduct(productId),
  selectProductFavouritesURL(productId),
  selectLabel('saveFavourites'),
  selectLabel('ctaProduct'),
  selectLabel('ctaCall'),
  selectLabel('ctaCallNum'),
  selectBrandColor,
  selectBrandColorText,
  selectCtaList,
  selectHasPhoneFromStore,
  selectStorePhone(storeId),
  selectIsWebView,
  selectCurrentTheme,
  (...args) => getProductCtas(...args, settings),
)
