const setImageFallback = noMediaImage => {
  document.addEventListener('error', e => {
    const node = e.target
    const isNodeAnImage = node && node.nodeName.toLowerCase() === 'img'
    const isFallbackAvailable = isNodeAnImage ? node.getAttribute('data-fallback') : false
    if (isNodeAnImage && !isFallbackAvailable) return

    const noMediaImg = new Image()
    noMediaImg.setAttribute('src', noMediaImage)

    if (isNodeAnImage && e.target.getAttribute('src') !== noMediaImg.getAttribute('src')) {
      e.target.setAttribute('src', noMediaImg.getAttribute('src'))
    }
  }, true)
}

export default setImageFallback
