import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'
import { NOT_AVAILABLE } from '../../../../helpers/const'

function* openDetail(action) {
  const { params } = action.payload
  const {
    id, title, position, origin, category,
  } = params

  const productTitle = title || NOT_AVAILABLE
  const eventLabel = `Position${position}-${id}-${productTitle}`

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'od',
    s2s_ec: 'c',
    s2s_el: eventLabel,
    s2s_pos: position,
    s2s_iid: id,
    s2s_title: productTitle,
    s2s_origin: origin,
    s2s_pc: category,
  })
}

export default openDetail
