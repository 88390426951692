import { all, fork } from 'redux-saga/effects'

import initSagas from './init'
import uxSagas from './ux'
import apiSagas from './api'
import trackingSagas from './tracking'
import advSagas from './adv'

function* rootSaga() {
  yield all([
    fork(initSagas),
    fork(uxSagas),
    fork(apiSagas),
    fork(trackingSagas),
    fork(advSagas),
  ])
}

export default rootSaga
