import { v1 as uuidv1 } from 'uuid'

const getSessionId = () => {
  const uuid = uuidv1()
  let sid = uuid

  try {
    const sidSessionStorage = sessionStorage.getItem('sid')
    if (sidSessionStorage) {
      sid = sidSessionStorage
    } else {
      sessionStorage.setItem('sid', uuid)
    }
  } catch (e) {
    // https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityerror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-this-document
    return sid
  }

  return sid
}

export default getSessionId
