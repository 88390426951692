import { fork, take, select } from 'redux-saga/effects'

import { selectCountry } from '../selectors/ux'

const takeEveryApiRequest = (actionName, saga) => fork(
  function* handler() {
    while (true) {
      const action = yield take(actionName)
      const country = yield select(selectCountry)

      action.payload = {
        ...action.payload,
        country,
      }

      yield fork(saga, action)
    }
  },
)

export default takeEveryApiRequest
