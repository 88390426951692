import { call, select } from 'redux-saga/effects'

import { trackSF } from '../providers'
import { selectCampaignRetailerSlug, selectCampaignRetailerId } from '../../../selectors/campaign'

function* openRetailerPage() {
  const retailerSlug = yield select(selectCampaignRetailerSlug)
  const retailerId = yield select(selectCampaignRetailerId)

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'orp',
    s2s_ec: 'c',
    s2s_ev: retailerId,
    s2s_el: retailerSlug,
  })
}

export default openRetailerPage
