import { call, select } from 'redux-saga/effects'

import { selectContext, selectCountry } from '../../../selectors/ux'
import { getSecretGaw } from '../../../../utils/i18n/secret'
import { selectUserCookiesPreferenceActive } from '../../../selectors/tracking'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../../helpers/const'

export function* trackGAWR(params = {}) {
  const context = yield select(selectContext)
  const country = yield select(selectCountry)

  const { remarketingConversionID, remarketingConversionLabel } = getSecretGaw({ country, context })

  const isTargetingAdsCookieEnable = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )

  if (!remarketingConversionID) {
    // eslint-disable-next-line no-console
    console.debug('Cannot track GAWR. remarketingConversion ID is missing.')
    return
  }

  const gawParams = {
    send_to: `${remarketingConversionID}${remarketingConversionLabel ? `/${remarketingConversionLabel}` : ''}`,
    google_remarketing_only: true,
    ...params,
  }

  try {
    if (isTargetingAdsCookieEnable) {
      yield call(window.gtag, 'event', 'conversion', gawParams)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function* initGoogleAdwordsRemarketing() {
  const context = yield select(selectContext)
  const country = yield select(selectCountry)

  const { remarketingConversionID } = getSecretGaw({ country, context })

  const isTargetingAdsCookieEnable = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )

  if (typeof window.gtag !== 'function' || !remarketingConversionID) {
    // eslint-disable-next-line no-console
    console.debug('error', 'Cannot init Google adwords')
    return
  }

  if (isTargetingAdsCookieEnable) {
    try {
      const gawrConfig = {
        transport_type: 'beacon',
      }

      yield call(window.gtag, 'config', `${remarketingConversionID}`, gawrConfig)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}
