import { take, call } from 'redux-saga/effects'

import { types as trackingActionTypes } from '../../actions/tracking'
import { initProviders } from './initProviders'
import initUnloadTracking from './initUnloadTracking'

function* watchInitTracking() {
  yield take(trackingActionTypes.INIT_TRACKING)

  yield call(initUnloadTracking)
  yield call(initProviders)
}

export default watchInitTracking
