import { types as actionTypes } from '../actions/i18n'
import initialState from '../initialState/i18n'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_I18N: {
      const { i18n } = action.payload
      return { ...i18n }
    }
    default:
      return state
  }
}
