import { types as actionTypes } from '../actions/defaultStoreHours'
import initialState from '../initialState/defaultStoreHours'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_STORE_HOURS: {
      const { defaultStoreHours } = action.payload
      return [...defaultStoreHours]
    }
    default:
      return state
  }
}
