import createActionTypes from './createActionTypes'

export const namespace = 'ADV'

export const types = createActionTypes(namespace, [
  'SET_AD_MANAGER_INITIALIZED',
  'SET_CATEGORY_ADUNIT_INITIALIZED',
  'REMOVE_FROM_CATEGORY_ADUNITS_LIST',
])

export const setAdManagerInitialized = isAdManagerInitialized => ({
  type: types.SET_AD_MANAGER_INITIALIZED,
  payload: { isAdManagerInitialized },
})

export const setCategoryAdUnitInitialized = adSlot => ({
  type: types.SET_CATEGORY_ADUNIT_INITIALIZED,
  payload: { adSlot },
})

export const removeFromCategoryAdUnitsList = list => ({
  type: types.REMOVE_FROM_CATEGORY_ADUNITS_LIST,
  payload: { list },
})
