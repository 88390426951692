import { set, addMinutes } from 'date-fns'
import formatHours from '../../helpers/formatHours'

const manipulateStoreHours = (storeHours, daysOfWeek) => {
  const hours = []

  for (const storeHour of storeHours) {
    const openHour = set(new Date(), {
      hours: storeHour.open.split(':')[0],
      minutes: storeHour.open.split(':')[1],
      seconds: 0,
    })
    const closeHour = addMinutes(openHour, storeHour.close)

    const apiHour = {
      number: storeHour.dow,
      from: { hh: openHour.getHours(), mm: openHour.getMinutes() },
      to: { hh: closeHour.getHours(), mm: closeHour.getMinutes() },
    }

    hours.push(apiHour)
  }
  return formatHours(daysOfWeek, hours)
}

export default manipulateStoreHours
