import { call, select } from 'redux-saga/effects'
import removeUndefinedProps from '../../../../helpers/removeUndefinedProps'

import getInteger from '../../../../utils/getInteger'
import { getSecret } from '../../../../utils/i18n/secret'
import { selectContext, selectCountry } from '../../../selectors/ux'

function* getGoogleAnalyticsCountryPropertyId() {
  const country = yield select(selectCountry)
  const context = yield select(selectContext)
  return getSecret('GA4_COUNTRY_PROPERTY')({ country, context })
}

export function* trackGAC(params) {
  const googleAnalyticsGA4PropertyId = yield getGoogleAnalyticsCountryPropertyId()

  const {
    hitType,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
  } = params

  const gaParams = {
    event_category: eventCategory,
    event_label: eventLabel,
    value: eventValue ? getInteger(eventValue) : undefined,
  }
  const formattedParams = removeUndefinedProps(gaParams)

  try {
    yield call(window.gtag, hitType, eventAction, {
      ...formattedParams, send_to: googleAnalyticsGA4PropertyId,
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function* initGoogleAnalyticsCountry() {
  const googleAnalyticsGA4PropertyId = yield getGoogleAnalyticsCountryPropertyId()
  if (typeof window.gtag !== 'function' || !googleAnalyticsGA4PropertyId) {
    // eslint-disable-next-line no-console
    console.error('error', 'Cannot init Google Analytics Country')
    return
  }
  const gaConfig = {
    anonymize_ip: true,
    transport_type: 'beacon',
  }
  try {
    yield call(window.gtag, 'config', googleAnalyticsGA4PropertyId, gaConfig)
  } catch (error) {
  // eslint-disable-next-line no-console
    console.error(error)
  }
}
