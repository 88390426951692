/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
import React from 'react'

export const initDebugFeatures = () => {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  const ReactRedux = require('react-redux/lib')

  whyDidYouRender(React, {
    trackAllPureComponents: true,
    trackExtraHooks: [
      [ReactRedux, 'useSelector'],
    ],
  })
}
