import { types as actionTypes } from '../actions/categories'
import initialState from '../initialState/categories'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES: {
      const { categories } = action.payload
      return [...categories]
    }
    default:
      return state
  }
}
