import React from 'react'

import Routes from './routes'
import CustomerImpressionPixel from './components/CustomerImpressionPixel'

const App = () => (
  <>
    <Routes />
    <React.StrictMode>
      <CustomerImpressionPixel />
    </React.StrictMode>
  </>
)

export default App
