import {
  call,
  select,
} from 'redux-saga/effects'

import qs from 'qs'
import UAParser from 'ua-parser-js'

import createTrackingMeta from '@doveconviene/sf-web-js-sdk/createTrackingMeta'

import { selectContext, selectCountry } from '../../../selectors/ux'
import { selectUserId, selectSessionId, selectUserGeolocation } from '../../../selectors/user'
import { selectFlyerId, selectCampaignName } from '../../../selectors/campaign'
import { getSecret } from '../../../../utils/i18n/secret'
import getQueryParams from '../../../../utils/getQueryParams'
import { selectENPropertyId } from '../../../selectors/tracking'
import { selectViewMode } from '../../../selectors/layout'

export const defaultConfig = {
  a: 's2s',
  t: 'w',
}

/*
   DOCS
  'st', // security token
  'et', // expire token
  'c', // country es(it_IT)
  'fid', // flyerid
  'a', // s2s
  'lm', // location mode
  'la', // location accuracy
  'fvk', // flyer view
  'ts', // timestamp iso
  'et', // timestamp integer
  'z', // randomintcacheburster
  'lat', // user latitude
  'lng', // user longitude
  'dcid', // uuid of user, generated or params
  'utm_medium', // utm_medium
  'utm_campaign', // utm_campaign
  'utm_source', // utm_source
  't', // t=m mobile, t=w web
  's2s_ea', // event action
  's2s_ec', // event category
  's2s_el', // event label
  's2s_ev', // event value
  's2s_pos', // product position
  's2s_iid', // product id
  's2s_title', // product title
  's2s_to', // destination
  's2s_origin', // origin
  's2s_pc', // product category
  'sf_sid', // ShopFully's session id
  'gclid', // Google Click Id
*/

function* getSfBaseConfig() {
  const { lat, lng } = yield select(selectUserGeolocation)
  const country = yield select(selectCountry)

  const {
    utm_source = 'direct',
    utm_medium = 'sl',
    t: target = 'w',
    gclid,
  } = getQueryParams()

  const flyerId = yield select(selectFlyerId)
  const userId = yield select(selectUserId)
  const sessionId = yield select(selectSessionId)
  const enPropertyId = yield select(selectENPropertyId)

  const campaignName = yield select(selectCampaignName)

  return {
    c: country,
    t: target,
    z: Math.floor((new Date().getTime()) / 1000),
    lat,
    lng,
    fid: flyerId,
    dcid: userId,
    sf_sid: sessionId,
    utm_source,
    utm_medium,
    utm_campaign: campaignName,
    gclid,
    property_id: enPropertyId,
  }
}

export function* prepareTrackingUrl(entries) {
  const baseConfig = yield call(getSfBaseConfig)
  const context = yield select(selectContext)

  const envPrefix = context === 'tiendeo' && !!baseConfig.property_id ? 'EN_' : ''
  const streamfullyPsw = getSecret(`${envPrefix}STREAMFULLY_PASSWORD`)({ context })
  const streamfullyUrl = getSecret(`${envPrefix}STREAMFULLY_URL`)({ context })

  const config = {
    ...defaultConfig,
    ...baseConfig,
    ...entries,
    ...createTrackingMeta(streamfullyPsw),
  }

  const queryString = qs.stringify(config)
  return `${streamfullyUrl}?${queryString}`
}

export function* trackSF(entries) {
  const url = yield call(prepareTrackingUrl, entries)

  try {
    yield call(window.fetch, url)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function* trackSFPost(eventType, at) {
  const baseConfig = yield call(getSfBaseConfig)
  const context = yield select(selectContext)
  const utmc = yield select(selectCampaignName)
  const country = yield select(selectCountry)
  const { lat, lng } = yield select(selectUserGeolocation)
  const viewMode = yield select(selectViewMode)
  const enPropertyId = yield select(selectENPropertyId)
  const fid = yield select(selectFlyerId)
  const uid = yield select(selectUserId)

  const {
    utm_source = 'direct',
    utm_medium = 'sl',
  } = getQueryParams()

  const envPrefix = context === 'tiendeo' && !!baseConfig.property_id ? 'EN_' : ''
  const streamfullyPsw = getSecret(`${envPrefix}STREAMFULLY_PASSWORD`)({ context })
  const splunkUrl = getSecret(`${envPrefix}STREAMFULLY_URL`)({ context })
  const { st, ts, et } = createTrackingMeta(streamfullyPsw)

  const ua = UAParser()
  const { name: brn, version: brv } = ua.browser
  const { name: sn, version: sv } = ua.os

  const eventData = {
    h: {
      brn,
      brv,
      ct: country,
      pid: enPropertyId || '_dc_',
      pn: `s2s_${viewMode}`,
      sn,
      sv,
      tp: 'w',
      uid,
    },
    v: '1.0',
    e: [
      {
        at: {
          utms: utm_source !== 'undefined' ? utm_source : undefined,
          utmm: utm_medium !== 'undefined' ? utm_medium : at?.utmm,
          utmc: utmc !== 'undefined' ? utmc : undefined,
          fid,
          lat,
          lng,
          ...at, // fp, fgid, text,url
        },
        et: eventType,
        ts,
      },
    ],
  }

  return fetch(`${splunkUrl}/?et=${et}&st=${st}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: JSON.stringify(eventData),
  })
}
