import {
  take, all, select, call,
} from 'redux-saga/effects'

import { types as trackingActionTypes } from '../../actions/tracking'
import { selectUserCookiesPreferenceActive, selectArePixelsInitialized } from '../../selectors/tracking'
import { initProvidersWithCookie } from './initProviders'
import { OT_TARGETING_ADS_PREFERENCE, CONSENT_MODE_VALUES } from '../../../helpers/const'
import { initComscorePixel } from './providers/comscore'

/**
 * This method is called every time the user sets the cookies preferences.
 * Is also called as soon as the user opens the webApp ONLY if a preference has already been set
 */
export function* watchCookieConsent() {
  while (true) {
    yield take(trackingActionTypes.SET_COOKIE_PREFERENCES)

    const isTargetingAdsCookieEnable = yield select(
      selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
    )
    const arePixelsInitialzed = yield select(selectArePixelsInitialized)
    const targetingPreference = CONSENT_MODE_VALUES[isTargetingAdsCookieEnable ? 1 : 0]

    try {
      yield call(window.gtag, 'consent', 'update', {
        wait_for_update: 2000,
        ad_storage: targetingPreference,
        ad_user_data: targetingPreference,
        ad_personalization: targetingPreference,
        analytics_storage: CONSENT_MODE_VALUES[1], // always granted
        personalization_storage: CONSENT_MODE_VALUES[0],
        functionality_storage: CONSENT_MODE_VALUES[0],
        security_storage: CONSENT_MODE_VALUES[0],
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Unable to update Consent Mode', err)
    }

    if (isTargetingAdsCookieEnable && !arePixelsInitialzed) {
      yield call(initProvidersWithCookie)
    }
  }
}

export function* watchCookieOnReady() {
  yield all([
    take(trackingActionTypes.SET_COOKIE_READY),
    take(trackingActionTypes.SET_COMSCORE_READY),
  ])
  yield call(initComscorePixel)
}
