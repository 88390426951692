import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import ErrorMessage from './components/ErrorMessage'
import EmptyState from './components/EmptyState'
import Loading from './components/Loading'

import { ReactComponent as ProductNotFound } from '../images/svg/productNotFound_emptyState.svg'
import { ReactComponent as ProductExpired } from '../images/svg/productExpired_emptyState.svg'
import { ERROR_TYPE_NOT_FOUND, ERROR_TYPE_EXPIRED, ERROR_TYPE_ERROR } from '../helpers/const'

const Splash = ({
  type, message, cta, theme,
}) => {
  switch (type) {
    case ERROR_TYPE_ERROR:
      return <ErrorMessage message={message} color={theme.colors.primary} />
    case ERROR_TYPE_NOT_FOUND:
      return (
        <EmptyState
          artwork={<ProductNotFound width={160} height={160} />}
          message={message}
          theme={theme}
        />
      )
    case ERROR_TYPE_EXPIRED:
      return (
        <EmptyState
          artwork={<ProductExpired width={160} height={160} />}
          message={message}
          cta={cta}
          theme={theme}
        />
      )
    default:
      return <Loading />
  }
}

Splash.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  cta: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.string,
    }),
  }).isRequired,
}

Splash.defaultProps = {
  type: '',
  message: '',
  cta: null,
}

export const renderSplash = props => {
  window.errorPage = props.error || props.message
  ReactDOM.render(
    <Splash {...props} />,
    document.getElementById('root'),
  )
}

export default Splash
