import createActionTypes from './createActionTypes'

export const namespace = 'I18N'

export const types = createActionTypes(namespace, [
  'SET_I18N',
])

export const setI18n = i18n => ({
  type: types.SET_I18N,
  payload: { i18n },
})
