import I18nUtils from '@doveconviene/sf-web-js-sdk/i18n/I18nUtils'
import geti18nConstsByContext from '../context/geti18nConstsByContext'
import geti18nLabelsByContext from '../context/geti18nLabelsByContext'

const consts = geti18nConstsByContext()
const labels = geti18nLabelsByContext()

const i18nUtils = new I18nUtils(null, consts, labels)

export default i18nUtils
