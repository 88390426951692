import { captureException } from '@sentry/browser'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import { createBrowserHistory } from 'history'

import initialState from './initialState'
import createRootReducer from './reducers'
import rootSaga from './sagas'
import getQueryParams from '../utils/getQueryParams'

const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    // eslint-disable-next-line no-console
    console.error(error)
    captureException(error)
  },
})

// https://github.com/zalmoxisus/redux-devtools-extension
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const history = createBrowserHistory()
const { debug } = getQueryParams()

const getMiddlewares = () => {
  const baseMiddlewares = [
    routerMiddleware(history),
    sagaMiddleware,
  ]

  if (process.env.REACT_APP_ENV === 'lcl' && debug) {
    // eslint-disable-next-line global-require, import/no-extraneous-dependencies
    const { createLogger } = require('redux-logger')
    const logger = createLogger()
    return applyMiddleware(
      ...baseMiddlewares,
      logger,
    )
  }

  return applyMiddleware(
    ...baseMiddlewares,
  )
}

const configureStore = () => {
  const middlewares = getMiddlewares()
  const store = createStore(createRootReducer(history), initialState, composeEnhancers(middlewares))
  sagaMiddleware.run(rootSaga)
  return store
}

const store = configureStore()

export default store
