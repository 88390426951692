import createActionTypes from './createActionTypes'

export const namespace = 'HOURS'

export const types = createActionTypes(namespace, [
  'SET_HOURS',
  'SET_STORE_HOURS',
])

export const setHours = hours => ({
  type: types.SET_HOURS,
  payload: { hours },
})

export const setStoreHours = (storeId, hours) => ({
  type: types.SET_STORE_HOURS,
  payload: { storeId, hours },
})
