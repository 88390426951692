import {
  take, call, put, spawn, select,
} from 'redux-saga/effects'

import { setCurrentProduct, setCountry } from '../../actions/ux'
import { types as initActionTypes } from '../../actions/init'
import { initTracking } from '../../actions/tracking'

import {
  campaignJob,
  flyerJob,
  retailerJob,
  categoryJob,
  storesJob,
  productsJob,
  userJob,
} from './jobs'

import { validateCampaign } from './helpers/validateCampaign'
import { renderSplash } from '../../../splash/index'
import { CAROUSEL_VIEW_MODE, ERROR_TYPE_ERROR } from '../../../helpers/const'
import { getCampaignDataFromUrl, getProductDataFromUrl } from '../../../helpers/getInfoFromUrl'
import { getCarouselProductPageRoute } from '../../../helpers/getAppRoutes'
import getQueryParams from '../../../utils/getQueryParams'
import { selectCurrentTheme } from '../../selectors/ux'

import { history } from '../..'

// eslint-disable-next-line consistent-return
function* watchInitCampaign() {
  yield take(initActionTypes.INIT_CAMPAIGN)
  const { campaignId, campaignNameId, country } = getCampaignDataFromUrl()
  const { productId } = getProductDataFromUrl()
  const { layout } = getQueryParams()

  if (layout === CAROUSEL_VIEW_MODE) {
    history.replace({
      pathname: getCarouselProductPageRoute(campaignNameId, null),
      search: window.location.search,
    })
  }

  try {
    yield put(setCountry(country))
    yield put(setCurrentProduct(productId))

    const {
      flyerId, hasStores, campaignCategories,
    } = yield call(campaignJob, { country, campaignId, campaignNameId })

    const { retailerId, endDate } = yield call(flyerJob, { flyerId, country })
    const { retailer } = yield call(retailerJob, { retailerId })
    yield call(categoryJob, { categoryId: retailer.category_id })
    yield call(userJob)

    if (hasStores) {
      yield spawn(storesJob, { flyerId, country })
    }

    const { products, productsArray } = yield call(productsJob, {
      flyerId, campaignCategories, productId,
    })

    yield call(validateCampaign, {
      flyerId,
      products,
      productsArray,
      campaignNameId,
      productId,
      endDate,
      categoryId: retailer.category_id,
    })
    yield put(initTracking())
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)

    const theme = yield select(selectCurrentTheme)
    return renderSplash({
      type: ERROR_TYPE_ERROR,
      message: 'Campaign Initialization Error',
      theme,
    })
  }
}

export default watchInitCampaign
