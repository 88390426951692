import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'
import { NOT_AVAILABLE } from '../../../../helpers/const'

function* viewedVideo(action) {
  const { params } = action.payload
  const {
    id,
    title,
    position,
    step,
    seeked,
  } = params

  const productTitle = title || NOT_AVAILABLE

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'vv',
    s2s_ec: 'i',
    s2s_el: seeked ? 'ff' : 'noff',
    s2s_ev: step.toString(),
    s2s_pos: position,
    s2s_iid: id,
    s2s_title: productTitle,
  })
}

export default viewedVideo
