import { getSecret } from '../utils/i18n/secret'
import { getCampaignDataFromUrl } from '../helpers/getInfoFromUrl'

const { country } = getCampaignDataFromUrl()
const baseUrl = getSecret('API_BASEURL')()
const version = getSecret('API_VERSION')()

export const urlOptions = {
  baseUrl: `${baseUrl}/${version}/${country.toLowerCase()}`,
  key: getSecret('API_KEY')({ country }),
}

export const fetchParams = {
  headers: {
    Authorization: `Basic ${btoa(getSecret('API_AUTH')())}`,
  },
}
