import { types as actionTypes } from '../actions/retailers'
import initialState from '../initialState/retailers'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RETAILER: {
      const { id, retailer } = action.payload

      return {
        ...state,
        [id]: {
          ...state[id],
          assets: {
            ...state.assets,
          },
          ...retailer,
        },
      }
    }
    case actionTypes.SET_RETAILER_ASSETS: {
      const { id, assets } = action.payload

      return {
        ...state,
        [id]: {
          ...state[id],
          assets: {
            ...state.assets,
            ...assets,
          },
        },
      }
    }
    default:
      return state
  }
}
