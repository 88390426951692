import { types as actionTypes } from '../actions/stores'
import initialState from '../initialState/stores'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STORES: {
      const { stores } = action.payload
      return {
        ...stores,
      }
    }
    default:
      return state
  }
}
