import { put } from 'redux-saga/effects'

import { setCurrentCategories } from '../../actions/ux'
import { track as trackEvent } from '../../actions/tracking'

function* onCategoriesDialogApllyClick(action) {
  const { params: { categories } } = action.payload

  yield put(setCurrentCategories(categories))
  yield put(trackEvent({
    event: 'filterSelected',
    params: {
      categories,
    },
  }))
}

export default onCategoriesDialogApllyClick
