import { call, select } from 'redux-saga/effects'

import {
  trackGAW,
  trackGAWR,
  trackFBQ,
} from '../providers'
import { selectFlyerId, selectCategorySlug, selectCampaignRetailerSlug } from '../../../selectors/campaign'

function* offerOpenWithCookie() {
  const flyerId = yield select(selectFlyerId)
  const retailerSlug = yield select(selectCampaignRetailerSlug)
  const categorySlug = yield select(selectCategorySlug)

  // Facebook pixel
  yield call(trackFBQ, 'track', 'ViewContent', {
    content_ids: flyerId,
    content_type: 'product',
  })

  // Facebook event viewer
  yield call(trackFBQ, 'trackCustom', 'FBS2SEventViewer', {
    flyer_id: flyerId,
    category_slug: categorySlug,
    retailer_slug: retailerSlug,
  })

  // Facebook Pixel
  yield call(trackFBQ, 'track', 'PageView')

  // Google AdWords
  yield call(trackGAW)

  // Google AdWords - Remarketing
  yield call(trackGAWR)
}

export default offerOpenWithCookie
