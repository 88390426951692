import PropTypes from 'prop-types'

import styled from '@emotion/styled'

const StyledRoot = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const StyledMessage = styled.p({
  marginTop: '24px',
  fontFamily: 'Nunito, Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: '14px',
  fontWeight: '600',
  textAlign: 'center',
})

const StyledCta = styled.a(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  color: theme.colors.mid_grey,
  backgroundColor: theme.colors.light_grey,
  textAlign: 'center',
  border: 'none',
  borderRadius: theme.spaces.default / 2,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  textDecoration: 'none',
  fontFamily: theme.fonts.primary,
  fontSize: theme.fontSize * 0.75,
  fontWeight: '700',
  lineHeight: `${theme.fontSize}px`,
  padding: theme.spaces.default,
  textTransform: 'uppercase',
}))

const EmptyState = ({
  artwork, message, cta, theme,
}) => (
  <StyledRoot>
    {artwork}
    <StyledMessage>
      {message}
    </StyledMessage>
    {
      cta ? (
        <StyledCta target="_blank" href={cta?.value} theme={theme}>
          {cta?.text}
        </StyledCta>
      ) : null
    }
  </StyledRoot>
)

EmptyState.propTypes = {
  artwork: PropTypes.element,
  message: PropTypes.string,
  cta: PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  }),
  theme: PropTypes.shape({}).isRequired,
}

EmptyState.defaultProps = {
  artwork: null,
  message: '',
  cta: null,
}

export default EmptyState
