export const loadScript = (src, done) => {
  const js = document.createElement('script')
  js.src = src
  js.onload = function onload() {
    done()
  }
  js.onerror = function onerror() {
    done(new Error(`Failed to load script ${src}`))
  }
  document.head.appendChild(js)
}
