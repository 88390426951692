import { select, put } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'
import { selectProducts, selectCollectionProductsList } from '../../selectors/products'
import { ORIGIN_LANDING } from '../../../helpers/const'

function* onProductsGridItemVisible(action) {
  const { params: { productId, category, position } } = action.payload

  const product = yield select(selectProduct(productId))
  const products = yield select(selectProducts)
  const total = (yield select(selectCollectionProductsList) || []).length

  const isLastProduct = position === total

  yield put(trackEvent({
    event: 'viewProductInList',
    params: {
      id: product.id,
      title: product.title,
      position,
      category,
    },
  }))

  if (isLastProduct) {
    yield put(trackEvent({
      event: 'viewLastProduct',
      params: {
        origin: ORIGIN_LANDING,
        lastProductId: products[products.length - 1].id,
      },
    }))
  }
}

export default onProductsGridItemVisible
