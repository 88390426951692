const manipulateCategories = (categoryList, productsCategories) => {
  if (!categoryList || categoryList?.length === 0) return productsCategories

  const categories = new Set()
  for (const category of categoryList) {
    const hasCategoryProducts = productsCategories.includes(category)
    if (hasCategoryProducts) {
      categories.add(category)
    }
  }
  return Array.from(categories)
}

export default manipulateCategories
