import { call, put, select } from 'redux-saga/effects'

import getQueryParams from '../../../../utils/getQueryParams'
import { getCurrentDate } from '../../../../helpers/getCurrentDate'
import {
  setCurrentCategories, setHasTitles, setHasCategories, setCurrentCollectionIndex,
} from '../../../actions/ux'
import { setProducts } from '../../../actions/products'
import manipulateCategories from '../../../manipulators/2.0/categories'
import manipulateProducts from '../../../manipulators/2.0/products'
import { fetchFlyerGibs } from '../../../../api/products/fetchFlyerGibs'
import { setCategories } from '../../../actions/categories'
import { getCampaignDataFromUrl } from '../../../../helpers/getInfoFromUrl'
import { ERROR_TYPE_ERROR } from '../../../../helpers/const'
import { renderSplash } from '../../../../splash/index'
import groupProductsByCategory from '../../../manipulators/2.0/groupProductsByCategory'
import { setCategorizedProducts } from '../../../actions/categorizedProducts'
import { selectCollectionProductsList } from '../../../selectors/products'
import { selectLabel } from '../../../selectors/i18n'
import { selectCurrentTheme } from '../../../selectors/ux'

// eslint-disable-next-line consistent-return
export function* productsJob({ flyerId, campaignCategories, productId }) {
  try {
    const { preview, category, favourites: favouritesProducts } = getQueryParams()
    const { country } = getCampaignDataFromUrl()
    const currentDate = getCurrentDate()
    const isPreview = preview === 'true'
    const favouriteProductIds = favouritesProducts ? favouritesProducts.split(',') : []

    const productsArray = (yield call(fetchFlyerGibs, { country, flyerId })) || {}

    const {
      products,
      productCategories,
      hasTitles,
    } = manipulateProducts(productsArray, currentDate, isPreview, favouriteProductIds)

    yield put(setProducts(products))

    const otherDealsLabel = yield select(selectLabel('otherDeals'))
    const categorizedProducts = groupProductsByCategory(
      Object.values(products),
      otherDealsLabel,
    )

    yield put(setCategorizedProducts(categorizedProducts))
    yield put(setHasTitles(hasTitles))
    yield put(setHasCategories(!!(productCategories.length)))

    /*
      Needed to set the initial value for currentCollectionIndex when we land
      on ProductsImageSwiper. Otherwise we cannot keep track of Swiper's slides
      initial index based on categorized products list.
    */
    if (productId) {
      const collectionProductsList = yield select(selectCollectionProductsList)
      const currentCollectionIndex = collectionProductsList
        .filter(product => product.id === productId)?.[0]?.collectionIndex
      yield put(setCurrentCollectionIndex(currentCollectionIndex))
    }

    const categories = manipulateCategories(campaignCategories, productCategories)
    yield put(setCategories(productCategories))

    if (category) {
      const queryStringCategory = category.replace(/"/g, '')
      const filteredCategory = categories.find(el => (
        el.toUpperCase() === queryStringCategory.toUpperCase()
      ))

      if (filteredCategory) {
        yield put(setCurrentCategories([filteredCategory]))
      }
    }
    return { products, productsArray }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    const theme = yield select(selectCurrentTheme)
    return renderSplash({
      type: ERROR_TYPE_ERROR,
      message: 'Products Error',
      theme,
    })
  }
}
