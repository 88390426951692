import { call, select } from 'redux-saga/effects'
import { selectUserCookiesPreferenceActive, selectIsPreferenceExpressed } from '../../../selectors/tracking'
import { selectCountry, selectContext } from '../../../selectors/ux'
import { setComscoreReady } from '../../../actions/tracking'
import store from '../../..'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../../helpers/const'
import { getSecretComscore } from '../../../../utils/i18n/secret'

export function* initComscorePixel() {
  const country = yield select(selectCountry)
  const context = yield select(selectContext)
  const comscoreIds = getSecretComscore({ country, context }) || {}
  const isPreferenceExpressed = yield select(selectIsPreferenceExpressed)
  const isTargetingEnabled = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )
  const adsTargeting = isTargetingEnabled ? 1 : 0
  const ucfr = isPreferenceExpressed ? adsTargeting : ''

  if (typeof window?.COMSCORE?.beacon !== 'function' || !comscoreIds.c1 || !comscoreIds.c2) {
    // eslint-disable-next-line no-console
    console.debug('error', 'Cannot init Comscore Pixel')
    return
  }

  try {
    yield call(window.COMSCORE.beacon, {
      c1: comscoreIds.c1,
      c2: comscoreIds.c2,
      cs_ucfr: ucfr,
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

function onLoadComscore() {
  store.dispatch(setComscoreReady())
}

export function* initComscoreScript() {
  const country = yield select(selectCountry)
  const context = yield select(selectContext)
  const comscoreIds = getSecretComscore({ country, context }) || {}

  if (!comscoreIds.c2) {
    // eslint-disable-next-line no-console
    console.debug('error', 'Cannot init Comscore script')
    return
  }

  const script = document.createElement('script')
  script.src = `https://sb.scorecardresearch.com/cs/${comscoreIds.c2}/beacon.js`
  script.async = true
  script.onload = onLoadComscore

  document.getElementsByTagName('body')[0].appendChild(script)
}
