import createActionTypes from './createActionTypes'

export const namespace = 'USER'

export const types = createActionTypes(namespace, [
  'SET_ID',
  'SET_SID',
  'SET_GEOLOCATION',
  'SET_USER_NEAREST_STORE_ID',
])

export const setUserId = id => ({
  type: types.SET_ID,
  payload: { id },
})

export const setSessionId = sid => ({
  type: types.SET_SID,
  payload: { sid },
})

export const setUserGeolocation = geolocation => ({
  type: types.SET_GEOLOCATION,
  payload: { geolocation },
})

export const setUserNearestStoreId = storeId => ({
  type: types.SET_USER_NEAREST_STORE_ID,
  payload: { storeId },
})
