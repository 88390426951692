import { types as actionTypes } from '../actions/categorizedProducts'
import initialState from '../initialState/categorizedProducts'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CATEGORIZED_PRODUCTS: {
      const { products } = action.payload
      return {
        ...state,
        products: [...products],
      }
    }
    default:
      return state
  }
}
