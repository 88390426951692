import get from 'lodash.get'

const BASE_POLYFILL_URL = 'https://polyfill.io/v3/polyfill.js'

const BROWSER_FEATURES = [
  'Number.isNaN',
  'Number.parseInt',
  'Number.isInteger',
  'String.prototype.includes',
  'String.prototype.repeat',
  'Array.prototype.find',
  'Array.prototype.entries',
  'Array.prototype.includes',
  'Array.prototype.findIndex',
  'Array.from',
  'Object.assign',
  'Object.values',
  'Object.entries',
  'Promise',
  'fetch',
  'Symbol',
  'Symbol.for',
  'Symbol.asyncIterator',
  'IntersectionObserver',
  'CustomEvent',
  'Math.trunc',
  'URL',
  'WeakMap',
]

const BROWSER_FEATURES_IMPORT_LIBRARIES = {
  IntersectionObserver: () => import('intersection-observer'),
}

export const getPolyFillUrl = () => {
  const featureList = BROWSER_FEATURES.reduce((acc, val) => `${acc},${val}`, '')
  return `${BASE_POLYFILL_URL}?features=${featureList}`
}

export const getBrowserDisabledFeatures = async done => {
  const keys = Object.keys(BROWSER_FEATURES_IMPORT_LIBRARIES)
  for (let index = 0; index < keys.length; index += 1) {
    const key = keys[index]
    if (!get(window, key)) {
      const importLibraryFn = BROWSER_FEATURES_IMPORT_LIBRARIES[key]
      // eslint-disable-next-line no-await-in-loop
      if (importLibraryFn) await importLibraryFn()
    }
  }
  done()
}

export const isBrowserUnsupported = () => BROWSER_FEATURES.some(val => !get(window, val))
