import { all, fork } from 'redux-saga/effects'

import takeEveryUxEvent from '../../helpers/takeEveryUxEvent'

import watchLocationChange from './watchLocationChange'

// APP UX EVENTS
import onCustomerPixelFired from './onCustomerPixelFired'

// HEADERS UX EVENTS
import onBrandHeaderLogoClick from './onBrandHeaderLogoClick'
import onBrandHeaderBackClick from './onBrandHeaderBackClick'
import onCampaignHeaderLogoClick from './onCampaignHeaderLogoClick'
import onSubHeaderCTAClick from './onSubHeaderCTAClick'

// HOMEPAGE UX EVENTS
import onNearestStoreMapClick from './onNearestStoreMapClick'
import onHeroSectionClick from './onHeroSectionClick'
import onCategoryListScrollerItemClick from './onCategoryListScrollerItemClick'
import onCategoriesFilterApplyClick from './onCategoriesFilterApplyClick'
import onProductsSearchType from './onProductsSearchType'
import onProductsGridItemClick from './onProductsGridItemClick'
import onProductsGridItemVisible from './onProductsGridItemVisible'

// PRODUCT UX EVENTS
import onStoresCtaClick from './onStoresCtaClick'
import onStoresListToggle from './onStoresListToggle'
import onProductStorePhoneClick from './onProductStorePhoneClick'
import onProductStoreMapClick from './onProductStoreMapClick'
import onProducInfoCtaClick from './onProducInfoCtaClick'
import onFavouriteProductCtaClick from './onFavouriteProductCtaClick'
import onProductCallCtaClick from './onProductCallCtaClick'
import onProductVideoStart from './onProductVideoStart'
import onProductVideoStep from './onProductVideoStep'
import onProductVideoEnterFullscreen from './onProductVideoEnterFullscreen'
import onProductDescriptionSeeMoreClick from './onProductDescriptionSeeMoreClick'
import onProductBannerClick from './onProductBannerClick'

import onProductInfoDrawerOpen from './onProductInfoDrawerOpen'
import onProductInfoDrawerClose from './onProductInfoDrawerClose'
import onProductInfoDrawerSetMode from './onProductInfoDrawerSetMode'

// PRODUCT SWIPER UX EVENTS
import onProductSwipeLeft from './onProductSwipeLeft'
import onProductSwipeRight from './onProductSwipeRight'

import onShowAllSimilarProductsCtaClick from './onShowAllSimilarProductsCtaClick'
import onSimilarProductCarouselClick from './onSimilarProductCarouselClick'
import onSimilarProductCarouselVisible from './onSimilarProductCarouselVisible'
import { CTAS_TYPES } from '../../../helpers/const'

function* uxSagas() {
  yield all([
    fork(watchLocationChange),

    // APP UX EVENTS
    takeEveryUxEvent(onCustomerPixelFired, { name: 'impression', target: 'App/CustomerPixel' }),

    // HEADERS UX EVENTS
    takeEveryUxEvent(onBrandHeaderLogoClick, { name: 'click', target: 'BrandHeader/Logo' }),
    takeEveryUxEvent(onBrandHeaderBackClick, { name: 'click', target: 'BrandHeader/Back' }),
    takeEveryUxEvent(onCampaignHeaderLogoClick, { name: 'click', target: 'CampaignHeader/Logo' }),
    takeEveryUxEvent(onSubHeaderCTAClick, { name: 'click', target: 'SubHeader/CTA' }),

    // HOMEPAGE UX EVENTS
    takeEveryUxEvent(onNearestStoreMapClick, { name: 'click', target: 'NearestStoreMap' }),
    takeEveryUxEvent(onHeroSectionClick, { name: 'click', target: 'HeroSection' }),
    takeEveryUxEvent(onCategoryListScrollerItemClick, { name: 'click', target: 'CategoryListScroller/Item' }),
    takeEveryUxEvent(onCategoriesFilterApplyClick, { name: 'click', target: 'CategoriesFilter/Apply' }),
    takeEveryUxEvent(onProductsSearchType, { name: 'type', target: 'ProductsSearch' }),
    takeEveryUxEvent(onProductsGridItemClick, { name: 'click', target: 'ProductsGridCategorized/Item' }),
    takeEveryUxEvent(onProductsGridItemVisible, { name: 'visible', target: 'ProductsGridCategorized/Item' }),

    // PRODUCTPAGE UX EVENTS
    takeEveryUxEvent(onStoresCtaClick, { name: 'click', target: 'StoreInfo/StoresCta' }),
    takeEveryUxEvent(onProductStorePhoneClick, { name: 'click', target: 'StoreInfo/Phone' }),
    takeEveryUxEvent(onProductStoreMapClick, { name: 'click', target: 'StoreInfo/Map' }),
    takeEveryUxEvent(onProducInfoCtaClick, { name: 'click', target: `ProductDetail/${CTAS_TYPES.ProductCta}` }), // il target viene creato dinamicamente
    takeEveryUxEvent(onFavouriteProductCtaClick, { name: 'click', target: `ProductDetail/${CTAS_TYPES.FavouriteCta}` }), // il target viene creato dinamicamente
    takeEveryUxEvent(onProductCallCtaClick, { name: 'click', target: `ProductDetail/${CTAS_TYPES.CallCta}` }), // il target viene creato dinamicamente
    takeEveryUxEvent(onProductVideoStart, { name: 'click', target: 'ProductDetail/VideoStart' }),
    takeEveryUxEvent(onProductVideoStep, { name: 'step', target: 'ProductDetail/VideoStep' }),
    takeEveryUxEvent(onProductVideoEnterFullscreen, { name: 'click', target: 'ProductDetail/EnterVideoFullscreen' }),
    takeEveryUxEvent(onProductDescriptionSeeMoreClick, { name: 'click', target: 'ProductDetail/SeeMore' }),
    takeEveryUxEvent(onProductBannerClick, { name: 'click', target: 'StoreInfo/Banner' }),
    takeEveryUxEvent(onStoresListToggle, { name: 'toggle', target: 'StoreInfo/StoresList' }),

    // PRODUCT INFO DRAWER UX EVENTS
    takeEveryUxEvent(onProductInfoDrawerOpen, { name: 'toggle', target: 'ProductInfoDrawer/Open' }),
    takeEveryUxEvent(onProductInfoDrawerClose, { name: 'toggle', target: 'ProductInfoDrawer/Close' }),
    takeEveryUxEvent(onProductInfoDrawerSetMode, { name: 'toggle', target: 'ProductInfoDrawer/Mode' }),

    // PRODUCT SWIPER UX EVENTS
    takeEveryUxEvent(onProductSwipeLeft, { name: 'swipeleft', target: 'ProductSwiper' }),
    takeEveryUxEvent(onProductSwipeRight, { name: 'swiperight', target: 'ProductSwiper' }),

    // SIMILAR PRODUCT CAROUSEL
    takeEveryUxEvent(onShowAllSimilarProductsCtaClick, { name: 'click', target: 'ProductDetail/SeeAllSimilarProducts' }),
    takeEveryUxEvent(onSimilarProductCarouselClick, { name: 'click', target: 'SimilarProductsCarousel/Item' }),
    takeEveryUxEvent(onSimilarProductCarouselVisible, { name: 'visible', target: 'SimilarProductsCarousel/Item' }),
  ])
}

export default uxSagas
