const colors = {
  dark_grey: '#282832',
  mid_grey: '#777794',
  grey: '#AEAEC0',
  light_grey: '#EBEBF5',
  lighter_grey: '#F7F7FB',
  green: '#A1CB5B',
  yellow: '#FFD833',
  orange: '#F5813F',
  purple: '#B090C1',
  turquoise: '#59C3C4',
  black: '#000000',
  white: '#FFFFFF',
  facebook: '#3B5998',
  twitter: '#00ACED',
  google_plus: '#DD4B39',
  transparent: 'transparent',
}
const breakpoints = {
  xsmall: '20em',
  small: '34em',
  medium: '48em',
  large: '62em',
  xlarge: '75em',
  xxlarge: '100em',
}
const mediaSizes = {
  desktop: {
    height: '450px',
  },
  mobile: {
    height: '252px',
  },
  carousel: {
    desktop: {
      height: '550px',
    },
    mobile: {
      height: '280px',
    },
  },
}
const fontSize = 16
const categoryBannerFontSizes = {
  desktop: 40,
  tablet: 32,
  mobile: 24,
}
const fontWeight = 400
const lineHeight = 22
const twoRowsLimit = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  lineClamp: '2',
  WebkitBoxOrient: 'vertical',
}
const advConf = {
  sizeMapping: {
    desktop: {
      viewport: [1024, 768],
      sizes: [
        [990, 250],
        [980, 250],
        [970, 250],
        [970, 90],
        [980, 90],
        'fluid',
        [1, 1],
      ],
    },
    mobile: {
      viewport: [640, 480],
      sizes: [
        [728, 90],
        'fluid',
        [1, 1],
      ],
    },
    default: {
      viewport: [0, 0],
      sizes: [
        [320, 50],
        [320, 100],
        [300, 250],
        [300, 100],
        'fluid',
        [1, 1],
      ],
    },
  },
}
const fonts = {
  montserrat: 'Montserrat, Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif',
  nunito: 'Nunito, Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif',
  openSans: '"Open Sans", sans-serif',
  helvetica: 'Helvetica,Arial,sans-serif',
  roboto: 'Roboto,sans-serif',
}
const spaces = {
  default: 16,
  large: 16 * 1.5,
}

const commons = {
  spaces,
  breakpoints,
  mediaSizes,
  fontSize,
  categoryBannerFontSizes,
  fontWeight,
  lineHeight,
  twoRowsLimit,
  advConf,
}

const dvc = {
  colors: {
    ...colors,
    primary: '#D1233E',
  },
  fonts: {
    ...fonts,
    primary: fonts.nunito,
  },
  ...commons,
}

const tiendeo = {
  colors: {
    ...colors,
    primary: '#D2312E',
  },
  fonts: {
    ...fonts,
    primary: fonts.openSans,
  },
  ...commons,
}

const promoqui = {
  colors: {
    ...colors,
    primary: '#8bc34a',
  },
  fonts: {
    ...fonts,
    primary: fonts.roboto,
  },
  ...commons,
}

const volantinofacile = {
  colors: {
    ...colors,
    primary: '#d53933',
  },
  fonts: {
    ...fonts,
    primary: fonts.helvetica,
  },
  ...commons,
}

export default {
  default: dvc, dvc, tiendeo, promoqui, volantinofacile,
}
