import { types as actionTypes } from '../actions/products'
import initialState from '../initialState/products'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS: {
      const { products } = action.payload
      return { ...products }
    }

    case actionTypes.TOGGLE_FAVOURITES: {
      const { productId } = action.payload

      return {
        ...state,
        [productId]: {
          ...state[productId],
          isFavouriteProduct: !state[productId].isFavouriteProduct,
        },
      }
    }
    default:
      return state
  }
}
