import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'

function* viewLastProduct(action) {
  const { params } = action.payload
  const { origin, lastProductId } = params

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'vl',
    s2s_ec: 'i',
    s2s_iid: lastProductId,
    s2s_origin: origin,
  })
}

export default viewLastProduct
