/* eslint-disable no-cond-assign */
/* eslint-disable no-param-reassign */
import { init as sentryInit } from '@sentry/browser'
import { getSecret } from '../utils/i18n/secret'
import getQueryParams from '../utils/getQueryParams'

const environment = getSecret('ENV')()
const { debug } = getQueryParams()

const isErrorTrackingEnabled = (
  environment === 'prod'
  || (environment === 'stg' && debug)
  || (environment === 'dev' && debug)
  || (environment === 'lcl' && debug)
)

const loadCssErrorRegExp = /Loading CSS chunk [\d]+ failed/
const loadChunkErrorRegExp = /Loading chunk [\d]+ failed./
const excludeBreadcrumbEvents = [
  'fetch', 'xhr',
]

const findTargetAncestor = el => {
  while (
    (el = el.parentNode)
    && !el?.dataset?.test
  );
  return el
}

/**
 * Blacklisting "Loading CSS chunk" caused by slow network conditions
 */
export const errorTrackingInit = () => {
  if (isErrorTrackingEnabled) {
    sentryInit({
      environment,
      dsn: getSecret('SENTRY_TRACKING_DNS')(),
      release: getSecret('VERSION')(),
      autoSessionTracking: false,
      debug: environment === 'lcl',

      denyUrls: ['cdn.iubenda.com'],
      ignoreErrors: [
        'Invalid LngLat object: (NaN, NaN)',
        loadCssErrorRegExp,
        loadChunkErrorRegExp,
      ],
      beforeBreadcrumb(breadcrumb, hint) {
        if (
          hint?.level === 'log'
          || excludeBreadcrumbEvents.includes(breadcrumb?.category)
        ) {
          return null
        }

        if (hint?.event?.target) {
          const ancestor = findTargetAncestor(hint.event.target)

          if (ancestor?.dataset?.test) {
            breadcrumb.message += ` >> ${ancestor.dataset.test}`
          }
        }

        return breadcrumb
      },
      beforeSend(event, hint) {
        const error = hint?.originalException

        if (
          loadCssErrorRegExp.test(error?.message)
          || loadChunkErrorRegExp.test(error?.message)
        ) {
          return null
        }

        return event
      },
    })
  }
}
