import { put, call } from 'redux-saga/effects'

import { setRetailerAssets } from '../../../../actions/retailers'
import { fetchRetailerAssets } from '../../../../../api/retailers/fetchRetailerAssets'

const manipulateAssets = assets => {
  const logoAsset = assets.find(asset => asset.name === 'logo_segnalino')
  const coverAsset = assets.find(asset => asset.name === 'logo_transparent')
  const pinAsset = assets.find(asset => asset.name === 'logo_segnalino_web')

  return {
    logo: logoAsset ? logoAsset.url : '',
    cover: coverAsset ? coverAsset.url : '',
    pin: pinAsset ? pinAsset.url : '',
  }
}

function* getRetailerAssets(action) {
  const { retailerId, country } = action.payload
  try {
    const retailerAssets = (yield call(fetchRetailerAssets, { country, retailerId })) || {}
    const assets = manipulateAssets(retailerAssets)

    yield put(setRetailerAssets(retailerId, assets))
  } catch (error) { console.error(error) } // eslint-disable-line no-console
}

export default getRetailerAssets
