import callcenterHours from './callcenterHours'
import categories from './categories'
import defaultStoreHours from './defaultStoreHours'
import i18n from './i18n'
import layout from './layout'
import products from './products'
import categorizedProducts from './categorizedProducts'
import retailers from './retailers'
import stores from './stores'
import tracking from './tracking'
import user from './user'
import ux from './ux'

export default {
  callcenterHours,
  categories,
  defaultStoreHours,
  i18n,
  layout,
  products,
  categorizedProducts,
  retailers,
  stores,
  tracking,
  user,
  ux,
}
