import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'

function* noStoresClick(action) {
  const { params } = action.payload
  const { position, id, title } = params

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'nomap',
    s2s_ec: 'c',
    s2s_el: `Position${position}-${id}-${title}`,
    s2s_pos: position,
    s2s_iid: id,
    s2s_title: title,
  })
}

export default noStoresClick
