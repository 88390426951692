export const getSecret = key => ({ country, context } = {}) => {
  const parsedCountry = country ? `_${country.toUpperCase()}` : ''
  const parsedContext = context ? `_${context.toUpperCase()}` : ''
  return process.env[`REACT_APP${parsedContext}_${key}${parsedCountry}`]
}

export const getSecretGaw = ({ country, context }) => ({
  conversionID: getSecret('GAW_CONVERSION_ID')({ country, context }),
  conversionLabel: getSecret('GAW_CONVERSION_LABEL')({ country, context }),
  remarketingConversionID: getSecret('GAW_REMARKETING_CONVERSION_ID')({ context }),
  remarketingConversionLabel: getSecret('GAW_REMARKETING_CONVERSION_LABEL')({ context }),
})

export const getSecretComscore = ({ country, context }) => ({
  c1: getSecret('COMSCORE_C1')({ country, context }),
  c2: getSecret('COMSCORE_C2')({ country, context }),
})

export const getSecretFeatureToggle = key => country => (
  Boolean(Number.parseInt(getSecret(key)({ country }), 10))
)
