import createActionTypes from './createActionTypes'

export const namespace = 'RETAILERS'

export const types = createActionTypes(namespace, [
  'SET_RETAILER',
  'SET_RETAILER_ASSETS',
])

export const setRetailer = (id, retailer) => ({
  type: types.SET_RETAILER,
  payload: { id, retailer },
})

export const setRetailerAssets = (id, assets) => ({
  type: types.SET_RETAILER_ASSETS,
  payload: { id, assets },
})
