import {
  ERROR_TYPE_EXPIRED,
  ERROR_TYPE_NOT_FOUND,
} from '../../../../helpers/const'

export const validateProduct = (products, productsArray, carouselPid) => {
  const validProductsLength = Object.values(products).length
  const apiProductsLength = productsArray.length

  if (apiProductsLength <= 0) return ERROR_TYPE_NOT_FOUND
  if (validProductsLength <= 0 && apiProductsLength >= 0) return ERROR_TYPE_EXPIRED
  if (!!carouselPid && !products[carouselPid]) return ERROR_TYPE_NOT_FOUND

  return true
}
