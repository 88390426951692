import { select, put } from 'redux-saga/effects'

import { getRetailerAssets, getRetailerSlug } from '../../../../actions/api'
import { selectRetailerLogo, selectRetailerSlug } from '../../../../selectors/retailers'
import { selectStore } from '../../../../selectors/stores'

function* getStoreRetailer(action) {
  const { storeId } = action.payload
  try {
    const storeRetailerId = (yield select(selectStore(storeId)))?.retailer_id
    const retailerSlug = yield select(selectRetailerSlug(storeRetailerId))
    const retailerLogo = yield select(selectRetailerLogo(storeRetailerId))

    if (!retailerSlug) {
      yield put(getRetailerSlug(storeRetailerId))
    }

    if (!retailerLogo) {
      yield put(getRetailerAssets(storeRetailerId))
    }
  } catch (error) { console.error(error) } // eslint-disable-line no-console
}

export default getStoreRetailer
