import { all, fork } from 'redux-saga/effects'

import watchInitTracking from './watchInitTracking'
import { watchCookieConsent, watchCookieOnReady } from './watchCookieConsent'
import { watchGClidOnCookiePreferences, watchGClidOnInitialized } from './watchGClidEvent'

import takeEveryTrackingEvent from '../../helpers/takeEveryTrackingEvent'

import trackingFilterSelected from './events/filterSelected'
import call from './events/call'
import callProduct from './events/callProduct'
import clientPixelTracked from './events/clientPixelTracked'
import closeDetail from './events/closeDetail'
import openDetail from './events/openDetail'
import offerOpen from './events/offerOpen'
import goTo from './events/goTo'
import navigateTo from './events/navigateTo'
import noStoresClick from './events/noStoresClick'
import openAllStores from './events/openAllStores'
import openRetailerPage from './events/openRetailerPage'
import offerOpenWithCookie from './events/offerOpenWithCookie'
import productInfo from './events/productInfo'
import favourite from './events/favourite'
import showDescription from './events/showDescription'
import startVideo from './events/startVideo'
import swipeleft from './events/swipeleft'
import swiperight from './events/swiperight'
import toggleView from './events/toggleView'
import viewLastProduct from './events/viewLastProduct'
import viewProductCarousel from './events/viewProductCarousel'
import viewProductInList from './events/viewProductInList'
import viewedVideo from './events/viewedVideo'
import heroSection from './events/heroSection'
import viewAllSimilarProduct from './events/viewAllSimilarProduct'
import viewProductSimilarProduct from './events/viewProductSimilarProduct'
import swipeProductDrawer from './events/swipeProductDrawer'
import toggleProductDrawer from './events/toggleProductDrawer'

function* trackingSagas() {
  yield all([
    fork(watchInitTracking),
    fork(watchCookieOnReady),
    fork(watchCookieConsent),
    fork(watchGClidOnCookiePreferences),
    fork(watchGClidOnInitialized),
    takeEveryTrackingEvent(trackingFilterSelected, { event: 'filterSelected' }),
    takeEveryTrackingEvent(call, { event: 'call' }),
    takeEveryTrackingEvent(callProduct, { event: 'callProduct' }),
    takeEveryTrackingEvent(clientPixelTracked, { event: 'clientPixelTracked' }),
    takeEveryTrackingEvent(closeDetail, { event: 'closeDetail' }),
    takeEveryTrackingEvent(openDetail, { event: 'openDetail' }),
    takeEveryTrackingEvent(offerOpen, { event: 'offerOpen' }),
    takeEveryTrackingEvent(goTo, { event: 'goTo' }),
    takeEveryTrackingEvent(navigateTo, { event: 'navigateTo' }),
    takeEveryTrackingEvent(noStoresClick, { event: 'noStoresClick' }),
    takeEveryTrackingEvent(openAllStores, { event: 'openAllStores' }),
    takeEveryTrackingEvent(openRetailerPage, { event: 'openRetailerPage' }),
    takeEveryTrackingEvent(offerOpenWithCookie, { event: 'offerOpenWithCookie' }),
    takeEveryTrackingEvent(productInfo, { event: 'productInfo' }),
    takeEveryTrackingEvent(favourite, { event: 'favourite' }),
    takeEveryTrackingEvent(showDescription, { event: 'showDescription' }),
    takeEveryTrackingEvent(startVideo, { event: 'startVideo' }),
    takeEveryTrackingEvent(swipeleft, { event: 'swipeleft' }),
    takeEveryTrackingEvent(swiperight, { event: 'swiperight' }),
    takeEveryTrackingEvent(toggleView, { event: 'toggleView' }),
    takeEveryTrackingEvent(viewLastProduct, { event: 'viewLastProduct' }),
    takeEveryTrackingEvent(viewProductCarousel, { event: 'viewProductCarousel' }),
    takeEveryTrackingEvent(viewProductInList, { event: 'viewProductInList' }),
    takeEveryTrackingEvent(viewedVideo, { event: 'viewedVideo' }),
    takeEveryTrackingEvent(heroSection, { event: 'heroSection' }),
    takeEveryTrackingEvent(viewAllSimilarProduct, { event: 'viewAllSimilarProduct' }),
    takeEveryTrackingEvent(viewProductSimilarProduct, { event: 'viewProductSimilarProduct' }),
    takeEveryTrackingEvent(swipeProductDrawer, { event: 'swipeProductDrawer' }),
    takeEveryTrackingEvent(toggleProductDrawer, { event: 'toggleProductDrawer' }),
  ])
}

export default trackingSagas
