import createActionTypes from './createActionTypes'

export const namespace = 'CALLCENTER_HOURS'

export const types = createActionTypes(namespace, [
  'SET_CALLCENTER_HOURS',
])

export const setCallcenterHours = callcenterHours => ({
  type: types.SET_CALLCENTER_HOURS,
  payload: { callcenterHours },
})
