import { put } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'

function* onCustomerPixelFired(action) {
  const { params } = action.payload

  if (params?.customerImpressionTagUrl) {
    yield put(trackEvent({
      event: 'clientPixelTracked',
      params: {
        eventLabel: params.customerImpressionTagUrl,
        eventValue: 1,
      },
    }))
  }
}

export default onCustomerPixelFired
