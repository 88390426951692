import { CAMPAIGN_NAME_ID_REGEX, PRODUCT_ID_REGEX } from './const'

const { pathname } = window.location

export const getCampaignDataFromUrl = () => {
  const matchCampaignNameId = pathname.match(CAMPAIGN_NAME_ID_REGEX)
  const campaignNameId = matchCampaignNameId ? matchCampaignNameId[0] : null
  const splittedCampaignUrl = campaignNameId ? campaignNameId.split('-') : null

  return {
    campaignNameId,
    campaignId: splittedCampaignUrl[1],
    country: splittedCampaignUrl[0],
  }
}

export const getProductDataFromUrl = () => {
  const matchProductId = window.location.pathname.match(PRODUCT_ID_REGEX)
  const productId = matchProductId ? matchProductId[0] : null

  return { productId }
}
