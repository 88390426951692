import { call, select } from 'redux-saga/effects'

import { trackSF } from '../providers'
import {
  selectCampaignName,
  selectCampaignRetailerId,
} from '../../../selectors/campaign'

function* offerOpen() {
  const campaignName = yield select(selectCampaignName)
  const retailerId = yield select(selectCampaignRetailerId)

  // Shopfully View Format
  yield call(trackSF, {
    s2s_ea: 'vf',
    s2s_ec: 'i',
    s2s_el: campaignName,
    s2s_ev: retailerId,
  })

  // Shopfully offerOpen
  yield call(trackSF, {
    a: 'ov',
    lm: 'r',
    la: 0,
    fvk: 'wo',
  })
}

export default offerOpen
