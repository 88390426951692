import difference from 'lodash.difference'

export const manipulateCategoryAdvToInitialize = (list, adSlot) => {
  const toBeInitialized = [...(list || []), adSlot]
  return toBeInitialized.filter(el => el
    && Object.keys(el).length > 0
    && el.slotId
    && el.slotName
    && el.domNodeId)
}

export const removeFromCategoryAdvList = (originalList = [], toRemoveList = []) => difference(
  [...(originalList || [])],
  [...(toRemoveList || [])],
)
