import createActionTypes from './createActionTypes'

export const namespace = 'TRACKING'

export const types = createActionTypes(namespace, [
  'INIT_TRACKING',
  'TRACK',
  'SET_TRACKING',
  'SET_COOKIE_PREFERENCES',
  'SET_PRODUCT_DETAIL_TRACKING',
  'INIT_IUBENDA',
  'SET_PIXELS_INITIALIZED',
  'SET_COOKIE_READY',
  'SET_COOKIE_INITIALIZED_NO_PREFERENCES',
  'SET_CAMPAIGN_NAME',
  'SET_TRACKING_IMPRESSION_TAG_URL',
  'SET_TRACKING_FLYER_ID',
  'SET_TRACKING_RETAILER_ID',
  'SET_TRACKING_CATEGORY_SLUG',
  'SET_TRACKING_RETAILER_SLUG',
  'SET_COMSCORE_READY',
  'SET_GCLID_EVENT_SENT',
])

export const initTracking = () => ({
  type: types.INIT_TRACKING,
  payload: { },
})

export const track = payload => ({
  type: types.TRACK,
  payload,
})

export const setTracking = tracking => ({
  type: types.SET_TRACKING,
  payload: { tracking },
})

export const setCampaignName = campaignName => ({
  type: types.SET_CAMPAIGN_NAME,
  payload: { campaignName },
})

export const setTrackingImpressionTagUrl = url => ({
  type: types.SET_TRACKING_IMPRESSION_TAG_URL,
  payload: { url },
})

export const setTrackingFlyerId = flyerId => ({
  type: types.SET_TRACKING_FLYER_ID,
  payload: { flyerId },
})

export const setTrackingRetailerId = retailerId => ({
  type: types.SET_TRACKING_RETAILER_ID,
  payload: { retailerId },
})

export const setTrackingCategorySlug = categorySlug => ({
  type: types.SET_TRACKING_CATEGORY_SLUG,
  payload: { categorySlug },
})

export const setTrackingRetailerSlug = retailerSlug => ({
  type: types.SET_TRACKING_RETAILER_SLUG,
  payload: { retailerSlug },
})

export const setCookieReady = () => ({
  type: types.SET_COOKIE_READY,
  payload: { },
})

export const setCookieInitializedNoPreferences = () => ({
  type: types.SET_COOKIE_INITIALIZED_NO_PREFERENCES,
  payload: { },
})

export const setCookiePreferences = (isPreferenceExpressed, preferences) => ({
  type: types.SET_COOKIE_PREFERENCES,
  payload: { isPreferenceExpressed, preferences },
})

export const setPixelsInitialized = arePixelsInitialized => ({
  type: types.SET_PIXELS_INITIALIZED,
  payload: { arePixelsInitialized },
})

export const setProductDetailTracking = (productId, status) => ({
  type: types.SET_PRODUCT_DETAIL_TRACKING,
  payload: { productId, status },
})

export const setComscoreReady = () => ({
  type: types.SET_COMSCORE_READY,
  payload: { },
})

export const setGClidEventSent = preference => ({
  type: types.SET_GCLID_EVENT_SENT,
  payload: { preference },
})
