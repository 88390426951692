/* eslint-disable array-callback-return */
/* eslint-disable no-console */
import {
  takeEvery,
  select,
  put,
} from 'redux-saga/effects'

import { types as advActionTypes, removeFromCategoryAdUnitsList } from '../../actions/adv'
import {
  selectIsAdManagerInitialized,
  selectCategoryAdUnitsToInitialize,
} from '../../selectors/adv'
import {
  selectCurrentTheme,
} from '../../selectors/ux'

import { addAdvSlot } from './helpers/addAdvSlot'

function* handleAdManagerRequests() {
  // Checking if Ad Manager has been initialized
  const isAdManagerInitialized = yield select(selectIsAdManagerInitialized)

  // Retrieving the list of pending category advSlots
  const adUnitsToInitialize = yield select(selectCategoryAdUnitsToInitialize)

  const theme = yield select(selectCurrentTheme)

  if (isAdManagerInitialized && adUnitsToInitialize?.length) {
    adUnitsToInitialize.map(adSlot => addAdvSlot(adSlot, theme))

    /**
     * After the pending category slots have been initialized,
     * we can safely remove them from the toBeInitialized list
     */
    yield put(removeFromCategoryAdUnitsList(adUnitsToInitialize))
  }
}

/**
 * This way the first adSlot will be initialized even though
 * Ad Manager initializes asynchronously
 */
export function* watchAdManager() {
  yield takeEvery([
    advActionTypes.SET_CATEGORY_ADUNIT_INITIALIZED,
    advActionTypes.SET_AD_MANAGER_INITIALIZED,
  ], handleAdManagerRequests)
}
