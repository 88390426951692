import { put, select } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'
import { ORIGIN_PRODUCT, CONTEXT_STORE } from '../../../helpers/const'
import { selectStore } from '../../selectors/stores'

function* onProductStoreMapClick(action) {
  const { params } = action.payload
  const { storeId } = params
  const store = yield select(selectStore(storeId))

  yield put(trackEvent({
    event: 'navigateTo',
    params: {
      id: store.id,
      address: store.address,
      zip: store.zip,
      city: store.city,
      to: `${ORIGIN_PRODUCT}-${CONTEXT_STORE}`,
    },
  }))
}

export default onProductStoreMapClick
