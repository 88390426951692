import { MEDIA_TYPE_IMAGE, MEDIA_TYPE_VIDEO } from './const'

const getMediaType = url => {
  if ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(url)) return MEDIA_TYPE_IMAGE
  if ((/\.(avi|wmv|flv|mpg|mp4)$/i).test(url)) return MEDIA_TYPE_VIDEO

  return MEDIA_TYPE_IMAGE
}

export default getMediaType
