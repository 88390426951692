import { call } from 'redux-saga/effects'

import { trackSF } from '../providers'
import { NOT_AVAILABLE } from '../../../../helpers/const'

function* goTo(action) {
  const { params } = action.payload
  const { destination } = params

  const eventLabel = destination || NOT_AVAILABLE

  // Shopfully
  yield call(trackSF, {
    s2s_ea: 'goto',
    s2s_ec: 'c',
    s2s_el: eventLabel,
  })
}

export default goTo
