const sortProducts = data => {
  if (!data || data.length === 0) return []

  const sortedProducts = [...data].sort((prod1, prod2) => {
    const { settings: { order: prod1Order = '' } } = prod1
    const { settings: { order: prod2Order = '' } } = prod2

    const orderPrev = Number.isNaN(parseInt(prod1Order, 10))
      ? data.length
      : parseInt(prod1Order, 10)
    const orderNext = Number.isNaN(parseInt(prod2Order, 10))
      ? data.length
      : parseInt(prod2Order, 10)

    return parseInt(orderPrev, 10) - parseInt(orderNext, 10)
  })

  return sortedProducts
}

export default sortProducts
