import fetchRestData from '../../helpers/fetchResData'

/**
 * Given a storeId, fetch store hours
 * @param {Object} params
 * @param {string} params.storeId
 */
export default ({ storeId }) => {
  const options = {
    resource: `stores/${storeId}/store_hours`,
    paginate: false,
    dataOnly: true,
  }

  return fetchRestData(options)
}
