import { selectConst } from './i18n'
import { selectIsWebView } from './ux'
import { selectUserNearestStoreId } from './user'
import { selectCampaignRetailerPinSrc } from './campaign'
import { selectRetailerLogo, selectRetailerPin, selectRetailerSlug } from './retailers'

import stringTemplate from '../../utils/stringTemplate'
import createPlaceMapUrl from '../../helpers/createPlaceMapUrl'
import { createMemoSelector } from '../helpers/selector'

const EMPTY_ARRAY = []

const selectStoresList = state => state.stores

export const selectStoreDefaultHours = state => state.defaultStoreHours || EMPTY_ARRAY

export const selectStoreCallcenterHours = state => state.callcenterHours || EMPTY_ARRAY

export const selectStorePhone = storeId => state => state.stores?.[storeId]?.phone

export const selectStores = createMemoSelector(selectStoresList, (stores => (
  Object.values(stores).sort((a, b) => (a.distance - b.distance))
)))

export const selectStore = storeId => state => state.stores[storeId]

export const selectStoresUrl = state => {
  const { retailerId } = state.tracking

  const isWebView = selectIsWebView(state)
  const protocolPath = isWebView ? 'mobile.protocol' : 'web.protocol'
  const protocol = selectConst(protocolPath)(state)
  const domain = selectConst('domain')(state)
  const storesPath = isWebView ? 'mobile.stores' : 'web.stores'
  const path = selectConst(storesPath)(state)

  return stringTemplate(`${protocol}${domain}${path}`, { retailerId })
}

export const selectStorePinUrl = storeId => state => {
  if (!storeId) return null
  const store = selectStore(storeId)(state)
  const campaignPinSrc = selectCampaignRetailerPinSrc(state)
  const retailerPinSrc = selectRetailerPin(store?.retailer_id)(state)
  const retailerLogoSrc = selectRetailerLogo(store?.retailer_id)(state)

  return store.pinURL || campaignPinSrc || retailerPinSrc || retailerLogoSrc
}

export const selectNoStoresURL = state => state.layout.noStoresURL

export const selectUserNearestStore = state => {
  const storeId = selectUserNearestStoreId(state)
  const store = selectStore(storeId)(state)
  return store || null
}

export const selectStoreMapUrl = storeId => state => {
  if (!storeId) return null

  const store = selectStore(storeId)(state)
  const storeRetailerSlug = selectRetailerSlug(store?.retailer_id)(state)
  const address = `${store.name || storeRetailerSlug || ''} ${store.address} ${store.city} ${store.province}`

  return createPlaceMapUrl({ address, lat: store.lat, lng: store.lng })
}

export const selectStorePageURL = storeId => state => {
  const isWebView = selectIsWebView(state)

  const protocolPath = isWebView ? 'mobile.protocol' : 'web.protocol'
  const protocol = selectConst(protocolPath)(state)
  const domain = selectConst('domain')(state)
  const storePath = isWebView ? 'mobile.store' : 'web.store'
  const path = selectConst(storePath)(state)

  if (path) {
    return stringTemplate(`${protocol}${domain}${path}`, { storeId })
  }
  return ''
}
