import { call, select } from 'redux-saga/effects'
import { selectUserCookiesPreferenceActive } from '../../../selectors/tracking'
import { selectCountry } from '../../../selectors/ux'
import { getSecret } from '../../../../utils/i18n/secret'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../../helpers/const'
import getContext from '../../../../utils/context/getContext'

export function* trackFBQ(...params) {
  const isTargetingAdsCookieEnable = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )
  try {
    if (window.fbq && isTargetingAdsCookieEnable) {
      yield call(window.fbq, ...params)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function* initFacebookPixel() {
  const country = yield select(selectCountry)
  const context = getContext()
  const isTargetingAdsCookieEnable = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )
  const facebookPixelId = getSecret('FB_PIXEL_ID')({ country, context })

  if (typeof window.fbq !== 'function' || !facebookPixelId) {
    // eslint-disable-next-line no-console
    console.debug('error', 'Cannot init Facebook Pixel')
    return
  }
  if (isTargetingAdsCookieEnable) {
    try {
      yield call(window.fbq, 'set', 'autoConfig', false, facebookPixelId) // https://stackoverflow.com/questions/48858665/disable-facebook-pixel-code-on-specific-button-click-events
      yield call(window.fbq, 'init', facebookPixelId)
    } catch (error) {
    // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}
