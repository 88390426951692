import {
  set,
  isWithinInterval,
  isBefore,
  addDays,
} from 'date-fns'

const isStoreOpen = (timeFrom, timeTo) => {
  const today = new Date(Date.now())
  const start = set(today, { hours: timeFrom.hh, minutes: timeFrom.mm, seconds: 0 })
  const end = set(today, { hours: timeTo.hh, minutes: timeTo.mm, seconds: 0 })
  const isEndDayAfter = isBefore(end, start)

  return isEndDayAfter ? (
    isWithinInterval(today, { start, end: addDays(end, 1) })
  ) : (
    isWithinInterval(today, { start, end })
  )
}

export default isStoreOpen
