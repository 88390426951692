/* eslint-disable no-unused-vars */
import {
  select, put, takeEvery, call,
} from 'redux-saga/effects'
import getQueryParams from '../../../utils/getQueryParams'
import { setGClidEventSent, types as trackingActionTypes } from '../../actions/tracking'
import { selectUserCookiesPreferenceActive, selectGClidEventSent } from '../../selectors/tracking'
import { OT_TARGETING_ADS_PREFERENCE, CONSENT_MODE_VALUES } from '../../../helpers/const'
import { getSecret } from '../../../utils/i18n/secret'
import { getCampaignDataFromUrl } from '../../../helpers/getInfoFromUrl'
import { trackSFPost } from './providers'
import { selectFlyerId } from '../../selectors/campaign'

/**
 * In EU countries, we only send the gclid event if the user accepts the
 * targeting ads cookie. We also only send ONE event per session
 * (reloading the window is considered new session).
 *
 * In NON EU countries, we send the gclid event both if the user accepts
 * or denies the cookies preferences.
 * If the users has not expressed a preference, the default value is DENIED.
 * WE can send maximum ONE denied event and ONE granted event per session.
 */

function* trackGClid() {
  const isTargetingAdsCookieEnabled = yield select(
    selectUserCookiesPreferenceActive(OT_TARGETING_ADS_PREFERENCE),
  )
  const flyerId = yield select(selectFlyerId)

  const { country } = getCampaignDataFromUrl()
  const targetingPermission = getSecret('TARGETING_PERMISSION')({ country })
  const targetingPreference = CONSENT_MODE_VALUES[isTargetingAdsCookieEnabled ? 1 : 0]

  const event = yield select(selectGClidEventSent, CONSENT_MODE_VALUES)
  const { gclid, utm_source } = getQueryParams()

  if (!gclid) return

  const et = 'external_conversion'
  const at = {
    sf_action: 'OfferOpen',
    conversion_id: gclid,
    utm_source,
    sf_resource_type: 'flyer',
    sf_resource_id: flyerId,
    profiling_consent: isTargetingAdsCookieEnabled,
    landing_url: window.location.href,
  }

  const isPreferenceAlreadyBeenSent = event[targetingPreference]

  if (isPreferenceAlreadyBeenSent) return

  if (targetingPermission === '0') {
    /**
     * We are currently in an EU country, so we can only send the gclid event
     * if the user accepts the targeting cookie
     */

    if (isTargetingAdsCookieEnabled) {
      yield call(trackSFPost, et, at)
    }
  } else {
    /**
     * We are currently in a NON EU country, so we can send the gclid event
     * regardless of the user preferences
     */
    yield call(trackSFPost, et, at)
  }

  yield put(setGClidEventSent(targetingPreference))
}

export function* watchGClidOnCookiePreferences() {
  yield takeEvery(trackingActionTypes.SET_COOKIE_PREFERENCES, trackGClid)
}

export function* watchGClidOnInitialized() {
  yield takeEvery(trackingActionTypes.SET_COOKIE_INITIALIZED_NO_PREFERENCES, trackGClid)
}
