import { types as actionTypes } from '../actions/callcenterHours'
import initialState from '../initialState/callcenterHours'

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CALLCENTER_HOURS: {
      const { callcenterHours } = action.payload
      return [...callcenterHours]
    }
    default:
      return state
  }
}
