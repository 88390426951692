import { call, put, select } from 'redux-saga/effects'

import { track as trackEvent } from '../../actions/tracking'
import { selectProduct } from '../../selectors/product'
import { selectCurrentProductId, selectHasStores, selectCurrentCollectionIndex } from '../../selectors/ux'
import { CONTEXT_HEADER, ORIGIN_LANDING, ORIGIN_PRODUCT } from '../../../helpers/const'
import { selectUserNearestStore } from '../../selectors/stores'
import { selectLabel } from '../../selectors/i18n'

function* onCall() {
  const callcenterNumber = yield select(selectLabel('ctaCallNum'))
  const productId = yield select(selectCurrentProductId)

  if (!productId) {
    const trackingTo = `${ORIGIN_LANDING}-${CONTEXT_HEADER}`
    yield put(trackEvent({
      event: 'call',
      params: { to: trackingTo },
    }))
  } else {
    const product = yield select(selectProduct(productId))
    const position = (yield select(selectCurrentCollectionIndex)) + 1 // from 1 to N

    yield put(trackEvent({
      event: 'callProduct',
      params: {
        id: product.id,
        title: product.title,
        position,
        to: `${ORIGIN_PRODUCT}-${CONTEXT_HEADER}`,
      },
    }))
  }
  yield call(window.open, `tel://${callcenterNumber}`, '_self')
}

function* onNavigateToMap() {
  const store = yield select(selectUserNearestStore)

  const toOrigin = (yield select(selectCurrentProductId)) ? ORIGIN_PRODUCT : ORIGIN_LANDING

  yield put(trackEvent({
    event: 'navigateTo',
    params: {
      id: store.id,
      address: store.address,
      zip: store.zip,
      city: store.city,
      to: `${toOrigin}-${CONTEXT_HEADER}`,
    },
  }))
}

function* onSubHeaderCTAClick(action) {
  const { params } = action.payload
  const hasStores = yield select(selectHasStores)
  if (hasStores) {
    yield call(onNavigateToMap, params)
  } else {
    yield call(onCall, params)
  }
}

export default onSubHeaderCTAClick
