import createActionTypes from './createActionTypes'

export const namespace = 'PRODUCTS'

export const types = createActionTypes(namespace, [
  'SET_PRODUCTS',
  'TOGGLE_FAVOURITES',
])

export const setProducts = products => ({
  type: types.SET_PRODUCTS,
  payload: { products },
})

export const toggleFavourites = productId => ({
  type: types.TOGGLE_FAVOURITES,
  payload: { productId },
})
