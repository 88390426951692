import createActionTypes from './createActionTypes'

export const namespace = 'DEFAULT_STORE_HOURS'

export const types = createActionTypes(namespace, [
  'SET_DEFAULT_STORE_HOURS',
])

export const setDefaultStoreHours = defaultStoreHours => ({
  type: types.SET_DEFAULT_STORE_HOURS,
  payload: { defaultStoreHours },
})
