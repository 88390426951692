const dvc = {
  en_au: {
    domain: 'www.shopfully.com.au',
    mobile: {
      protocol: 'dvc://',
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
      category: '/_screens/categories/{{categoryId}}',
    },
    web: {
      protocol: 'https://',
      retailer: '/catalogue/{{retailerSlug}}',
      stores: '/retailers-and-stores?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  en_nz: {
    domain: 'www.shopfully.co.nz',
    mobile: {
      protocol: 'dvc://',
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
      category: '/_screens/categories/{{categoryId}}',
    },
    web: {
      protocol: 'https://',
      retailer: '/catalogue/{{retailerSlug}}',
      stores: '/retailers-and-stores?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  es_mx: {
    domain: 'www.dondelocompro.mx',
    mobile: {
      protocol: 'dvc://',
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
      category: '/_screens/categories/{{categoryId}}',
    },
    web: {
      protocol: 'https://',
      retailer: '/folleto/{{retailerSlug}}',
      stores: '/cadenas-y-tiendas?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  it_it: {
    domain: 'www.doveconviene.it',
    mobile: {
      protocol: 'dvc://',
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
      category: '/_screens/categories/{{categoryId}}',
    },
    web: {
      protocol: 'https://',
      retailer: '/volantino/{{retailerSlug}}',
      stores: '/catene-e-negozi?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  pt_br: {
    domain: 'www.aondeconvem.com.br',
    mobile: {
      protocol: 'dvc://',
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
      category: '/_screens/categories/{{categoryId}}',
    },
    web: {
      protocol: 'https://',
      retailer: '/promocoes/{{retailerSlug}}',
      stores: '/redes-e-lojas?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  fr_fr: {
    domain: 'www.shopfully.fr',
    mobile: {
      protocol: 'dvc://',
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
      category: '/_screens/categories/{{categoryId}}',
    },
    web: {
      protocol: 'https://',
      retailer: '/catalogue/{{retailerSlug}}',
      stores: '/chaines-et-magasins?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
}

const tiendeo = {
  en_au: {
    domain: 'www.tiendeo.com.au',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  en_nz: {
    domain: 'www.tiendeo.co.nz',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  es_mx: {
    domain: 'www.tiendeo.mx',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  it_it: {
    domain: 'www.tiendeo.it',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  pt_br: {
    domain: 'www.tiendeo.com.br',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  ro_ro: {
    domain: 'www.tiendeo.ro',
    disableWebsiteTracking: true,
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  bg_bg: {
    domain: 'www.tiendeo.bg',
    disableWebsiteTracking: true,
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  cs_cz: {
    domain: 'www.tiendeo.cz',
    disableWebsiteTracking: true,
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  fr_fr: {
    domain: 'www.tiendeo.fr',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  es_es: {
    domain: 'www.tiendeo.com',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  pt_pt: {
    domain: 'www.tiendeo.pt',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  es_cl: {
    domain: 'www.tiendeo.cl',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  es_ar: {
    domain: 'www.tiendeo.com.ar',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  es_co: {
    domain: 'www.tiendeo.com.co',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  es_ec: {
    domain: 'www.tiendeo.com.ec',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  es_pe: {
    domain: 'www.tiendeo.pe',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  nl_be: {
    domain: 'www.tiendeo.be/fr',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  en_za: {
    domain: 'www.tiendeo.co.za',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  en_gb: {
    domain: 'www.tiendeo.co.uk',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  en_ca: {
    domain: 'www.tiendeo.ca',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  tr_tr: {
    domain: 'www.tiendeo.com.tr',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  de_de: {
    domain: 'www.tiendeo.de',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  de_at: {
    domain: 'www.tiendeo.at',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  nb_no: {
    domain: 'www.tiendeo.no',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  nl_nl: {
    domain: 'www.tiendeo.nl',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  da_dk: {
    domain: 'www.tiendeo.dk',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  sv_se: {
    domain: 'www.tiendeo.se',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  de_ch: {
    domain: 'www.tiendeo.ch',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  el_gr: {
    domain: 'www.tiendeo.gr',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  pl_pl: {
    domain: 'www.tiendeo.pl',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  sk_sk: {
    domain: 'www.tiendeo.sk',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  hu_hu: {
    domain: 'www.tiendeo.hu',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  fi_fi: {
    domain: 'www.tiendeo.fi',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  zh_sg: {
    domain: 'www.tiendeo.sg',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  ko_kr: {
    domain: 'www.tiendeo.co.kr',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  ar_sa: {
    domain: 'www.tiendeo.ae',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  ar_ae: {
    domain: 'www.tiendeo.ae',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  ar_ma: {
    domain: 'www.tiendeo.ma',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
  ja_jp: {
    domain: 'www.tiendeo.jp',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
}

const promoqui = {
  it_it: {
    domain: 'www.promoqui.it',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
}

const volantinofacile = {
  it_it: {
    domain: 'www.volantinofacile.it',
    mobile: {
      protocol: '',
      retailer: '',
      favourite: '',
      stores: '',
      store: '',
      category: '',
    },
    web: {
      protocol: 'https://',
      retailer: '',
      stores: '',
      store: '',
    },
  },
}

export default {
  default: dvc, dvc, tiendeo, promoqui, volantinofacile,
}

/*
inactive countries
  fr_fr: {
    domain: 'www.shopfully.fr',
    mobile: {
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
    },
    web: {
      retailer: '/catalogue/{{retailerSlug}}',
      stores: '/chaines-et-magasins?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  id_id: {
    domain: 'www.dimanabelanja.co.id',
    mobile: {
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
    },
    web: {
      retailer: '/promo/{{retailerSlug}}',
      stores: '/retailers-and-stores?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  en_us: {
    domain: 'www.shopfully.com',
    mobile: {
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
    },
    web: {
      retailer: '/catalogue/{{retailerSlug}}',
      stores: '/retailers-and-stores?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
  es_es: {
    domain: 'www.dondetucompras.es',
    mobile: {
      retailer: '/_screens/retailer_details/{{retailerId}}',
      favourite: '/_actions/contentmemo/{{productId}}',
      stores: '/_screens/brands/{{retailerId}}/stores',
      store: '/_screens/store_details/{{storeId}}',
    },
    web: {
      retailer: '/folleto/{{retailerSlug}}',
      stores: '/cadenas-y-tiendas?r={{retailerId}}',
      store: '/store/{{storeId}}',
    },
  },
*/
