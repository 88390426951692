import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectCustomerImpressionTagURL } from '../../../flux/selectors/tracking'
import { uxEvent } from '../../../flux/actions/ux'

const CustomerImpressionPixel = () => {
  const dispatch = useDispatch()
  const [tracked, setTracked] = React.useState(false)
  const customerImpressionTagUrl = useSelector(selectCustomerImpressionTagURL)

  const onPixelFired = () => {
    dispatch(uxEvent({
      name: 'impression',
      target: 'App/CustomerPixel',
      params: { customerImpressionTagUrl },
    }))
    setTracked(true)
  }

  return customerImpressionTagUrl && !tracked ? (
    <img
      src={customerImpressionTagUrl}
      onLoad={onPixelFired}
      border="0"
      height="1"
      width="1"
      style={{ display: 'none' }}
      alt=""
    />
  ) : null
}

export default CustomerImpressionPixel
