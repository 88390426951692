import { put, select } from 'redux-saga/effects'

import { selectProduct } from '../../selectors/product'
import { track as trackEvent } from '../../actions/tracking'
import { selectCurrentCollectionIndex } from '../../selectors/ux'

function* onFavouriteProductCtaClick(action) {
  const { params: { productId } } = action.payload

  const product = yield select(selectProduct(productId))
  const position = (yield select(selectCurrentCollectionIndex)) + 1 // from 1 to N

  yield put(trackEvent({
    event: 'favourite',
    params: {
      id: product.id,
      title: product.title,
      position,
    },
  }))
}

export default onFavouriteProductCtaClick
