import get from 'lodash.get'
import { createSelector } from '../helpers/selector'

const selectConsts = state => state.i18n.consts

const selectLabels = state => state.i18n.labels

export const selectConst = constKey => createSelector(
  selectConsts,
  consts => get(consts, constKey),
)

export const selectLabel = labelKey => createSelector(
  selectLabels,
  labels => get(labels, labelKey) || '',
)

export const selectObjectLabel = labelKey => createSelector(
  selectLabels,
  labels => get(labels, labelKey) || {},
)
